import { Card } from 'react-bootstrap';

import FilterView from '../../../common/components/FilterView';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import dataTableBgImg from '../../../../assets/img/illustrations/cornerRight-1.png';
import { useSelector } from 'react-redux';
import { useInstDashName } from '../../../../hooks/useDashName';

const DashboardRoot = () => {
  const location = useLocation();
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.instructionsGrids
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  
  const dashboardName = useInstDashName()
  
  // const data = useSelector((state: RootState) => state.instructionDashboard.data);
  const data = useSelector(
    (state: RootState) => state.dashboard.instructionDashboardList
  );
  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';

  return (
    <FilterView
      showColumnSettings
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='instDashboard'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g) => g?.gridName === 'INST_DASHBOARD')?.configGroup ||
        []
      }
      showRightDiv={!!matchPath(location.pathname, `${APP_PATH_PREFIX}/instruction`)}
      dashboardName={dashboardName}
      data={data}
      gridDef={grids}
    />
  );
};

export default DashboardRoot;
