import {
  faCheck,
  faEdit,
  faInfoCircle,
  faRedo,
  faSave,
  faTimes,
  faUpload,
  faFolder
} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  matchPath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { updateBlDetailsRowColumns } from '../../blDetails/slices/blDetailsTable';
import { IDataTableColumn } from '../../../common/types/dataTable';
import DataTable from '../../../common/components/DataTable';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import {
  getDemurrage,
  addDemurrage,
  getDemurrageView,
  getInstructionList,
  getReleaseTypeList,
  getTrukerNameList,
  getDefoultPickuLocation,
  getOBLDetails,
  getAttachmentsOnly,
  getReleasePOPDetails,
  getUnpaidInvoice,
  getCFSNameList,
  fetchCFSNameByCountry,
  fetchEmptyYardNameByCountry,
  // getCheckList,
} from '../../dashboard/actions/instruction';

import infiniteLoop from '../../../../assets/img/animated-icons/infinite-loop.json';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { AESDecrypt } from '../../../../encrypt-util';
import { toast } from 'react-toastify';
import {
  updateSelectedDateDV,
  updateSelectedtruckerCode,
  updateSelectedAttachmentName,
  updateSelectedDocId,
  updateAdttamentData,
  updateSelectedPickupLocation,
  updateSelectedTruckerName,
  updateSelectedtruckerId,
  updateselectedInternalCode,
  updateSelectedAttachmentFilePath,
  updateSelectedFileServerPath,
  clearSelectedFileServerPath,
  updateLinerVerify,
  updateSelectedCheckList,
  updateselectedCheckListRemark,
  setLinerAttachment,
  updateSelectedCfsName,
  updateSelectedemptyYardName,
} from './instructionSlice/DemurrageSlice';
import { resetDemurrageViewSlice } from './instructionSlice/DemurrageViewSlice';
import { getUserProfile } from '../../../common/actions/userDetails';
import { updateDemurrageList } from './instructionSlice/DemurrageSlice';
import i18next from 'i18next';
import { useAsyncDebounce } from 'react-table';
import AsyncSelect from 'react-select/async';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import Lottie from 'lottie-react';
import { getDateFormat, getDateTimeFormat } from '../../../../helpers/dateFormat';
import axiosInstance from '../../../../axiosSetup';

import { getAppConfig } from '../../../common/actions/userDetails';
import UploadIndicator from '../../../../components/common/UploadIndicator';
import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useDataCountry from '../../../../hooks/useDataCountry';
import SnoozedToButton from '../../instructionDashboard/components/SnoozedTo';
import { updateDashbaordRefresh } from '../../../common/components/DataTable/slices/DataTable';
import ToggleDiv from '../../../../components/common/ToggleDiv';
import { clearPOPLInerData, clearUnpaidData } from '../../crrpop/InvoiceUnpaidSlice';
import { getReleaseAttConf } from './actions/Instruction';
import UploadThing from '../../../../components/common/UploadThing';
import Select from 'react-select';
import useBlCountry from '../../../../hooks/useBlCountry';

const ReleaseInstruction = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [submitFlag, setSubmitFlag] = useState(false);
  const [currStatus, setcurrStatus] = useState<any>('');
  const [isEdit, setIsEdit] = useState(false);
  //const bl_event_id = AESDecrypt(blEventId)
  //const bl_event_id = AESDecrypt(blEventId)
  const [attachedFileSize, setAttachedFileSize] = useState(0);
  const [loadingFileState, setLoadingFileState] = useState<{
    [key: string]: number;
  }>({});
  //dndInstructionId/edi
  // const docId = location.pathname.split('/')[4];
  const { blNum, encrypt, encryptdoc, blId } = useParams();
  const blidDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data?.[0]?.blId
  );
  const decrptdoc = AESDecrypt(encryptdoc);
  const decrptins = AESDecrypt(encrypt);
  const docId = decrptins;
  const onBack = useBackNavigation();
  const today = new Date();

  //Scroll object
  const rowColumns = useSelector(
    (state: RootState) => state.instructionTable.rowColumns
  );
  // to get pod value
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);

  //all instruction List
  const instructionList = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const linerDocs = useSelector((state: RootState) => state.demurrage.linerDocs)

  
  //Non released Bl container Data
  const data = useSelector((state: RootState) => state.demurrage.data);

  /*   const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const dataCountry = useDataCountry();
  //By default attachment data
  //TODO: make dynamic
  const docAttachment = useSelector(
    (state: RootState) => state.demurrage.attData
  );

  const [country, setCountry] = useState<any>(null);
  const serverFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedFileServerPath
  );

  const docList = useSelector((state: RootState) => state.demurrage.docList);

  const csfList = useSelector((state: RootState) => state.demurrageViewSlice.cfsList);
  const emptyYardList = useSelector((state: RootState) => state.demurrageViewSlice.emptyYardList);

  //console.log("emptyYardList",emptyYardList);
  //Pickup location dropdown list
  const pickUpLocation = useSelector(
    (state: RootState) => state.demurrage.pickUpLocation
  );

  //Saved container Pickup data
  const dataList = useSelector((state: RootState) => state.demurrageView.data);

  //Session user email id
  const userEmail = useSelector(
    (state: RootState) => state.userProfile.profile.email
  );
  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );

  //variable to save new Trucker Code Input values in table
  const [truckerCodearray, settruckerCodearray] = useState<any>([]);

  const [isDraft, setIsDraft] = useState(false)

  //variable to save new pickup date Input values in table
  const selectedDate = useSelector(
    (state: RootState) => state.demurrage.selectedDate
  );

  //variable to save new match code Input values in table
  const selectedinternalCode = useSelector(
    (state: RootState) => state.demurrage.selectedinternalCode
  );

  //variable to save new attachmentName Input values in table
  const selectedAttachmentName = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentName
  );

  const selectedDefaultPickupLocation = useSelector(
    (state: RootState) => state.demurrage.selectedDefaultPickupLocation
  );

  //variable to save new Attachemnt File path Input values in table
  const selectedAttachmentFilePath = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentFilePath
  );

  //variable to get attachment list

  const docVerifyFileName = useSelector(
    (state: RootState) => state.demurrage.attData.list
  );

  const checkListData = useSelector((state: RootState) => state.demurrage.checkList);
  const emptyYard = useSelector((state: RootState) => state.demurrage.data.list.find((x) => x.emptyYardPartnerId)?.emptyYardPartnerId)
  
  //variable to save new pickup location Input values in table
  const selectedPickupLocation = useSelector(
    (state: RootState) => state.demurrage.selectedPickupLocation
  );

  const selectedCfsName = useSelector(
    (state: RootState) => state.demurrage.selectedCfsName
  )

  const selectedEmptyYardName = useSelector(
    (state: RootState) => state.demurrage.selectedemptyYardName
  )



  //variable to save new Trucker name Input values in table
  const selectedTruckerName = useSelector(
    (state: RootState) => state.demurrage.selectedTruckerName
  );

  //variable to save new Trucker code Input values in table
  const selectedTruckerCode = useSelector(
    (state: RootState) => state.demurrage.selectedTruckerCode
  );

  //variable to save new Trucker id Input values in table
  const selectedtruckerId = useSelector(
    (state: RootState) => state.demurrage.selectedtruckerId
  );

  //variable to save new Attachemnt File in byte array Input values in table
  const selectedAttachmentBytrr = useSelector(
    (state: RootState) => state.demurrage.selectedAttachmentBytrr
  );

  //variable to save new Attachemnt File name Input values in table
  const selectedDocId = useSelector(
    (state: RootState) => state.demurrage.selectedDocId
  );

  const releasePOPData = useSelector((state: RootState) => state.invoiceUnpaid.releasePOPData)



  const impHaulage = useSelector(
    (state: RootState) => state.oblDetails.oblForm.haulage
  );

  //Release type drop down list
  const releaseTypeList = useSelector(
    (state: RootState) => state.invoiceChange.releaseTypeList
  );

  //Release Type value
  const [releaseType, setReleaseType] = useState(releaseTypeList[0]?.value);

  //getpickUp location from bl Type value
  //const defoultPickUplocation = useState(getPickuLocation[0]?.pickupLocation);

  //Session

  const demurrageForm = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm
  );


  const demurrageViewForm = useSelector(
    (state: RootState) => state.demurrageViewSlice.demurrageForm
  );

  console.log("demurrageViewForm",demurrageViewForm);

  const docVerifyEmailId = docVerifyFileName.map((i) => i.actionBy)

  const blEventId_docHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data?.[0]?.blEventId
  );
  const blEventId_docInsDashboard: any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item: any) => Item.bl_id == AESDecrypt(blNum))[0]?.bl_event_id
  );

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const matchPth = [
    {
      path: `${APP_PATH_PREFIX}/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[3] !== 'instruction',
    },
    {
      path: `${APP_PATH_PREFIX}/instruction/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    {
      path: `${APP_PATH_PREFIX}/payment/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    { path: `${APP_PATH_PREFIX}/instruction/:blNum/:encrypt/:encryptdoc` },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });

  const [blCountry, setBlCountry] = useBlCountry(
    matchPth ? "view" : "new",
    "import",
    804
  );

  function checkPickupLocationValidity(
    containerArray: any[],
    selectedPickupLocationValue: { [s: string]: unknown },
    selectedTruckerName: { [s: string]: unknown },
    selectedDate: { [s: string]: null | string }
  ) {

    // no entries
    if (Object.values(selectedPickupLocationValue).findIndex(n => n && n !== '0') == -1) return false

    // overstay
    const overstay = Object.entries(selectedPickupLocationValue).filter(([k, v]) => v == '1002' && k !== 'location')
    if (overstay.length > 0) {
      if (overstay.findIndex(([k, v]) => selectedDate?.[k]) == -1) return false
    }

    // terminal
    const terminal = Object.entries(selectedPickupLocationValue).filter(([k, v]) => v == '1001' && k !== 'location')
    if (terminal.length > 0) {
      if (impHaulage !== 'Carrier Haulage' ||
        !['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) {
        if (terminal.findIndex(([k, v]) => selectedTruckerName?.[k]) == -1) return false
      }
    }

    // Blank
    const blank = Object.entries(selectedPickupLocationValue).filter(([k, v]) => ((!v) || v == '0') && k !== 'location')
    if (blank.length > 0) {
      if (blank.findIndex(([k, v]) => selectedTruckerName?.[k] || selectedDate?.[k]) != -1) return false
    }

    return true
  }

  const PickupLocationValidityFlag = checkPickupLocationValidity(
    data.list,
    selectedPickupLocation,
    selectedTruckerName,
    selectedDate
  );

  const handleFileChange = (e: any, fileDocId: any, filePaths: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > attachedFileSize) {
        toast.error(
          `File size is greater than ${(+attachedFileSize) / 1000000}MB. Please upload file below ${(+attachedFileSize) / 1000000}MB`
        );
        e.target.value = null;
        return;
      }

      if(file.name.length > 70){
        toast.error(t("resource_message:fileNameLimit60").toString());
        e.target.value = ""
        return
      }

      let fileExt = file.name.slice(-4);

      if (fileExt.toLowerCase() != '.pdf') {
        toast.error('Please upload Only PDF File');
        e.target.value = null;
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      try {
        // delete any existing file
        //if(filePaths[fileDocId]!==undefined){
        //  axiosInstance.post('deleteURL', filePaths[fileDocId])}

        // add file to bucket
        setLoadingFileState((state: any) => {
          return { ...state, [fileDocId]: 0 };
        });
        axiosInstance
          .post('/api/edo-rest-v1/v1/view/file', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress(progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );
              setLoadingFileState((state: any) => {
                return { ...state, [fileDocId]: percentCompleted };
              });
            },
          })
          .then((response) => {

          })
          .catch(() => {
            setLoadingFileState((state: any) => {
              return { ...state, [fileDocId]: -1 };
            });
          });

        // add server path to redux
      } catch (error) {
        console.error('File Upload Error: ', error);
        console.error('File Upload Error: ', error);
      }

      dispatch(
        updateSelectedAttachmentName({
          ...selectedAttachmentName,
          [fileDocId]: file,
        })
      );
      dispatch(
        updateSelectedAttachmentFilePath({
          ...selectedAttachmentFilePath,
          [fileDocId]: null,
        })
      );

      dispatch(
        updateSelectedDocId({
          ...selectedDocId,
          [fileDocId]: file[0],
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getAppConfig('attachment.maxsize'))
      .unwrap()
      .then((response: any) => {
        if (response !== '') {
          setAttachedFileSize(+response.response.data);
        }
      });
  }, []);

  useEffect(() => {
    setcurrStatus(demurrageForm?.status);
  }, [demurrageForm]);
  useEffect(() => {
    if (location.pathname.split('/')[3] === 'instruction' || location.pathname.split('/')[4] === 'instruction') {
      dispatch(
        getDefoultPickuLocation({
          blId: AESDecrypt(blNum),
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails.partnerId
              : selectedLinerId,
        })
      );
    }
    dispatch(
      getDemurrage({
        docId: AESDecrypt(blNum),
        location: location.pathname.split('/')[3],
        selectedLinerId:
          profileDetails.partnerType === 'liner'
            ? profileDetails.partnerId
            : selectedLinerId,
      })
    )
  }, [blNum, selectedLinerId]);

  /* useEffect(() => {
    dispatch(getCheckList(1));

  },[]) */

  useEffect(() => {
    dispatch(resetDemurrageViewSlice(1));
    if (profileDetails.partnerType === 'liner') dispatch(clearPOPLInerData());
    if (decrptdoc && docList && selectedLinerId) {
      dispatch(
        getDemurrageView({
          docId: decrptdoc,
          docList: docList,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails.partnerId
              : selectedLinerId,
        })
      )
        .unwrap()
        .then((v: any) => {
          //for view
          //show only current request container
          setBlCountry(v?.data?.country)
          if (!v?.data?.blId) {
            onBack()
            toast.error('Please Try Again')
            return
          }
          if (v?.list?.length > 0) {
            dispatch(
              updateDemurrageList({
                list: data.list.filter((x: any) =>
                  v?.list
                    .map((y: any) => y?.containerNo)
                    .includes(x?.containerNo)
                ),
                next: null,
                previous: null,
              })
            );
          }

          setDndInstructionId(v?.data?.dndInstructionId);
          if (v?.data?.dndInstructionId) {
            setDisableFields(true);
            setIsEdit(false);
          }
          setRemarks(v?.data?.remarks);
          setUserRemarks(v?.data?.userRemarks ?? '');
          if (v?.data?.attList.length > 0) {
            dispatch(updateAdttamentData(v?.data?.attList));
          }
        })
    }
  }, [decrptdoc, docId, location.pathname, docList, selectedLinerId]);


  const onApproveReject = (status: string, type: string) => {
    if (status && type) {

      if (status === 'A' &&
        accessGroupLevel.includes('Y') && checkListData.some((i: any) => i.isMandatory == 'Y' && i.check == null && i.groupLevel == demurrageViewForm.groupLevel)) {
        toast.error('All Check list points need to be marked');
        return
      }

      if (!!linerDocs?.find?.((i) => !['100', 100, '0', 0, '-1', -1].includes(i.loading))) {
        toast.error('Can not approve request with ongoing upload')
        return
      }

      if (status == 'A') {
        const pendingFile = linerDocs?.filter((i) => i.m)
          ?.find((i) => (!i.serverPath || !i.fileName || i.loading != 100)
            && (i.doReleasePdfId == null && i.id == null))

        if (pendingFile) {
          toast.error(`Please Upload ${pendingFile.name
            } to Approve the request`)
          return
        }

       // console.log("linerDocs",!!linerDocs?.find((i) => i.docId == 206)?.fileName);
        if(rbac.rbacDetails.cfsEmptyName == 'Y'){
          if((!!linerDocs?.find((i) => i.docId == 206)?.fileName) !== (!!selectedCfsName.value)){
          toast.error(`Please Add ${
            !!selectedCfsName.value?linerDocs?.find((i) => i.docId == 206).name:'CFS Name'
          } to Approve the request`)
          return
        } else if((!!linerDocs?.find((i) => i.docId == 208)?.fileName) !== (!!selectedEmptyYardName.value)){
          toast.error(`Please Add ${
            !!selectedEmptyYardName.value?linerDocs?.find((i) => i.docId == 208).name:'Empty Yard Name'
          } to Approve the request`)
          return
        } 
      }
         
      }


      if (status === 'R' && (remarks === '' || remarks == null)) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else {
        dispatch(
          commonApproveReject({
            instructionId: dndInstructionId || null,
            blId:demurrageViewForm.blId,
            country: blCountry,
            instructionType: type || null,
            linerRemarks: remarks || null,
            customerRemarks: null,
            status: status || null,
            emailTo: demurrageViewForm.senderEmail || null,
            isVerify: !accessGroupLevel.includes('Y') || status === 'R' ? null : `${docAttachment.list.filter((item) => item.actionBy === profileDetails.email).map((item) => `${item.docId}-${item.isVerify}`)}`,
            requestType: 804,
            requestedPartnerId: profileDetails.partnerId,
            groupLevel: !accessGroupLevel.includes('Y') ? null : demurrageViewForm.groupLevel + 1,
            actionBy: !accessGroupLevel.includes('Y') ? null : profileDetails.email || null,
            checkList: checkListData.filter(
              (i: any) => i.groupLevel == demurrageViewForm.groupLevel && i.check != null
            ).map((item: any) => ({
              codeId: item.docId,
              isVerify: item.check,
              remarks: item.remarks,
              docType: 8041,

            })),
            demurrageInstLinerAttDtls: linerDocs?.map((i) => ({
              demurrageInstructionLinerAttId: i.id,
              doReleasePdfId: i.doReleasePdfId,
              docId: i.docId,
              filePath: '',
              fileName: i.fileName,
              serverFilePath: i.serverPath,
              tempFileName: i.tempName,
            })),
            // actionOn:docVerifyUpdatedTime || null
            linerId: +selectedLinerId,
            cfsPartnerId:selectedCfsName.value?+selectedCfsName.value:null,
            emptyYardPartnerId:selectedEmptyYardName.value?+selectedEmptyYardName.value:null
          })
        )
          .unwrap()
          .then((response: any) => {
            toast.success(response?.responseMsg);
            dispatch(updateDashbaordRefresh(true))
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page
        setTimeout(() => {
          dispatch(
            getInstructionList({
              blNum: AESDecrypt(blNum),
              country: profileDetails.partnerType == "liner" ? dataCountry : blCountry
            })
          );
        }, 3000);
        onBack();
      }
    }
  };

  const [senderEmail, setSenderEmail] = useState<any>(userEmail);
  const [dndInstructionId, setDndInstructionId] = useState('');
  const [remarks, setRemarks] = useState('');
  const [userRemarks, setUserRemarks] = useState('');

  const [requestedPartnerName, setRequestedPartnerName] = useState('');

  const rbac = useSelector((state: RootState) => state.userProfile);
  const recentId = useSelector((state: RootState) => {
    const arr = state?.documentHistory?.data?.filter(
      (inst) =>
        inst.instructionId == 804 &&
        (inst.event == 'Submitted' || inst.event == 'Draft' || inst.event == 'Not Submitted')
    );
    const recentInst = arr.length <= 0 ? null :
      arr.reduce((a: any, b: any) => (a.blEventId > b.blEventId ? a : b))

    return ['Y', '1', '2', '3', '4', '5', '6'].includes(recentInst?.docStatus ?? '')
      ? null
      : recentInst;
  });

  const newEvent = useMemo(() => recentId?.docStatus?.trim() !== '', [])

  const reSubmit = useMemo(() => demurrageViewForm.status?.includes('Rejected')
    && demurrageViewForm.status != 'Rejected', [demurrageViewForm])


  const setInitialValue = () => {
    if (blNum) {
      const selectedDataDemurrage = dataList?.list?.find?.(
        (i: any) => i?.blId == AESDecrypt(blNum)
      );
      if (demurrageViewForm?.attList?.length > 0) {
        dispatch(updateAdttamentData(demurrageViewForm.attList));
      }
      if (selectedDataDemurrage) {
        setSenderEmail(selectedDataDemurrage?.senderEmail);
        setRemarks(selectedDataDemurrage?.remarks);

        setRequestedPartnerName(selectedDataDemurrage?.requestedPartnerName);
        setReleaseType(selectedDataDemurrage?.releaseType);
      }
    }
  };

  useEffect(() => {
    if ((!decrptdoc || decrptdoc?.length <= 0) && recentId?.docId && selectedLinerId) {
      dispatch(getAttachmentsOnly({ id: recentId?.docId, selectedLinerId }));
    } else if (!disableFields) {
      dispatch(getAttachmentsOnly({ id: null }))
    }
  }, [decrptdoc, recentId?.docId, selectedLinerId]);

  useEffect(() => {
    dispatch(updateSelectedPickupLocation({}));
    dispatch(updateSelectedTruckerName({}));
    dispatch(updateSelectedtruckerId({}));
    dispatch(updateSelectedAttachmentName({}));
    setUserRemarks('');
    setInitialValue();
  }, [docId]);

  useEffect(() => {
    if (blNum) {
      const selectedDataDemurrage = dataList?.list?.find?.(
        (i: any) => i?.blId == AESDecrypt(blNum)
      );
      if (selectedDataDemurrage) {
        //setSenderEmail()

        setSenderEmail(
          selectedDataDemurrage?.senderEmail
            ? selectedDataDemurrage?.senderEmail
            : senderEmail
        );
        setRemarks(selectedDataDemurrage?.remarks || '');
        setRequestedPartnerName(
          selectedDataDemurrage?.requestedPartnerName || ''
        );
        setReleaseType(selectedDataDemurrage?.releaseType || '');
      }
    }
  }, [dataList, blNum, location.pathname]);
  const _onReleaseTypeChange = useAsyncDebounce((value: string) => {
    setReleaseType(value);
  }, 200);
  const _onCloseForm = () => {

    if (decrptins == '804') {
      dispatch(toggleDocumentHistoryColumnShort(false));
    }
    if (location.pathname.includes('/bl')) {
      dispatch(
        updateBlDetailsRowColumns({
          ...rowColumns,
          mainTableClass: 'col-lg-2',
          documentMainViewClass: 'col-lg-10',
          documentHistoryClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    }
    setTimeout(() => {
      onBack();
    }, 500);
  };

  useEffect(() => {
    if (blNum && selectedLinerId && blCountry == 'MY' && (
      profileDetails.partnerType == "liner" || !location.pathname.includes("/bl/")))
      dispatch(
        getOBLDetails({
          blNumber: AESDecrypt(blNum) || null,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
  }, [blNum, selectedLinerId, blCountry]);


  useEffect(() => {
    if (profileDetails?.partnerType == 'liner'
      && (blId || demurrageForm.blId || blidDocHistory)
      && (rbac.rbacDetails.crrUnpaidHardStop == 'Y' || rbac.rbacDetails.crrUnpaidSoftStop == 'Y')) {

      dispatch(getReleasePOPDetails({ blid: AESDecrypt(blNum) ?? demurrageForm.blId ?? blidDocHistory, country: blCountry }))
    }
  }, [profileDetails?.partnerType, blId, blidDocHistory, demurrageForm.blId, blCountry])

  useEffect(() => {
    if (blCountry && selectedLinerId) {
      dispatch(getReleaseAttConf({ country: blCountry, liner: selectedLinerId }))
    }
  }, [blCountry, selectedLinerId])
  type FormValues = {
    senderEmail: string;
    value: string;
    requestedPartnerName: string;
    //destuffingType: string;
    releaseType: string;
  };

  const validationSchema = Yup.object().shape({
    senderEmail: Yup.string()
      .email('Email is invalid')
      .when('country', {
        is: 'USA',
        then: Yup?.string().required('Email is required'),
      })
      .when('country', {
        is: 'CAN',
        then: Yup?.string().required('Email is required'),
      }),

    // destuffingType: Yup.string().required('Destuffing Type  is required'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const [clearable, setClearable] = useState(true);

  const TableCell = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;

    if (disableFields) {
      return (
        <span>
          {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.pickupDate
          }
        </span>
      );
    }
    return (
      <>
        <DatePicker
          selected={selectedDate?.[containerNo] || null}
          portalId='root-portal'
          onChange={(date) => {
            dispatch(
              updateSelectedDateDV({
                ...selectedDate,
                [containerNo]: date,
                intended: null,
              })
            );
          }}
          className='fs--2 form-control'
          timeIntervals={5}
          dateFormat={getDateFormat()}
          isClearable={clearable}
          popperProps={{
            strategy: 'fixed',
          }}
          disabled={
            selectedPickupLocation?.[containerNo] === '1001' && !['KE', 'TZ', 'UG'].includes(blCountry)
          }
          minDate={new Date()}
        />
        {!data?.list
          ?.map((d) => (selectedDate?.[d.containerNo] ? true : false))
          .includes(true) &&
          !selectedDate?.[containerNo] && (
            <span className='text-danger f--1 mt-1'> </span>
          )}
      </>
    );
  };

  const [selectedContainerInput, setSelectedContainerInput] =
    useState<string>('');

  const TableCellTruckerId = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;

    if (!disableFields) {
      return (
        <input
          type='text'
          className='fs--1 form-control'
          disabled={true}
          title={containerNo}
          defaultValue={selectedtruckerId?.[containerNo] || ''}
          onBlur={(e) => {
            e.preventDefault();

            dispatch(
              updateSelectedtruckerId({
                ...selectedtruckerId,
                [containerNo]: e.target.value,
              })
            );
          }}
        />
      );
    } else {
      return (
        <span>
          {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.truckerId
          }
        </span>
      );
    }
  };

  const TableCellInternalCode = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;

    if (!disableFields) {
      return (
        <input
          type='text'
          className='fs--2 form-control'
          disabled={
            disableFields ||
            selectedPickupLocation?.[containerNo] === '1002' ||
            (impHaulage === 'Carrier Haulage' &&

              ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry))
          }
          // ref={inputRef}
          defaultValue={selectedinternalCode?.[containerNo] || ''}
          /*  ref={(input) => {
            if (input && selectedContainerInput === containerNo) {
              input.focus();
            }
          }} */
          /* onFocus={() => setSelectedContainerInput(containerNo)}
          onBlur={() => setSelectedContainerInput('')} */
          // value={inputCrn}
          onBlur={(e) => {
            e.preventDefault();
            // setInputCrn(e.target.value);

            dispatch(
              updateselectedInternalCode({
                ...selectedinternalCode,
                [containerNo]: e.target.value,
                // intended: date,
                //  truckerCode: null,
              })
            );
          }}
        />
      );
    } else {
      return (
        <span>
          {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.internalCode
          }
        </span>
      );
    }
  };

  const TableCellTrucker = (rowData: any) => {
    const { blContainerId, containerNo, cntStatus, truckerCode } =
      rowData.row.original;
    if (["SG","VN"].includes(blCountry) && cntStatus === 'Completed') {
      return <span>{truckerCode}</span>;
    } else if (!disableFields) {
      return (
        <input
          type='text'
          className='fs--2 form-control'
          disabled={cntStatus == 'Completed' ? true : disableFields}
          title={containerNo}
          // ref={inputRef}
          defaultValue={selectedTruckerCode?.[containerNo] || ''}
          ref={(input) => {
            if (input && selectedContainerInput === containerNo) {
              input.focus();
            }
          }}
          onFocus={() => setSelectedContainerInput(containerNo)}
          onBlur={() => setSelectedContainerInput('')}
          // value={inputCrn}
          onChange={(e) => {
            e.preventDefault();
            // setInputCrn(e.target.value);

            dispatch(
              updateSelectedtruckerCode({
                ...selectedTruckerCode,
                [containerNo]: e.target.value,
                // intended: date,
                //  truckerCode: null,
              })
            );

            data?.list?.forEach((f: any) => {
              if (f.blContainerId === blContainerId) {
                const existingIndex = truckerCodearray.findIndex(
                  (item: any) => item.id === f.blContainerId
                );
                if (existingIndex !== -1) {
                  settruckerCodearray((prevArray: any) => {
                    const newArray = [...prevArray];
                    newArray[existingIndex] = {
                      value: e.target.value,
                      id: f.blContainerId,
                    };
                    return newArray;
                  });
                } else {
                  settruckerCodearray((prevArray: any) => [
                    ...prevArray,
                    { value: e.target.value, id: f.blContainerId },
                  ]);
                }
              }
            });
          }}
        />
      );
    } else {
      return (
        <span>
          {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.truckerCode
          }
        </span>
      );
    }
  };
  const locationOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        dispatch(getCFSNameList({ country: blCountry, name: inputValue }))
          .unwrap()
          .then((v: any) => {
            resolve(v);
          });
      }, 2000);
    });
  const TableCellReturnLocation = (rowData: any) => {
    const { blContainerId, containerNo, } = rowData.row.original;
    if (!disableFields) {
      if (['KE', 'TZ', 'UG'].includes(blCountry)) {
        return <div style={{ zIndex: 999 }}>
          <AsyncSelect
            menuPortalTarget={document.body}
            classNames={{
              control: (state) => {
                return `dropControl fs--1 asyncDropField m-0 p-0
              form-control ${state.isDisabled ? 'disabledField' : ''}
              ${state.isFocused ? 'focusField' : ''}`;
              },
            }}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({}),
            }}
            cacheOptions
            onChange={(e: any) => {
              dispatch(
                updateSelectedPickupLocation({
                  ...selectedPickupLocation,
                  [containerNo]: e?.cfsName ?? '',
                  location: null,
                })
              );
              if (!e?.cfsName) {
                dispatch(
                  updateSelectedTruckerName({
                    ...selectedTruckerName,
                    [containerNo]: null,
                    location: null,
                  })
                );
                dispatch(
                  updateSelectedtruckerId({
                    ...selectedtruckerId,
                    [containerNo]: null,
                    location: null,
                  })
                );
                dispatch(
                  updateselectedInternalCode({
                    ...selectedinternalCode,
                    [containerNo]: null,
                  })
                );
                dispatch(
                  updateSelectedDateDV({
                    ...selectedDate,
                    [containerNo]: null,
                  })
                );
              }
            }}
            value={{
              label: selectedPickupLocation?.[containerNo] || '',
              value: selectedPickupLocation?.[containerNo] || '',
            }}

            loadOptions={locationOptions}
            isDisabled={disableFields}
            isClearable={true}
          />
        </div>
      }
      return (
        <Form.Select
          disabled={disableFields}
          value={selectedPickupLocation?.[containerNo] || ''}
          onChange={(e) => {
            dispatch(
              updateSelectedPickupLocation({
                ...selectedPickupLocation,
                [containerNo]: e.target.value,
                location: null,
              })
            );
            dispatch(
              updateSelectedTruckerName({
                ...selectedTruckerName,
                [containerNo]: null,
                location: null,
              })
            );
            dispatch(
              updateSelectedtruckerId({
                ...selectedtruckerId,
                [containerNo]: null,
                location: null,
              })
            );
            dispatch(
              updateselectedInternalCode({
                ...selectedinternalCode,
                [containerNo]: null,
              })
            );
            dispatch(
              updateSelectedDateDV({
                ...selectedDate,
                [containerNo]: null,
              })
            );
          }}
          className='fs--1 form-control  form-select'>
          {
            <>
              {pickUpLocation.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </>
          }
        </Form.Select>
      );
    } else {
      return (
        <span>
          {
            ['KE', 'TZ', 'UG'].includes(blCountry) ? demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.pickupLocation
              :
              pickUpLocation.find(
                (v: any) =>
                  demurrageViewForm?.list?.find?.(
                    (f: any) => f?.cntNo === containerNo
                  )?.pickupLocation === v?.value.toString()
              )?.text
          }
        </span>
      );
    }
  };

  useEffect(() => {
    dispatch(fetchCFSNameByCountry({
      country: dataCountry
    }))

    dispatch(fetchEmptyYardNameByCountry({
      country: dataCountry
    }))


  }, [dataCountry])

  useEffect(() => {
    if ((selectedCfsName?.value && !selectedCfsName?.label)) {
      const label = csfList.find((x) => x?.value == selectedCfsName?.value)?.text
      if (label) dispatch(
        updateSelectedCfsName({
          value: selectedCfsName?.value,
          label: label ?? null

        })
      );
    }
    if ((selectedEmptyYardName?.value && !selectedEmptyYardName?.label)) {
      const labelEmpty = emptyYardList.find((x) => x?.value == selectedEmptyYardName?.value)?.text
      if (labelEmpty) dispatch(
        updateSelectedemptyYardName({
          value: selectedEmptyYardName?.value,
          label: labelEmpty ?? null

        })

      );
    }

  }, [csfList, emptyYardList, selectedEmptyYardName, selectedCfsName])

  const TableCellCfsList = (rowData: any) => {
    const { docId } = rowData.row.original;
    if(docId!= 206 && docId != 208) return<></>
    if(instructionList.filter(
      (v: any) =>
        v?.docId === demurrageViewForm?.dndInstructionId
    ).map((u: any) => u?.event === 'Completed').includes(true)
      || (demurrageForm.status == 'Completed' || demurrageForm.status?.includes('Rejected'))
      ||
      !(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y' || !accessGroupLevel.includes('Y'))
      ||
      dataCountry !== blCountry || rbac.rbacDetails.demurrageApproveReject !== 'Y') {
      return <span>{docId!= 206?
        selectedEmptyYardName?.label ?? emptyYardList.find((x) => x.value == selectedEmptyYardName.value)?.text ?? ""
        :selectedCfsName?.label ?? csfList.find((x) => x.value == selectedCfsName.value)?.text ?? ""} </span>
      }
    
     return <div style={{ zIndex: 999 }}>
          <Select
            menuPortalTarget={document.body}
            placeholder={docId == 206?"CFS Name":"Empty Yard Name "}
            options={docId== 206?csfList?.map(x=>({label:x?.text, value:x?.value})):emptyYardList?.map(x=>({label:x?.text, value:x?.value}))}
            value={docId== 206?selectedCfsName:selectedEmptyYardName}
            classNames={{
              control: (state) => {
                return `dropControl fs--1 asyncDropField m-0 p-0
              form-control ${state.isDisabled ? 'disabledField' : ''}
              ${state.isFocused ? 'focusField' : ''}`;
              },
            }}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({}),
            }}
            
            onChange={(e: any) => {
              if(e == null)
              {
                e = {
                  label:null,
                  value:0
                }
              }
              dispatch(
                docId== 206?updateSelectedCfsName(e):updateSelectedemptyYardName(e)
              );
              
            }}
          
            isDisabled={
              instructionList.filter(
                (v: any) =>
                  v?.docId === demurrageViewForm?.dndInstructionId
              ).map((u: any) => u?.event === 'Completed').includes(true)
              || (demurrageForm.status == 'Completed' || demurrageForm.status?.includes('Rejected'))
              ||
              !(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y' || !accessGroupLevel.includes('Y'))
              ||
              dataCountry !== blCountry || rbac.rbacDetails.demurrageApproveReject !== 'Y' 
            }
            isClearable={true}
          />
        </div>
      
      
  };


  

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        dispatch(getTrukerNameList({ name: inputValue, country: blCountry }))
          .unwrap()
          .then((v: any) => {
            resolve(v);
          });
      }, 2000);
    });
  const TableCellTruckerName = (rowData: any) => {
    const { blContainerId, containerNo } = rowData.row.original;
    if (selectedTruckerName?.[containerNo]) {
      promiseOptions(selectedTruckerName?.[containerNo]);
    }

    if (!disableFields) {
      return (
        <div style={{ zIndex: 999 }}>
          <AsyncSelect
            menuPortalTarget={document.body}
            classNames={{
              control: (state) => {
                return `dropControl fs--1 asyncDropField m-0 p-0
              form-control ${state.isDisabled ? 'disabledField' : ''}
              ${state.isFocused ? 'focusField' : ''}`;
              },
            }}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({}),
            }}
            cacheOptions
            onChange={(e: any) => {
              dispatch(
                updateSelectedTruckerName({
                  ...selectedTruckerName,
                  [containerNo]: e?.truckerName,
                  location: null,
                })
              );
              dispatch(
                updateSelectedtruckerId({
                  ...selectedtruckerId,
                  [containerNo]: e?.truckerId,
                  location: null,
                })
              );
              dispatch(
                updateselectedInternalCode({
                  ...selectedinternalCode,
                  [containerNo]: e?.internalCode,
                })
              );
            }}
            value={{
              label: selectedTruckerName?.[containerNo] || '',
              value: selectedTruckerName?.[containerNo] || '',
            }}
            loadOptions={promiseOptions}
            isDisabled={
              selectedPickupLocation?.[containerNo] === '1002' ||
              (impHaulage === 'Carrier Haulage' &&
                ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry))
            }
            isClearable={true}
          />
        </div>

        /*  <Form.Select
          disabled={disableFields}
          value={selectedTruckerName?.[containerNo] || ''}
          autoComplete='n'
          onChange={(e) => {
            dispatch(
              updateSelectedTruckerName({
                ...selectedTruckerName,
                [containerNo]: e.target.value,
                location: null,
              })
            );
            /* setPayloadList(
              data?.list?.map((f: any) =>
                f.blContainerId === blContainerId
                  ? {
                      ...f,
                      returnLocation: e.target.value,
                    }
                  : f
              )
            );  
          }}
          className='fs--1 form-control  form-select'>
          {
            <>
              <option value=''>Select</option>
              <option value='Johannasbag'>Over stay Depot</option>
              <option value='Terminal'>Terminal</option>
            </>
          }
        </Form.Select>
        */
      );
    } else {
      return (
        <span>
          {
            demurrageViewForm?.list?.find?.(
              (f: any) => f?.cntNo === containerNo
            )?.truckerName
          }
        </span>
      );
    }
  };

  const accessGroupLevel = [rbac.rbacDetails.crrLevelOne, rbac.rbacDetails.crrLevelTwo, rbac.rbacDetails.crrLevelThree, rbac.rbacDetails.crrLevelFour, rbac.rbacDetails.crrLevelFive]

  const uploadDoument = (rowData: any) => {
    const { docId, dndInstructionAttId, fileName, actionBy, isVerify } = rowData.row.original;

    const charLimit = (name: string, limit: number) =>
      name && name?.length >= limit ? name?.substring(0, limit) + '...' : name;
    if (!disableFields) {
      return (
        <div className='d-flex justify-content-start align-items-center'>
          <UploadThing
            name={selectedAttachmentFilePath?.[docId]
              ? fileName
              : selectedAttachmentName?.[docId]?.name
            }
            loading={loadingFileState[docId]}
            disabled={!!isVerify || Object.values(loadingFileState).some(
              (val) => val != 100 && val != -1 && val != undefined
            )}

            fulfilled={function (name: string, serverPath: string, serverName: string): void {
              setLoadingFileState((state: any) => {
                return { ...state, [docId]: 100 };
              });
              dispatch(
                updateSelectedFileServerPath({
                  ...serverFilePath,
                  [docId]: [serverPath, serverName],
                })
              );
              dispatch(
                updateSelectedAttachmentName({
                  ...selectedAttachmentName,
                  [docId]: { name },
                })
              );
            }}

            failed={function (): void {
              setLoadingFileState((state: any) => {
                return { ...state, [docId]: -1 };
              });
              dispatch(
                updateSelectedAttachmentName({
                  ...selectedAttachmentName,
                  [docId]: { name: '' },
                })
              );
            }}

            pending={function (loading: number): void {
              setLoadingFileState((state: any) => {
                return { ...state, [docId]: loading };
              });
            }}

            clear={() => {/* allowRemove is false */ }}

            selected={function (name: string): void {
              dispatch(
                updateSelectedAttachmentName({
                  ...selectedAttachmentName,
                  [docId]: { name },
                })
              );
              setLoadingFileState((state: any) => {
                return { ...state, [docId]: 0 };
              });
              dispatch(
                updateSelectedAttachmentFilePath({
                  ...selectedAttachmentFilePath,
                  [docId]: null,
                })
              );
            }}
            fileLimit={10}
            fileType={['PDF']}
            labelWidth='250px'
            link={!selectedAttachmentFilePath?.[docId] ? undefined : `${process.env.REACT_APP_CLIENT_APP_HOST
              }/api/edo-rest-v2/v2/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === 'liner'
                ? profileDetails?.partnerId
                : selectedLinerId
              }`}
            allowRemove={false}
          />
          {/* <label
            className='btn btn-sm btn-falcon-primary m-0 me-2 '
            style={Object.values(loadingFileState).some(
              (val) => val != 100 && val != -1
          )?{cursor:'auto', opacity:'0.5'}:{}}
            htmlFor={`${docId}-input`}>
            <FontAwesomeIcon icon={faUpload} />
          </label>
          <input
            id={`${docId}-input`}
            title='Input'
            type='file'
            className='fs--2 form-control ms-1'
            disabled={disableFields || Object.values(loadingFileState).some(
              (val) => val != 100 && val != -1
          )}
            accept='.pdf'
            onClick={() => {
              updateSelectedFileServerPath({
                ...serverFilePath,
                [docId]: [null, null],
              });
            }}
            onChange={(e) => {
              handleFileChange(e, docId, serverFilePath);
            }}
            hidden
          />
          {selectedAttachmentFilePath?.[docId] ? (
            <span
              title={fileName}
              className='text-primary cursor-pointer'
              onClick={() => {
                window.open(
                  `${
                    process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/edo-rest-v2/v2/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${
                    profileDetails.partnerType === 'liner'
                      ? profileDetails?.partnerId
                      : selectedLinerId
                  }`,
                  '_blank'
                );
              }}>
              {charLimit(fileName, 45)}
            </span>
          ) : (
            <>
              <span title={selectedAttachmentName?.[docId]?.name}>
                {charLimit(selectedAttachmentName?.[docId]?.name, 45)}
              </span>
              {selectedAttachmentName?.[docId]?.name && (
                <UploadIndicator
                  style={{ marginLeft: '20px' }}
                  progress={loadingFileState[docId]}
                  fail={loadingFileState[docId] === -1}
                />
              )}
            </>
          )} */}
        </div>
      );
    } else {
      return (
        <div className='d-flex justify-content-start align-items-center'>
          {/*  <label
            className='btn btn-sm btn-dark m-0 me-2'
            htmlFor={`${docId}-input`}>
            Choose
          </label>
          <input
            id={`${docId}-input`}
            title='Input'
            type='file'
            className='fs--2 form-control ms-1'
            disabled={disableFields}
            onChange={(e) => {
              handleFileChange(e, docId);
            }}
            hidden
          /> */}

          <span
            className='text-primary cursor-pointer'
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_CLIENT_APP_HOST
                }/api/edo-rest-v2/v2/common/openDocument?id=${dndInstructionAttId}&moduleTp=DND&linerId=${profileDetails.partnerType === 'liner'
                  ? profileDetails?.partnerId
                  : selectedLinerId
                }`,
                '_blank'
              );
            }}>
            {charLimit(fileName, 50)}
          </span>
        </div>
      );
    }
  };


  const documentCheck = (rowData: any) => {
    const { docId, check, groupLevel } = rowData.row.original;

    return (
      <><span className="radio p-2">
        <label> Yes <input type="radio" name={docId} value="true" className='form-check-input'
          disabled={accessGroupLevel[groupLevel] != 'Y' || groupLevel != demurrageViewForm.groupLevel}
          checked={check == 'Y'} onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch(updateSelectedCheckList({
              check: 'Y',
              docId: docId,
            }))

          }} />
        </label>
      </span>
        <span className="radio p-2">
          <label> No <input type="radio" name={docId} value="false" className='form-check-input'
            disabled={accessGroupLevel[groupLevel] != 'Y' || groupLevel != demurrageViewForm.groupLevel}
            checked={check === 'N'} onChange={(e: ChangeEvent<HTMLInputElement>) => {
              dispatch(updateSelectedCheckList({
                check: 'N',
                docId: docId,
              }))

            }} /></label>
        </span>
      </>

    );

  };


  const TableVerifyDoc = (rowData: any) => {
    const { type, allowAccess, docId, isVerify, fileName, actionBy } = rowData.row.original;
    //const name = 'TableVerifyDoc' + type.toString();
    if (fileName) {
      return (
        <div className='d-flex justify-content-start align-items-center'>
          <input
            type='checkbox'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {

              dispatch(updateLinerVerify({
                verify: e.target.checked,
                docId: docId,
                fileName: fileName,
                actionBy: (e.target.checked == (demurrageForm.attList.find((i) => i.docId == docId)?.isVerify)) ? demurrageForm.attList.find((i) => i.docId == docId).actionBy : profileDetails.email,
                actionOn: (e.target.checked == (demurrageForm.attList.find((i) => i.docId == docId)?.isVerify)) ? demurrageForm.attList.find((i) => i.docId == docId).actionOn : moment(
                  moment
                    .utc(new Date())
                    .toDate()
                ).format(getDateTimeFormat().toLocaleUpperCase())


              }))

            }

            }
            checked={isVerify}
            disabled={profileDetails.partnerType !== 'liner' || !(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y') || (demurrageViewForm.status == 'Completed' || demurrageViewForm.status?.includes('Rejected'))}
          />
        </div>
      )
    }



  };

  const TableLinerId = (rowData: any) => {
    const { actionBy } = rowData.row.original;
    return (

      <div className='d-flex justify-content-start align-items-center'>
        <span> {actionBy}  </span>
      </div>

    );
  };

  const TableLastUpdated = (rowData: any) => {
    const { actionOn } = rowData.row.original;
    new Date()
    return (

      <div className='d-flex justify-content-start align-items-center'>
        <span>{actionOn}</span>
      </div>

    );
  };


  const TableCheckListRemark = (rowData: any) => {
    const { docId, remarks, groupLevel } = rowData.row.original;

    return (
      <input
        type='text'
        className='fs--2 form-control'
        disabled={accessGroupLevel[groupLevel] != 'Y' || groupLevel != demurrageViewForm.groupLevel}
        defaultValue={remarks}
        onBlur={(e) => {
          e.preventDefault();
          dispatch(
            updateselectedCheckListRemark({
              docId,
              remarks: e.target.value,

            })
          );
        }}
      />

    );

  };

  const TableCheckListDocName = (rowData: any) => {
    const { docId, name } = rowData.row.original;

    return (

      <span title={name}>{name}</span>


    );

  };

  const resetForm = () => {
    setSenderEmail('');
    dispatch(updateSelectedDateDV({ intended: null }));
    dispatch(updateSelectedtruckerCode({ truckerCode: '' }));
    dispatch(updateSelectedPickupLocation({}));
    dispatch(updateSelectedTruckerName({}));
    dispatch(updateSelectedtruckerId({}));
    dispatch(updateSelectedAttachmentName({}));
    dispatch(updateSelectedAttachmentFilePath({}));
    dispatch(clearSelectedFileServerPath());
    setUserRemarks('');
    setLoadingFileState({})

  };
  const language = useSelector((state:RootState)=> state.navbarTop.language);

  const [disableFields, setDisableFields] = useState<any>(
    profileDetails?.partnerType === 'liner' ||
    (!!matchPth && profileDetails?.partnerType !== 'liner')
  );



  const onSubmit = handleSubmit(async (payload) => {
    setSubmitFlag(true);
    const idValueMap: { [id: string]: string } = {};
    truckerCodearray.forEach((item: { id: string | number; value: string }) => {
      idValueMap[item.id] = item.value;
    });

    try {
      await dispatch(
        addDemurrage({
          ...payload,
          requestedPartnerId: profileDetails?.partnerId,
          blId: AESDecrypt(blNum),
          country: blCountry,
          dndInstructionId:
            demurrageViewForm?.status != 'Submitted' && !demurrageViewForm?.status?.includes('Rejected')
              ? null
              : demurrageViewForm?.dndInstructionId || null,
          senderEmail: senderEmail,
          remarks: instructionList
            .filter(
              (v: any) => v?.docId === demurrageViewForm?.dndInstructionId
            )
            .map((u: any) => u?.event?.includes('Rejected'))
            .includes(true)
            ? ''
            : demurrageViewForm?.remarks || '',
          userRemarks: userRemarks,
          status: isDraft ? "Draft" :
            demurrageViewForm.groupLevel ? `Approved by L${demurrageViewForm.groupLevel}` :
              "Submitted",
          list: data?.list
            ?.filter((v: any) =>
              ['SG','VN'].includes(blCountry)
                ? !(v.cntStatus === 'Completed') &&
                selectedTruckerCode?.[v.containerNo]
                : true
            )
            .map?.((d: any, index) => ({
              ...d,
              containerPickupId:
                demurrageViewForm?.list?.find(
                  (x: any) => d?.containerNo === x?.containerNo
                )?.containerPickupId || null,
              //  truckerCode: inputRef.current?.value,
              /*  truckerCode: updatedArray.find(
              (code) => code.id == d.blContainerId.toString()
            )?.value, */
              truckerCode: d?.containerNo
                ? selectedTruckerCode?.[d.containerNo]
                  ? selectedTruckerCode?.[d.containerNo]
                  : null
                : null,
              truckerId: d?.containerNo
                ? selectedtruckerId?.[d.containerNo]
                  ? selectedtruckerId?.[d.containerNo]
                  : null
                : null,
              internalCode: d?.containerNo
                ? selectedinternalCode?.[d.containerNo]
                  ? selectedinternalCode?.[d.containerNo]
                  : null
                : null,
              pickupLocation: d?.containerNo
                ? selectedPickupLocation?.[d.containerNo]
                  ? selectedPickupLocation?.[d.containerNo]
                  : null
                : null,
              truckerName: d?.containerNo
                ? selectedTruckerName?.[d.containerNo]
                  ? selectedTruckerName?.[d.containerNo]
                  : null
                : null,
              pickupDate: d?.containerNo
                ? selectedDate?.[d.containerNo]
                  ? moment(selectedDate?.[d.containerNo] + ' UTC')?.format(
                    'YYYY-MM-DD'
                  )
                  : null
                : null,
            })),
          docList: docAttachment?.list?.filter((d) => loadingFileState[d.docId] != -1).map?.((d: any, index) => ({
            ...d,
            dndInstructionAttId: (demurrageViewForm.status == 'Submitted') ||
              (demurrageViewForm.status == 'Not Submitted') ||
              (demurrageViewForm.status == 'Draft') ?
              demurrageViewForm?.attList?.find(
                (x: any) => d?.docId === x?.docId
              )?.dndInstructionAttId ?? null : null,
            docId: d?.docId,
            filePath: d?.docId ? selectedAttachmentFilePath?.[d.docId] : null,
            fileName: d?.docId ? selectedAttachmentName?.[d.docId]?.name : null,
            serverPath: d?.docId ? serverFilePath?.[d.docId]?.[0] : null,
            tempFileName: d?.docId ? serverFilePath?.[d.docId]?.[1] : null,
          })),
          docId,
          checkList: checkListData,
          groupLevel: demurrageViewForm?.groupLevel ?? 0,
          linerId: +selectedLinerId,
        })
      );
    } catch (err: any) {
      console.error(err.message);
      console.error(err.message);
    }
    //dispatch(getInstructionList(AESDecrypt(blNum)));
    setTimeout(() => {
      dispatch(
        getInstructionList({
          blNum: AESDecrypt(blNum),
          country: profileDetails.partnerType == "liner" ? dataCountry : blCountry
        })
      );
      dispatch(updateDashbaordRefresh(true))
    }, 3500);

    onBack()

    setSubmitFlag(false);
    setIsEdit(false);
  });

  const contactDetailsColumns: IDataTableColumn[] = [
    {
      accessor: 'blContainerId',
      Header: 'blContainerId',
    },

    {
      accessor: 'containerNo',
      Header: t('resource_2:containerNo'),
      width: 200,
      maxWidth: 150,
    },

    ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
      ? {
        accessor: 'pickupLocation',
        Header: t('resource_2:pickupLocation'),
        width: ['KE', 'UG', 'TZ'].includes(blCountry) ? 250 : 178,
        maxWidth: ['KE', 'UG', 'TZ'].includes(blCountry) ? 250 : 178,
        Cell: TableCellReturnLocation,
      }
      : {
        accessor: 'pickupLocation',
        Header: '',
        width: 0,
        maxWidth: 0,
      },

    !['SG','VN'].includes(blCountry)
      ? {
        accessor: 'IntendedDate',
        Header: t('resource_2:IntendedDate'),
        width: 190,
        maxWidth: 190,
        Cell: TableCell,
        //  columnFilterType: 'date-range',
      }
      : {
        accessor: 'IntendedDate',
        Header: '',
        width: 0,
        maxWidth: 0,
      },

    ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
      ? {
        accessor: 'truckerName',
        Header: t('resource_2:trukerName'),
        width: 170,
        maxWidth: 170,
        Cell: TableCellTruckerName,
      }
      : { accessor: 'truckerName', Header: '', width: 0, maxWidth: 0 },

    ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
      ? {
        accessor: 'truckerId',
        Header: t('resource_2:trukerId'),
        width: 170,
        maxWidth: 170,
        Cell: TableCellTruckerId,
      }
      : { accessor: 'truckerId', Header: '', width: 0, maxWidth: 0 },

    ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
      ? {
        accessor: 'internalCode',
        Header: t('resource_2:internalCode'),
        width: 170,
        maxWidth: 170,
        Cell: TableCellInternalCode,
      }
      : {
        accessor: 'internalCode',
        Header: '',
        width: 0,
        maxWidth: 0,
      },

    !['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
      ? {
        accessor: 'truckerCode',
        Header:
          oblForm?.pod === 'MYPKG' && blCountry === 'MY'
            ? t('resource_3:ledger_acc_number') + ' '
            : oblForm?.pod === 'MYPEN' && blCountry === 'MY'
              ? t('resource_2:dic_number') + ' '
              : blCountry === 'MY' &&
                (oblForm?.pod === 'MYPGU' || oblForm?.pod === 'MYTPP')
                ? t('resource_3:forward_agent_code') + ' '
                : oblForm?.pod === 'MYKUA' && blCountry === 'MY'
                  ? t('') + ' '
                  : t('resource_2:truckerCode') + ' ',
        width: 178,
        maxWidth: 178,
        Cell: TableCellTrucker,
      }
      : {
        accessor: 'truckerCode',
        Header: '',
        width: 0,
        maxWidth: 0,
      },

    ['SG','MY','VN'].includes(blCountry)
      ? {
        accessor: 'cntStatus',
        Header: t('resource_1:status'),
        width: 150,
        maxWidth: 150,
      }
      : {
        accessor: 'cntStatus',
        Header: '',
        width: 0,
        maxWidth: 0,
      },
    ['SG','MY','VN'].includes(blCountry)
      ? {
        accessor: 'requestedOn',
        Header: t('resource_1:requested'),
        width: 150,
        maxWidth: 150,
        Cell: (rowData: any) => {
          const { requestedOn } = rowData.row.original;
          return (
            <span>
              {requestedOn
                ? moment(
                  moment
                    .utc(requestedOn + 'UTC', 'YYYY-MM-DDTHH:mm:SS')
                    .toDate()
                ).format('YYYY-MM-DD HH:mm')
                : ''}
            </span>
          );
        },
      }
      : {
        accessor: 'requestedOn',
        Header: '',
        width: 0,
        maxWidth: 0,
      },
    ['SG','MY','VN'].includes(blCountry)
      ? {
        accessor: 'approvedRejectedOn',
        Header: 'Approved/Rejected',
        width: 150,
        maxWidth: 150,
        Cell: (rowData: any) => {
          const { approvedRejectedOn } = rowData.row.original;
          return (
            <span>
              {approvedRejectedOn
                ? moment(
                  moment
                    .utc(approvedRejectedOn + 'UTC', 'YYYY-MM-DDTHH:mm:SS')
                    .toDate()
                ).format('YYYY-MM-DD HH:mm')
                : ''}
            </span>
          );
        },
      }
      : {
        accessor: 'approvedRejectedOn',
        Header: '',
        width: 0,
        maxWidth: 0,
      },
  ];

  //const docList = { 101: 'Bill of entry' };
  const POPColumns = [
    {
      accessor: 'invno',
      Header: 'Invoice Number',
      width: 200,
      maxWidth: 150
    },
    {
      accessor: 'ccy',
      Header: 'CCY',
      width: 150,
      maxWidth: 100
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      width: 200,
      maxWidth: 150
    },
    {
      accessor: 'invtype',
      Header: 'Invoice Type',
      width: 200,
      maxWidth: 150
    },
    {
      accessor: 'invstatus',
      Header: 'Paid',
      width: 100,
      maxWidth: 80
    },
    {
      accessor: 'invref',
      Header: 'Payment Reference No',
      width: 250,
      maxWidth: 200
    },
    {
      accessor: 'invatt',
      Header: 'Payment Proof',
      width: 200,
      maxWidth: 150,
      Cell: (rowdata: any) => {
        const { invatt, pymtId } = rowdata.row.original
        if (invatt && invatt != '' && pymtId) return <span
          style={{ color: '#2c7be5', cursor: 'pointer' }}
          onClick={() => {
            //window.open(`/api/edo-rest-v1/v1/invoice/openInvoicePDF?invNo=${invno}&partnerId=${profileDetails.partnerId}`)
            window.open(`${process.env.REACT_APP_CLIENT_APP_HOST
              }/api/edo-rest-v2/v2/common/openDocument?id=${pymtId}&moduleTp=PAYMENT_PROOF&linerId=${profileDetails?.partnerId
              }`,
              '_blank')
          }}
        >{invatt}</span>

        return <span>{invatt}</span>
      }
    }
  ];

  const docAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: 'dndInstructionAttId',
      Header: 'dndInstructionAttId',
    },
    {
      accessor: 'docId',
      Header: t("resource_2:documentName"),
      Cell: (rowData: any) => {
        const { docId } = rowData.row.original;
        const name = docList?.find((v: any) => v?.id === docId)?.name
        return (
          <>
            {docList?.find((v: any) => v?.id === docId)?.m === true ? (
              <span className='mandatory'>*</span>
            ) : (
              ''
            )}
            <span title={name || ''}>{name || ''}</span>
          </>
        );
      },
      width: 200,
      maxWidth: 180,
    },

    {
      accessor: 'byteArray',
      Header: t("resource_1:upload"),
      Cell: uploadDoument,
      width: 350,
      maxWidth: 350,
    },
    {
      accessor: 'verify',
      Header: 'Verify',
      Cell: TableVerifyDoc,
      width: 50,
      maxWidth: 50,
    },
    {
      accessor: 'linerEmaiId',
      Header: 'Liner ID',
      Cell: TableLinerId,
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: 'lastUpdatedId',
      Header: 'Last Updated ',
      Cell: TableLastUpdated,
      width: 180,
      maxWidth: 180,
    },
    {
      accessor: 'fileName',
      Header: 'fileName',
    },
  ];

  const linerDocAttachmentColumns: IDataTableColumn[] = [
    {
      accessor: 'dndInstructionAttId',
      Header: 'dndInstructionAttId',
    },
    {
      accessor: 'docId',
      Header: 'Document Name',
      Cell: (rowData: any) => {
        const { docId, name, m } = rowData.row.original;
        return (
          <>
            {m ? (
              <span className='mandatory'>*</span>
            ) : (
              ''
            )}
            <span title={name || ''}>{name || ''}</span>
          </>
        );
      },
      width: 200,
      maxWidth: 180,
    },
    {
      accessor: 'fileName',
      Header: 'Upload',
      width: 300,
      maxWidth: 350,
      Cell: (rowData: any) => {
        const { docId, fileName, loading, id, doReleasePdfId } = rowData.row.original;

        // view only
        if (
          instructionList.filter(
            (v: any) =>
              v?.docId === demurrageViewForm?.dndInstructionId
          ).map((u: any) => u?.event === 'Completed').includes(true)
          || (demurrageForm.status == 'Completed' || demurrageForm.status?.includes('Rejected'))
          ||
          !(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y' || !accessGroupLevel.includes('Y'))
          ||
          dataCountry !== blCountry || rbac.rbacDetails.demurrageApproveReject !== 'Y'
        ) {
          return <span className={`fs--1 text-primary cursor-pointer`}
            onClick={() => {
              const link = doReleasePdfId ? `/api/edo-rest-v2/v2/common/openDocument?id=${doReleasePdfId}&moduleTp=DO_RELEASE_PDF&linerId=${selectedLinerId}`
                : id ? `/api/edo-rest-v2/v2/common/openDocument?id=${id}&moduleTp=DND_RELEASE_PDF&linerId=${selectedLinerId}`
                  : undefined
              window.open(link)
            }}>{fileName}</span>
        }

        return <UploadThing
          name={fileName}
          link={doReleasePdfId ? `/api/edo-rest-v2/v2/common/openDocument?id=${doReleasePdfId}&moduleTp=DO_RELEASE_PDF&linerId=${selectedLinerId}`
            : id ? `/api/edo-rest-v2/v2/common/openDocument?id=${id}&moduleTp=DND_RELEASE_PDF&linerId=24118`
              : undefined}
          loading={loading}
          fulfilled={function (name: string, serverPath: string, serverName: string): void {
            dispatch(setLinerAttachment({
              docId,
              fileName: name,
              serverPath,
              tempName: serverName,
              loading: 100
            }))
          }}
          failed={function (): void {
            dispatch(setLinerAttachment({
              docId,
              loading: -1,
              fileName: ''
            }))
          }}
          pending={function (loading: number): void {
            dispatch(setLinerAttachment({
              docId,
              loading
            }))
          }}
          clear={function (): void {
            dispatch(setLinerAttachment({
              doReleasePdfId: null,
              docId,
              fileName: '',
              loading: 0,
              serverPath: null,
              tempName: null
            }))
          }}
          selected={function (name: string): void {
            dispatch(setLinerAttachment({
              doReleasePdfId: null,
              docId,
              fileName: name,
              loading: 0,
              serverPath: null,
              tempName: null
            }))
          }}
          fileLimit={(+attachedFileSize) / 1000000}
          fileType={['pdf']}
          labelWidth='250px'
        />
      }
    },
    {
      accessor: 'cfsName',
      Header: "" ,
      width: 200,
      maxWidth: 250,
      Cell:TableCellCfsList
    }
     
  ];


  const docCheckColumns: IDataTableColumn[] = [

    {
      accessor: 'docId',
      Header: '',
      width: 200,
      maxWidth: 230,
    },
    {
      accessor: 'name',
      Header: 'Check List Name',
      Cell: TableCheckListDocName,
      width: 200,
      maxWidth: 230,
    },

    {
      accessor: 'check',
      Header: 'Check',
      Cell: documentCheck,
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: 'remarks',
      Header: 'Remarks ',
      Cell: TableCheckListRemark,
      width: 180,
      maxWidth: 180,
    },
    {
      accessor: 'linerId',
      Header: 'Liner ID',
      width: 150,
      maxWidth: 150,
    },
    {
      accessor: 'checkListUpdated',
      Header: 'Last Updated ',
      width: 180,
      maxWidth: 180,
    }


  ];
  useEffect(() => {
    dispatch(fetchCFSNameByCountry({
      country: dataCountry
    }))
  }, [country])

  useEffect(() => {
    if (!blCountry) return
    dispatch(
      getReleaseTypeList(
        { partnerCountry: blCountry, linerId: selectedLinerId }
      )
    );
    if(!language)return
    i18n.changeLanguage(
      `${blCountry}_${language}`
    );
  }, [blCountry, selectedLinerId,language]);

  useEffect(() => {
    if (!disableFields && demurrageViewForm?.list?.length > 0) {
      let tempSelectedTruckerName = {};
      let tempSelectedDate = {};
      let tempselectedPickupLocation = {};
      let tempSelectedTruckerCode = {};
      let tempSelectedtruckerId = {};
      let tempSelectedAttachmentName = {};
      let tempSelectedAttachmentFilePath = {};

      for (var i = 0; i < demurrageViewForm?.list.length; i++) {
        tempSelectedTruckerName = {
          ...tempSelectedTruckerName,
          [demurrageViewForm.list[i]?.containerNo]:
            demurrageViewForm?.list[i]?.truckerName || null,
          location: null,
        };
        tempSelectedDate = {
          ...tempSelectedDate,
          [demurrageViewForm.list[i]?.containerNo]: demurrageViewForm?.list[i]
            ?.pickupDate
            ? moment(
              demurrageViewForm?.list[i]?.pickupDate,
              'YYYY-MM-DD'
            )?.toDate()
            : null,
          intended: null,
        };
        let snContainer = data.list.find(
          (v: any) => v.containerNo === demurrageViewForm.list[i]?.containerNo
        );
        if (
          snContainer?.cntStatus === 'Completed' ||
          snContainer?.cntStatus?.includes('Rejected')
        ) {
          tempSelectedTruckerCode = {
            ...tempSelectedTruckerCode,
            [demurrageViewForm.list[i]?.containerNo]:
              snContainer?.truckerCode || null,
            truckerCode: null,
          };
        } else {
          tempSelectedTruckerCode = {
            ...tempSelectedTruckerCode,
            [demurrageViewForm.list[i]?.containerNo]:
              demurrageViewForm?.list[i]?.truckerCode || null,
            truckerCode: null,
          };
        }

        tempSelectedtruckerId = {
          ...tempSelectedtruckerId,
          [demurrageViewForm.list[i]?.containerNo]:
            demurrageViewForm?.list[i]?.truckerId || null,
        };

        tempselectedPickupLocation = {
          ...tempselectedPickupLocation,
          [demurrageViewForm.list[i]?.containerNo]:
            demurrageViewForm?.list[i]?.pickupLocation || null,
          location: null,
        };
      }
      for (var i = 0; i < demurrageViewForm?.attList.length; i++) {
        tempSelectedAttachmentName = {
          ...tempSelectedAttachmentName,
          [demurrageViewForm.attList[i]?.docId]: {
            name: demurrageViewForm?.attList[i]?.fileName || null,
          },
        };
        tempSelectedAttachmentFilePath = {
          ...tempSelectedAttachmentFilePath,
          [demurrageViewForm.attList[i]?.docId]:
            demurrageViewForm?.attList[i]?.filePath || null,
        };
      }

      dispatch(updateSelectedTruckerName(tempSelectedTruckerName));
      dispatch(updateSelectedDateDV(tempSelectedDate));
      dispatch(updateSelectedtruckerCode(tempSelectedTruckerCode));
      dispatch(updateSelectedtruckerId(tempSelectedtruckerId));

      dispatch(updateSelectedPickupLocation(tempselectedPickupLocation));
      dispatch(updateSelectedAttachmentName(tempSelectedAttachmentName));
      dispatch(
        updateSelectedAttachmentFilePath(tempSelectedAttachmentFilePath)
      );
      setSenderEmail(
        demurrageViewForm?.senderEmail
          ? demurrageViewForm?.senderEmail
          : senderEmail
      );
    } else if (!disableFields && data?.list?.length > 0) {
      let tempSelectedTruckerName = {};
      let tempSelectedDate = {};
      let tempselectedPickupLocation = {};
      let tempSelectedTruckerCode = {};
      let tempSelectedtruckerId = {};

      for (var i = 0; i < data?.list.length; i++) {
        tempSelectedTruckerName = {
          ...tempSelectedTruckerName,
          [data.list[i]?.containerNo]: data.list[i]?.truckerName || null,
          location: null,
        };
        tempSelectedDate = {
          ...tempSelectedDate,
          [data.list[i]?.containerNo]: data.list[i]?.pickupDate
            ? moment(data.list[i]?.pickupDate, 'YYYY-MM-DD')?.toDate()
            : null,
          intended: null,
        };
        tempSelectedTruckerCode = {
          ...tempSelectedTruckerCode,
          [data.list[i]?.containerNo]: data.list[i]?.truckerCode || null,
          truckerCode: null,
        };

        tempSelectedtruckerId = {
          ...tempSelectedtruckerId,
          [data.list[i]?.containerNo]: data.list[i]?.truckerId || null,
        };

        tempselectedPickupLocation = {
          ...tempselectedPickupLocation,
          [data.list[i]?.containerNo]: data.list[i]?.pickupLocation || selectedDefaultPickupLocation || null,
          location: null,
        };
      }

      dispatch(updateSelectedTruckerName(tempSelectedTruckerName));
      dispatch(updateSelectedDateDV(tempSelectedDate));
      dispatch(updateSelectedtruckerCode(tempSelectedTruckerCode));
      dispatch(updateSelectedtruckerId(tempSelectedtruckerId));

      dispatch(updateSelectedPickupLocation(tempselectedPickupLocation));
    } else {
      if (!disableFields && location.pathname.split('/')[3] === 'instruction') {
        let tempSelectedTruckerCode = {};
        let tempselectedPickupLocation = {};

        for (var i = 0; i < data?.list.length; i++) {
          tempSelectedTruckerCode = {
            ...tempSelectedTruckerCode,
            [data.list[i]?.containerNo]: data.list[i]?.truckerCode || null,
            truckerCode: null,
          };
          tempselectedPickupLocation = {
            ...tempselectedPickupLocation,
            [data.list[i]?.containerNo]: selectedDefaultPickupLocation || null,
            location: null,
          };
        }

        dispatch(updateSelectedtruckerCode(tempSelectedTruckerCode));
        dispatch(updateSelectedPickupLocation(tempselectedPickupLocation));
      } else {
        dispatch(updateSelectedtruckerCode({}));
        dispatch(updateSelectedPickupLocation({}));
      }

      dispatch(updateSelectedTruckerName({}));
      dispatch(updateSelectedDateDV({ intended: null }));
      dispatch(updateSelectedtruckerId({}));
      dispatch(updateSelectedAttachmentName({}));
      dispatch(updateSelectedAttachmentFilePath({}));
    }
  }, [disableFields, demurrageViewForm, data, selectedDefaultPickupLocation]);


  const approveRejectAccess = useMemo(() => !(instructionList.filter(
    (v: any) =>
      v?.docId === demurrageViewForm?.dndInstructionId
  ).map((u: any) => u?.event === 'Completed').includes(true)
    || (demurrageForm.status == 'Completed' || demurrageForm.status?.includes('Rejected'))
    ||
    !(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y' || !accessGroupLevel.includes('Y'))
    ||
    dataCountry !== blCountry || rbac.rbacDetails.demurrageApproveReject !== 'Y')
    , [instructionList, demurrageViewForm, dataCountry,
      blCountry, rbac.rbacDetails.demurrageApproveReject, demurrageForm.status])

  return (
    <Card style={{ minHeight: '100%' }}>
      <Card.Header
        className='d-flex align-items-center border-bottom bg-white sticky-top'
        style={{ zIndex: 10 }}>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_2:demurrageInstruction')}
        </h2>
      </Card.Header>
      <Card.Body
        className={
          blCountry === 'MY' &&
            (oblForm?.pod === 'MYBTU' ||
              oblForm?.pod === 'MYKCH' ||
              oblForm?.pod === 'MYBKI')
            ? 'd-none'
            : 'd-block'
        }>
        <div className='container'>
          {profileDetails?.partnerType === 'customer' &&
            blCountry === 'US' || blCountry === 'CA' ? (
            <>
              <label className='form-label'>
                Available only for imports into USA
              </label>
              <p style={{ fontSize: '12px' }}>
                Let us know the intended pickup date for your shipment and we
                will revert with the invoice for the PORT demurrage within some
                minutes.
              </p>{' '}
            </>
          ) : (
            ''
          )}
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:demurrageInstruction') != '' && (
                <div className='col-lg-12 mb-1'>
                  {t('resource_message:demurrageInstruction') != '' && (
                    <div className='col-lg-12 mb-1'>
                      <Form.Label style={{ color: '#f5822a' }}>
                        <ul style={{ paddingLeft: '15px' }}>
                          {t('resource_message:demurrageInstruction')
                            .split('\n')
                            .map((v: any) => (
                              <li>{v}</li>
                            ))}
                        </ul>
                      </Form.Label>
                    </div>
                  )}
                </div>
              )}

              {blCountry === 'MY' && (
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='carrierHaulage.pod'>
                    <Form.Label className='fs--1'>
                      {t('resource_3:Port_of_destination')}
                      <span>
                        {' '}
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          title=' '
                          className='me-2'
                        />
                      </span>
                    </Form.Label>
                    <Form.Control
                      type='POD'
                      size='sm'
                      //   disabled={disableFields}
                      disabled={true}
                      value={oblForm?.pod || ''}
                    />
                    <div className='invalid-feedback'></div>
                  </Form.Group>
                </div>
              )}

              {['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) ? (
                <div className='col-lg-5 mb-3 d-none' style={{ zIndex: 15 }}>
                  <Form.Group controlId='invoiceChanges.releaseType'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {t('resource_2:releaseType')}&nbsp;
                    </Form.Label>
                    <Form.Select
                      aria-label={t('resource_2:releaseType')}
                      {...register('releaseType')}
                      className='fs--1'
                      disabled={disableFields}
                      value={releaseType}
                      onChange={(e) => {
                        setReleaseType(e.target.value);
                        _onReleaseTypeChange(e.target.value);
                      }}>
                      {releaseTypeList.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </div>
              ) : (
                ''
              )}
              {['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) ? (
                ''
              ) : (
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='carrierHaulage.senderEmail'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {t('resource_2:senderEmail')}
                      <span>
                        {' '}
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          title='We will send the invoice for the demurrage within some minutes to this email address.'
                          className='me-2'
                        />
                      </span>
                    </Form.Label>
                    <Form.Control
                      {...register('senderEmail')}
                      className={`fs--1 form-control ${errors.senderEmail ? 'is-invalid' : ''
                        }`}
                      type='email'
                      onChange={(e) => {
                        setSenderEmail(e.target.value);
                      }}
                      size='sm'
                      disabled={disableFields}
                      value={
                        disableFields
                          ? demurrageViewForm?.senderEmail || ''
                          : senderEmail
                      }
                    />
                    <div className='invalid-feedback'>
                      {errors.senderEmail?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              )}
              {profileDetails.partnerType == 'liner' ? (
                <div className='col-lg-6 mb-3' style={{ zIndex: 1 }}>
                  <Form.Group controlId='carrierHaulage.requestedPartnerNm'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {t('resource_2:companyName')}
                      <span>
                        {' '}
                        {/* <FontAwesomeIcon
                        icon={faInfoCircle}
                        title='We will send the invoice for the demurrage within some minutes to this email address.'
                        className='me-2'
                      /> */}
                      </span>
                    </Form.Label>
                    <Form.Control
                      {...register('requestedPartnerName')}
                      className={`fs--1 form-control ${errors.requestedPartnerName ? 'is-invalid' : ''
                        }`}
                      type='text'
                      // onChange={(e) => setSenderEmail(e.target.value)}
                      size='sm'
                      disabled={disableFields}
                      value={
                        disableFields
                          ? demurrageViewForm?.requestedPartnerName || ''
                          : requestedPartnerName
                      }
                    />
                    <div className='invalid-feedback'>
                      {errors.requestedPartnerName?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}

              {!disableFields && ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) ? (
                <div className='col-lg-6 mb-3 '>
                  <Form.Group>
                    <Form.Label className='fs--1'>
                      {t('resource_7:pickupLocationForAllContainer')}{' '}
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        title='Change Pickup location for all conatainer'
                        className='me-2'
                      />
                    </Form.Label>
                    <div>
                      {['KE', 'TZ', 'UG'].includes(blCountry) ?
                        <div style={{ zIndex: 999 }}>
                          <AsyncSelect
                            menuPortalTarget={document.body}
                            classNames={{
                              control: (state) => {
                                return `dropControl fs--1 asyncDropField m-0 p-0
                          form-control ${state.isDisabled ? 'disabledField' : ''}
                          ${state.isFocused ? 'focusField' : ''}`;
                              },
                            }}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (base) => ({}),
                            }}
                            cacheOptions
                            onChange={(e: any) => {
                              let containers: any = {};
                              let emptyContainers: any = {};
                              let pickupDateConatiner: any = {};

                              for (let k of data.list) {
                                if (k?.containerNo) {
                                  if (
                                    e?.cfsName ===
                                    selectedPickupLocation?.[k?.containerNo]
                                  ) {
                                  } else {
                                    containers[k.containerNo] = e?.cfsName;
                                    emptyContainers[k.containerNo] = null;
                                  }
                                }
                                if (!e?.cfsName) {
                                  pickupDateConatiner[k.containerNo] = null;
                                }
                              }

                              dispatch(
                                updateSelectedPickupLocation({
                                  ...selectedPickupLocation,
                                  ...containers,
                                  location: e?.cfsName,
                                })
                              );
                              dispatch(
                                updateSelectedTruckerName({
                                  ...selectedTruckerName,
                                  ...emptyContainers,
                                })
                              );
                              dispatch(
                                updateSelectedtruckerId({
                                  ...selectedtruckerId,
                                  ...emptyContainers,
                                })
                              );
                              dispatch(
                                updateselectedInternalCode({
                                  ...selectedinternalCode,
                                  ...emptyContainers,
                                })
                              );
                              dispatch(
                                updateSelectedDateDV({
                                  ...selectedDate,
                                  ...pickupDateConatiner,
                                })
                              );

                            }}
                            value={{
                              label: selectedPickupLocation?.location || '',
                              value: selectedPickupLocation?.location || '',
                            }}

                            loadOptions={locationOptions}
                            isDisabled={disableFields}
                            isClearable={true}
                          />
                        </div>
                        : <Form.Select
                          disabled={disableFields}
                          value={selectedPickupLocation?.location || ''}
                          onChange={(e) => {
                            let containers: any = {};
                            let emptyContainers: any = {};
                            let pickupDateConatiner: any = {};
                            if (e.target.value) {
                              for (let k of data.list) {
                                if (k?.containerNo) {
                                  if (
                                    e.target.value ===
                                    selectedPickupLocation?.[k?.containerNo]
                                  ) {
                                  } else {
                                    containers[k.containerNo] = e.target.value;
                                    emptyContainers[k.containerNo] = null;
                                  }
                                  if (e.target.value === '1002') {
                                    pickupDateConatiner[k.containerNo] =
                                      selectedDate?.intended;
                                  } else {
                                    pickupDateConatiner[k.containerNo] = null;
                                  }
                                }
                              }
                            }
                            dispatch(
                              updateSelectedPickupLocation({
                                ...selectedPickupLocation,
                                ...containers,
                                location: e.target.value,
                              })
                            );
                            dispatch(
                              updateSelectedTruckerName({
                                ...selectedTruckerName,
                                ...emptyContainers,
                              })
                            );
                            dispatch(
                              updateSelectedtruckerId({
                                ...selectedtruckerId,
                                ...emptyContainers,
                              })
                            );
                            dispatch(
                              updateselectedInternalCode({
                                ...selectedinternalCode,
                                ...emptyContainers,
                              })
                            );
                            dispatch(
                              updateSelectedDateDV({
                                ...selectedDate,
                                ...pickupDateConatiner,
                              })
                            );
                          }}
                          className='fs--1 form-control  form-select'>
                          {
                            <>
                              {pickUpLocation.map((option: any) => (
                                <option key={option.value} value={option.value}>
                                  {option.text}
                                </option>
                              ))}
                            </>
                          }
                        </Form.Select>}
                    </div>
                  </Form.Group>
                </div>
              ) : (
                ''
              )}

              {blCountry === 'US' || blCountry === 'CA' ||
                (!disableFields && ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) ||
                (blCountry === 'MY' && oblForm?.pod === 'MYPEN') ? (
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='carrierHaulage.IntendedDate'>
                    <Form.Label className='fs--1'>
                      {t('resource_7:IntendedDateForAllContainer')}{' '}
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        title='We will calculate the storage/demurrage up to this date.'
                        className='me-2'
                      />
                    </Form.Label>
                    <div className='releaseDate'>
                      <DatePicker
                        selected={selectedDate?.intended}
                        onChange={(date) => {
                          let containers: any = {};
                          if (date) {
                            for (let k of data.list) {
                              if (k?.containerNo) {
                                if (['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) {
                                  if (
                                    selectedPickupLocation?.[k?.containerNo] !==
                                    '1001'
                                  ) {
                                    containers[k.containerNo] = date;
                                  } else {
                                    containers[k.containerNo] = null;
                                  }
                                } else {
                                  containers[k.containerNo] = date;
                                }
                              }
                            }
                          }

                          dispatch(
                            updateSelectedDateDV({
                              ...selectedDate,
                              ...containers,
                              intended: date,
                            })
                          );
                        }}
                        className='fs--2 form-control'
                        timeIntervals={5}
                        dateFormat={getDateFormat()}
                        isClearable={clearable}
                        popperProps={{
                          strategy: 'fixed',
                        }}
                        minDate={new Date()}
                        //disabled={DatePickDisabled}
                        //value={value}
                        disabled={disableFields}
                      />
                    </div>
                  </Form.Group>
                </div>
              ) : (
                ''
              )}

              {['SG','MY','VN'].includes(blCountry) ? (
                <div className='col-lg-6 mb-3' style={{ zIndex: 1 }}>
                  <Form.Group controlId='carrierHaulage.IntendedDate'>
                    <Form.Label className='fs--1'>
                      {oblForm?.pod === 'MYPKG' && blCountry === 'MY'
                        ? t('resource_7:ledger_acc_number') + ' '
                        : oblForm?.pod === 'MYPEN' && blCountry === 'MY'
                          ? t('resource_5:dic_number') + ' '
                          : blCountry === 'MY' &&
                            (oblForm?.pod === 'MYPGU' || oblForm?.pod === 'MYTPP')
                            ? t('resource_7:forward_agent_code') + ' '
                            : oblForm?.pod === 'MYKUA' && blCountry === 'MY'
                              ? t('') + ' '
                              : t('resource_7:IntendedDateForAllContainerSG') + ' '}
                      {/*  <FontAwesomeIcon
                        icon={faInfoCircle}
                        title='We will calculate the storage/demurrage up to this date.'
                        className='me-2'
                      /> */}
                    </Form.Label>
                    <div>
                      {
                        <Form.Control
                          size='sm'
                          type='text'
                          className={
                            oblForm?.pod === 'MYKUA' && blCountry === 'MY'
                              ? 'd-none'
                              : 'd-block'
                          }
                          onChange={(e) => {
                            let containers: any = {};

                            for (let k of data.list) {
                              if (k?.containerNo) {
                                containers[k.containerNo] = e.target.value;
                              }
                            }

                            dispatch(
                              updateSelectedtruckerCode({
                                ...selectedTruckerCode,
                                ...containers,
                                // intended: date,
                                truckerCode: e.target.value,
                              })
                            );
                          }}
                          value={selectedTruckerCode?.truckerCode}
                          disabled={disableFields}
                        />
                        /* <DatePicker
                        truckerCode
                        selected={selectedDate?.intended}
                        onChange={(date) => {
                          let containers: any = {};
                          if (date) {
                            for (let k of data.list) {
                              if (k?.containerNo) {
                                containers[k.containerNo] = date;
                              }
                            }

                            setPayloadList(
                              data?.list?.map((f: any) => ({
                                ...f,
                                pickupDate: moment(date + ' UTC').format(
                                  'yyyy-MM-DD'
                                ),
                              }))
                            );
                          }

                          dispatch(
                            updateSelectedDateDV({
                              ...selectedDate,
                              ...containers,
                              intended: date,
                            })
                          );
                        }}
                        className='fs--2 form-control'
                        timeIntervals={5}
                        dateFormat='MM/dd/yyyy'
                        isClearable={clearable}
                        popperProps={{
                          strategy: 'fixed',
                        }}
                        // onChange={(e) => SetIntendedDate(e.target.value)}
                        //disabled={DatePickDisabled}
                        //value={value}
                        disabled={disableFields}
                      /> */
                      }
                    </div>
                  </Form.Group>
                </div>
              ) : (
                ''
              )}

              {!disableFields && ['ZA','GB', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) ? (
                <div className='col-lg-6 mb-3'>
                  <Form.Group>
                    <Form.Label className='fs--1'>
                      {t('resource_7:truckerNameForAllContainer')}{' '}
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        title='Change trucker name for all conatainer'
                        className='me-2'
                      />
                    </Form.Label>
                    <div style={{ zIndex: 999 }}>
                      <AsyncSelect
                        menuPortalTarget={document.body}
                        classNames={{
                          control: (state) => {
                            return `dropControl fs--1 asyncDropField m-0 p-0
                          form-control ${state.isDisabled ? 'disabledField' : ''
                              }
                          ${state.isFocused ? 'focusField' : ''}`;
                          },
                        }}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (base) => ({}),
                        }}
                        cacheOptions
                        onChange={(e: any) => {
                          let containers: any = {};
                          let emptyContainers: any = {};
                          let truckerIdConatiner: any = {};
                          let pickupDateConatiner: any = {};
                          if (e?.truckerName) {
                            for (let k of data.list) {
                              if (k?.containerNo) {
                                if (
                                  selectedPickupLocation?.[k?.containerNo] !==
                                  '1002' &&
                                  !(
                                    impHaulage === 'Carrier Haulage' &&
                                    ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
                                  )
                                ) {
                                  containers[k.containerNo] = e?.truckerName;
                                  emptyContainers[k.containerNo] = e?.internalCode;
                                  truckerIdConatiner[k.containerNo] =
                                    e?.truckerId;
                                }
                                if (
                                  selectedPickupLocation?.[k?.containerNo] ===
                                  '1002'
                                ) {
                                  pickupDateConatiner[k.containerNo] =
                                    selectedDate?.intended;
                                } else {
                                  pickupDateConatiner[k.containerNo] = null;
                                }
                              }
                            }
                          }
                          dispatch(
                            updateSelectedTruckerName({
                              ...selectedTruckerName,
                              ...containers,
                              location: e?.truckerName,
                            })
                          );
                          dispatch(
                            updateSelectedtruckerId({
                              ...selectedtruckerId,
                              ...truckerIdConatiner,
                            })
                          );
                          dispatch(
                            updateselectedInternalCode({
                              ...selectedinternalCode,
                              ...emptyContainers,
                            })
                          );
                          dispatch(
                            updateSelectedDateDV({
                              ...selectedDate,
                              ...pickupDateConatiner,
                            })
                          );
                        }}
                        value={{
                          label: selectedTruckerName?.location || '',
                          value: selectedTruckerName?.location || '',
                        }}
                        loadOptions={promiseOptions}
                        isDisabled={
                          (impHaulage === 'Carrier Haulage' && ['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry))
                          || disableFields
                        }
                        isClearable={true}
                      />
                    </div>
                  </Form.Group>
                </div>
              ) : (
                ''
              )}
              {profileDetails?.partnerType === 'liner' &&
                (
                  rbac.rbacDetails.crrUnpaidHardStop == 'Y' ||
                  rbac.rbacDetails.crrUnpaidSoftStop == 'Y'
                ) &&
                releasePOPData.length > 0 &&
                <ToggleDiv
                  className='col-lg-12 mb-2 demmurageTable'
                  style={{ zIndex: 1 }}
                  showText={'Click here to view Payment details'}
                  hideText={'Click here to hide Payment details'}
                  initial={releasePOPData.findIndex((i) => i.invoicestatus != 1) != -1}
                >

                  <DataTable
                    data={releasePOPData}
                    gridName='RELEASE_POP'
                    columns={POPColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releasepop'
                    autoHeight={true}
                    staticHiddenCols={[]}
                    maxHeight='250px'
                  />
                </ToggleDiv>
              }

              {profileDetails?.partnerType === 'customer' &&
                blCountry === 'US' || blCountry == 'CA' ? (
                <label className='form-label' style={{ color: '#f5822a' }}>
                  Please submit the Intended Pickup Date only for those
                  containers for which the Demurrage Invoice is needed
                </label>
              ) : (
                ''
              )}
              <div
                className='col-lg-12 mb-2 demmurageTable'
                style={{ zIndex: 1 }}>
                {data.list?.length > 0 ? (
                  <DataTable
                    data={data.list}
                    gridName='RELEASE_INTSRUCTION'
                    columns={contactDetailsColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releaseInstruction'
                    autoHeight={true}
                    staticHiddenCols={
                      blCountry === 'US' || blCountry == 'CA'
                        ? [
                          'blContainerId',
                          'pickupLocation',
                          'truckerName',
                          'internalCode',
                          'truckerId',
                          'cntStatus',
                          'requestedOn',
                          'approvedRejectedOn',
                          'truckerCode',
                        ]
                        : ['SG','VN'].includes(blCountry) ||
                          (blCountry === 'MY' &&
                            (oblForm?.pod === 'MYPKG' ||
                              oblForm?.pod === 'MYPGU' ||
                              oblForm?.pod === 'MYTPP'))
                          ? [
                            'blContainerId',
                            'pickupLocation',
                            'truckerName',
                            'internalCode',
                            'truckerId',
                            'IntendedDate',
                          ].concat(
                            !isEdit && !disableFields
                              ? [
                                'cntStatus',
                                'requestedOn',
                                'approvedRejectedOn',
                              ]
                              : []
                          )
                          : blCountry === 'MY' && oblForm?.pod === 'MYPEN'
                            ? [
                              'blContainerId',
                              'pickupLocation',
                              'truckerName',
                              'internalCode',
                              'truckerId',
                            ].concat(
                              !isEdit && !disableFields
                                ? [
                                  'cntStatus',
                                  'requestedOn',
                                  'approvedRejectedOn',
                                ]
                                : []
                            )
                            : blCountry === 'MY' && oblForm?.pod === 'MYKUA'
                              ? [
                                'blContainerId',
                                'pickupLocation',
                                'truckerName',
                                'internalCode',
                                'truckerId',
                                'truckerCode',
                                'IntendedDate',
                              ].concat(
                                !isEdit && !disableFields
                                  ? [
                                    'cntStatus',
                                    'requestedOn',
                                    'approvedRejectedOn',
                                  ]
                                  : []
                              )
                              : ['ZA','GB', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)
                                ? [
                                  'blContainerId',
                                  'truckerCode',
                                  'cntStatus',
                                  'requestedOn',
                                  'approvedRejectedOn',
                                ]
                                : (['KE', 'UG', 'TZ'].includes(blCountry))
                                  ? [
                                    'blContainerId',
                                    'truckerCode',
                                    'cntStatus',
                                    'requestedOn',
                                    'approvedRejectedOn',
                                    'truckerId',
                                    'truckerCode',
                                    'truckerName',
                                    'internalCode',
                                  ]
                                  : ['blContainerId']
                    }
                  />
                ) : (
                  ''
                )}
              </div>
              {['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) && docAttachment.list && docList?.length ? (
                <div className='col-lg-12 mt-2 mb-2 demmurageTable'>
                  {(!disableFields) && <span className='badge bg-secondary mb-2'>
                    {t('resource_message:docInfo')}{' '}
                  </span>}
                  <div className='col-lg-12 mt-2 mb-2 demmurageTable'>
                    <DataTable
                      data={docAttachment?.list ?? []}
                      gridName='RELEASE_ATTACHMENT'
                      columns={docAttachmentColumns}
                      sortable={false}
                      searchable={false}
                      showColumnFilter={false}
                      selection={false}
                      perPage={10}
                      pagination={false}
                      showExport={false}
                      uniqueFilterKey='releaseAttachment'
                      autoHeight={true}
                      staticHiddenCols={accessGroupLevel.includes('Y') ? ['dndInstructionAttId', 'fileName',] : ['dndInstructionAttId', 'fileName', 'verify', 'linerEmaiId', 'lastUpdatedId']}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              {accessGroupLevel.includes('Y') && checkListData?.length > 0 &&
                <div className='demmurageTableCheckList'>
                  <DataTable
                    data={checkListData ?? []}
                    gridName='CHECK_LIST'
                    columns={docCheckColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releaseAttachment'
                    autoHeight={true}
                    staticHiddenCols={['docId']}

                  />
                </div>
              }
              {['ZA','GB', 'JO', 'KE', 'TZ', 'UG', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry) &&
                (
                  profileDetails.partnerType == 'liner'
                  || demurrageViewForm.status == 'Completed'
                ) &&
                linerDocs?.length > 0 &&
                <div className='col-lg-12 mt-2 mb-2 demmurageTable'>
                  {approveRejectAccess && <span className='badge bg-secondary mb-2'>
                    {t('resource_message:docInfo')}{' '}
                  </span>}

                  <DataTable
                    data={linerDocs}
                    gridName='LINER_RELEASE_ATTACHMENT'
                    columns={linerDocAttachmentColumns}
                    sortable={false}
                    searchable={false}
                    showColumnFilter={false}
                    selection={false}
                    perPage={10}
                    pagination={false}
                    showExport={false}
                    uniqueFilterKey='releaseAttachment'
                    autoHeight={true}
                    staticHiddenCols={rbac.rbacDetails.cfsEmptyName == 'Y'?['dndInstructionAttId']:["cfsName",'dndInstructionAttId']}
                  />
                </div>
              }
              <div className='col-lg-6 mb-3'>
                <Form.Group controlId='carrierHaulage.userRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:userRemark')}
                  </Form.Label>
                  <Form.Control
                    className={'fs--1 form-control'}
                    value={userRemarks}
                    maxLength={200}
                    as='textarea'
                    onChange={(e) => {
                      setUserRemarks(e.target.value);
                    }}
                    disabled={
                      profileDetails.partnerType == 'liner' ||
                      disableFields ||
                      demurrageViewForm.status === 'Completed'
                    }
                  />
                </Form.Group>
              </div>

              {rbac.rbacDetails.demurrageApproveReject === 'Y' ||
                disableFields ? (
                <div className='col-lg-6 mb-3'>
                  <Form.Group controlId='carrierHaulage.rejectionRemark'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:rejectRemark')}
                    </Form.Label>
                    <Form.Control
                      className={'fs--1 form-control'}
                      value={remarks}
                      as='textarea'
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      disabled={
                        demurrageViewForm.status == 'Completed' || demurrageViewForm.status?.includes('Rejected')
                          ? true
                          : rbac.rbacDetails.demurrageApproveReject === 'Y'
                            ? false
                            : disableFields
                      }
                    />
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
              {dataCountry === blCountry &&
                rbac.rbacDetails.demurrageApproveReject === 'Y' ? (
                <>
                  {instructionList
                    .filter(
                      (v: any) =>
                        v?.docId === demurrageViewForm?.dndInstructionId
                    )
                    .map((u: any) => u?.event === 'Completed')
                    .includes(true) || (demurrageForm.status == 'Completed' || demurrageForm.status?.includes('Rejected')) ? (
                    <></>
                  ) : (
                    <>
                      <div className='d-flex justify-content-center align-items-center'>
                        {(accessGroupLevel[demurrageViewForm.groupLevel] == 'Y' || !accessGroupLevel.includes('Y')) && <>
                          <Button
                            disabled={
                              demurrageViewForm?.status === 'Completed' ||
                                demurrageViewForm?.status?.includes('Rejected')
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('A', 'CONTAINER_RELEASE');
                            }}
                            variant='success'
                            className='me-2 approve'
                            size='sm'>
                            <FontAwesomeIcon
                              icon={faCheck}
                              className='me-2 approve'
                            />
                            {t('resource_1:approve')}
                          </Button>

                          <Button
                            variant='danger'
                            className='reject me-2'
                            size='sm'
                            disabled={
                              demurrageViewForm?.status === 'Completed' ||
                                demurrageViewForm?.status?.includes('Rejected')
                                ? disableFields
                                : false
                            }
                            onClick={(e) => {
                              onApproveReject('R', 'CONTAINER_RELEASE');
                            }}>
                            <FontAwesomeIcon
                              icon={faTimes}
                              className='me-2   reject'

                            />
                            {t('resource_1:reject')}
                          </Button>
                          {rbac.rbacDetails.snoozeCUD === 'Y' && !location.pathname.includes('/bl') ? <SnoozedToButton blNum={AESDecrypt(blNum)} blEventId={blEventId_docInsDashboard} /> : ""}
                        </>
                        }
                      </div>
                    </>
                  )}
                </>
              ) : (
                !disableFields && (
                  <div className='d-flex justify-content-center align-items-center'>
                    {submitFlag ? (
                      <Lottie
                        animationData={infiniteLoop}
                        loop={true}
                        style={{ height: '80px', width: '80px' }}
                      />
                    ) : (
                      <>
                        <Button
                          className='me-2'
                          variant='falcon-primary'
                          size='sm'
                          type='submit'
                          disabled={(() => {

                            // disable if file uploading
                            if (Object.values(loadingFileState).some(
                              (val) => val != 100 && val != -1 && val != undefined
                            )) return true

                            if (blCountry == 'US' || blCountry == 'CA') {
                              if (!senderEmail || senderEmail?.trim?.() == '') return true
                              return !data.list
                                .some((v: any) =>
                                  selectedDate?.[v?.containerNo] ? true : false
                                )
                            }
                            if (['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) {
                              if (docAttachment?.list?.length == 0) return true

                              if (docAttachment.list.filter((x: any) => x.m).findIndex(
                                (d) =>
                                  // check for file name  
                                  !selectedAttachmentName?.[d?.docId]

                                  || ((
                                    // check for uploaded files
                                    loadingFileState[d?.docId] !== 100
                                    || !serverFilePath[d?.docId]
                                  )

                                    // check for submitted files
                                    && ((demurrageViewForm.attList.findIndex((i) => (i?.docId == d?.docId)
                                      && i?.dndInstructionAttId) == -1)
                                      || loadingFileState[d?.docId] == -1)
                                  )
                              ) != -1) return true

                              if (['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) return !PickupLocationValidityFlag

                              const usedContainers = Object.entries(selectedPickupLocation)
                                .filter(([key, val]) => val && val != '' && key != 'location')
                                .map((([key, _]) => key))
                              if (usedContainers.length < 1) return true
                              if (['KE', 'UG', 'TZ'].includes(blCountry)) return usedContainers.some((c) => !selectedDate?.[c])
                            }

                            if (blCountry === 'MY' && oblForm?.pod === 'MYKUA') return false

                            if (blCountry === 'MY' && oblForm?.pod === 'MYPEN') {
                              if (!senderEmail || senderEmail?.trim?.() == '') return true
                              return !data.list
                                .map((v: any) =>
                                  selectedDate?.[v?.containerNo] &&
                                    selectedTruckerCode?.[v?.containerNo]
                                    ? true
                                    : false
                                ).includes(true)
                            }
                            if (['SG','MY','VN'].includes(blCountry)) {
                              if (!senderEmail || senderEmail?.trim?.() == '') return true
                              return !data.list
                                .map((v: any) =>
                                  selectedTruckerCode?.[v?.containerNo]
                                    ? true
                                    : false
                                )
                                .includes(true)
                            }
                            return true
                          })()}>
                          <FontAwesomeIcon icon={faSave} className='me-2' />
                          {t('resource_1:submit')}
                        </Button>
                        <Button
                          className='me-2'
                          variant='falcon-danger'
                          size='sm'
                          onClick={resetForm}
                          style={{ zIndex: 1 }}>
                          <FontAwesomeIcon icon={faRedo} className='me-2' />
                          {t('resource_1:reset')}
                        </Button>
                        {
                          rbac.rbacDetails.saveAsDraft === 'Y' && ((newEvent || recentId?.event == 'Not Submitted') && (currStatus != 'Submitted') && (!currStatus.includes('Rejected'))) &&
                          <Button
                            className='me-2'
                            variant='falcon-primary'
                            size='sm'
                            type='submit'
                            onClick={() => {
                              setIsDraft(true)
                            }}
                            disabled={
                              (
                                () => {

                                  // if loading
                                  if (Object.values(loadingFileState).some(
                                    (val) => val != 100 && val != -1 && val != undefined
                                  )) return true

                                  if (Object.values(selectedPickupLocation).length < 2
                                  ) return true

                                  if (userRemarks.trim() != '')
                                    return false

                                  if (['ZA','GB', 'UG', 'KE', 'TZ', 'JO', 'AU', 'NZ', 'HK', 'PK'].includes(blCountry)) {
                                    if (Object.values(selectedPickupLocation).findIndex(n => n && n !== '0') != -1)
                                      return false

                                    if (Object.values(loadingFileState).some((val) => val == 100))
                                      return false
                                  }
                                  if (['SG','MY','VN'].includes(blCountry)) {
                                    if (senderEmail && senderEmail?.trim?.() != '')
                                      return false

                                    if (data.list
                                      .findIndex((v: any) =>
                                        selectedTruckerCode?.[v?.containerNo] &&
                                        selectedTruckerCode?.[v?.containerNo].trim() != ''
                                      ) != -1)
                                      return false
                                  }

                                  if (blCountry == 'US' || blCountry == 'MY' || blCountry == 'CA') {
                                    if (senderEmail && senderEmail?.trim?.() != '')
                                      return false

                                    if (data.list
                                      .findIndex((v: any) =>
                                        selectedDate?.[v?.containerNo]
                                      ) != -1)
                                      return false
                                  }


                                  return true
                                }
                              )()
                            }>
                            <FontAwesomeIcon icon={faFolder} className='me-2' color='#f5822a' />
                            {t('resource_3:saveAsDraft')}
                          </Button>}
                      </>
                    )}


                  </div>
                )
              )}
              {
              (currStatus == 'Submitted' || currStatus?.includes('Rejected') || currStatus == 'Draft')   &&
              profileDetails.partnerType != 'liner' &&
              disableFields ? (
                <div className='d-flex justify-content-center align-items-center'>
                  <Button
                    variant='falcon-primary'
                    size='sm'
                    className='me-2'
                    onClick={async() => {
                      if (
                        (rbac.rbacDetails.crrUnpaidHardStop == 'Y' 
                        || rbac.rbacDetails.crrUnpaidSoftStop == 'Y')){
                        dispatch(clearUnpaidData())
                        if(await dispatch(getUnpaidInvoice(
                          {blid: AESDecrypt(blNum)??demurrageForm.blId??blidDocHistory, country: blCountry}
                        )).unwrap().then(
                          ({unpaid, attachment}:any)=>{
                            if((unpaid && unpaid.length > 0)||
                          (attachment && attachment.length > 0)
                          ){
                              return true
                            }
                          }
                        )){
                          navigate(`${location.pathname.split('/')[0]}/bl/${encodeURIComponent(
                            blNum || ''
                          )}/unpaid`)
                          return
                        }
                      }
                      setDisableFields(false);
                      setIsEdit(true);
                    }}>
                    <FontAwesomeIcon icon={faEdit} className='me-2' />
                    {t('resource_1:edit')}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ReleaseInstruction;
