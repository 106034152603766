/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useResizeColumns,
  useBlockLayout,
  useRowState,
} from "react-table";
import { useDispatch } from "react-redux";
import {
  updateSelectedAmount,
  updateSelectedAmountPayment,
  updateSelectedpaymentMultiPayTable,
  updateSelectedRowsPayment,
} from "../../../app/features/multiBlPayment/slices/multiBlPaymentSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import {
  addSelectionToTableData,
  removeSelectionfromInstTable,
  removeSelectionfromTable,
  updateSelectedInstRow,
  updateSelectedTableData,
} from "../../../app/features/dashboard/slices/dashboardSlice";
import { updateSelectedBlListMultiPay } from "../../../app/features/multiBlPayment/slices/multiBlPaymentSlice";
import { useBlDashName } from "../../../hooks/useDashName";
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (rest.checked) return;
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        className="form-check fs-0 mb-0 d-flex align-items-center"
      >
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);
/* const IndeterminateRadioButton = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    return (
      <Form.Check.Input {...rest} type='radio'/>
    );
  }
); */
const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  perPage = 20,
  onCustomSelectChange,
  onSelectRow,
  selectionInitialState,
  disableRowSelection,
  selectionType,
  staticHiddenCols,
  uniqueFilterKey,
  selectedRows,
  isChecked,
  isAllChecked,
}) => {
  const dispatch = useDispatch();
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 40,
      width: 120,
      maxWidth: 40,
    }),
    []
  );

  const [objectArray, setObjectArray] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [selectedAmt, setSelectedAmt] = useState(0);
  const filterState = useSelector((state) => state.payment.data);
  const selectedAmount = useSelector(
    (state) => state.multiBlPayment.selectedAmountAll
  );

  const selectedAmountPayment = useSelector(
    (state) => state.multiBlPayment.selectedAmountPaymentAll
  );
  const selectedBls = useSelector(
    (state) => state.multiBlPayment.selectedRowsMultiPay
  );
  const invoiceDashboardData = useSelector(
    (state) => state.dashboard.invoiceDashboardList
  );

  const paymentDashboardList = useSelector(
    (state) => state.dashboard.paymentDashboardList
  );
  const selecteCredData = useSelector(
    (state) => state.creditBalance.selectedCreditData
  );
  const currentGridName = useSelector(
    (state) => state.dashboard.currentGridName
  );
  const pmtNumberdata = useSelector(
    (state) => state.paymentBlPayment.pmtNumberdata
  );
  const blDashboardName = useBlDashName();

  const editPayment = useSelector((state) => state.blPayment.editPayment);
  const amountDetails = useSelector((state) => state.blPayment.amountDetails);

  const handleClick = (row) => {
    setObjectArray((prevArray) => {
      console.log("prevArray", prevArray);
      const newObjectArray = [
        ...prevArray,
        {
          id: row.id,
          amount: row.original.amount,
          localamount: row.original.localamount,
          invID: row.invID,
          invcurr: row.invcurr,
          localccy: row.localccy,
          invtype: row.invtype,
          collector_code: row.collector_code,
        },
      ];

      const stringIdCount = {};
      const filteredArray = [];
      const filteredArrayPayment = [];
      const seen = {};
      newObjectArray?.forEach((item) => {
        const key = `${item.id}-${item.amount}`;
        stringIdCount[key] = (stringIdCount[key] || 0) + 1;
      });

      newObjectArray.forEach((item) => {
        const key = `${item.id}-${item.amount}`;
        if (!seen[key]) {
          seen[key] = true;
          filteredArray.push(item);
        }
      });

      if (uniqueFilterKey === "paymentOne") {
        //debugger
        dispatch(updateSelectedAmount(filteredArray));
      }

      return filteredArray;
    });
  };
  const handleClickForPaymentTwo = (row) => {
    setObjectArray((prevArray) => {
      console.log("prevArray", prevArray);

      const newObjectArray = [
        ...prevArray,
        {
          pmtreference: row.pmtreference, // Unique identifier for payment
          amount: row.pmt_amt, // Payment amount
          localamount: row.localamount || null, // Assuming this exists or can be null
          currency: row.currency, // Payment currency
          localccy: row.localccy || null, // Local currency if applicable
          collector_code: row.collector_code, // Collector code
          paymentMode: row.paymentMode || null, // Payment mode if applicable
          pmtmode: row.pmtmode || null,
          pmtstatus: row.pmtstatus, // Status of payment
          liner_id: row.liner_id || null,
          customername: row.customername || null,
        },
      ];

      // Filter duplicates by `id` and `amount`
      const stringIdCount = {};
      const filteredArray = [];
      const seen = {};

      newObjectArray.forEach((item) => {
        const key = `${item.pmtreference}-${item.amount}`;
        stringIdCount[key] = (stringIdCount[key] || 0) + 1;
      });

      newObjectArray.forEach((item) => {
        const key = `${item.pmtreference}-${item.amount}`;
        if (!seen[key]) {
          seen[key] = true;
          filteredArray.push(item);
        }
      });

      if (uniqueFilterKey === "paymentTwo") {
        /*   let updatedSelection = [];

        if (selectedAmountPayment.length === 0) {
          // If no rows are previously selected, just add the filtered rows
          updatedSelection = [...filteredArray];
        } else {
          // If there are previously selected rows, add only those with matching currencies
          const filteredRowsWithSameCurrency = filteredArray.filter((newRow) =>
            selectedAmountPayment.some(
              (selectedRow) => selectedRow.currency === newRow.currency
            )
          );
          updatedSelection = [
            ...selectedAmountPayment,
            ...filteredRowsWithSameCurrency,
          ];
        }
 */
        // Dispatch the update to Redux

        dispatch(updateSelectedAmountPayment(filteredArray));

        console.log("selectedamount", selectedAmountPayment, filteredArray);
      }

      return filteredArray;
    });
  };

  useEffect(() => {
    if (selectedAmount.length == 0) {
      setObjectArray([]);
      setselectedData([]);
    }
  }, [selectedAmount]);
  useEffect(() => {
    if (selectedAmountPayment.length == 0) {
      setObjectArray([]);
      setselectedData([]);
    }
  }, [selectedAmountPayment]);

  const [forceUpdate, setForceUpdate] = useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    rows,
    setGlobalFilter,
    hideSelectAll,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,

      data,
      disableSortBy: !sortable,
      initialState: pagination
        ? {
            pageSize: perPage,
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          }
        : {
            pageSize: Math.max(data?.length, perPage),
            selectedRowIds: selectionInitialState,
            hiddenColumns: staticHiddenCols,
          },

      defaultColumn: defaultColumn,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useBlockLayout,

    (hooks) => {
      const disAllowPaymentCondition = (i) =>
        i?.pmtstatus == "Yes" ||
        i?.pmtreference ||
        i?.invtypecode == "999" ||
        (selectedAmount?.[0] &&
          selectedAmount[0].collector_code != i?.collector_code) ||
        ((selectedAmount.length ?? 0) > 0 &&
          (selectedAmount[0]?.invcurr !== i?.invcurr ||
            (selectedAmount[0]?.id.substring(0, 2) == "CD") !==
              (i?.invno?.substring(0, 2) == "CD") ||
            isNaN(i?.localamt) !== isNaN(selectedAmount[0]?.localamount)));
      if (selection) {
        if (uniqueFilterKey === "paymentOne") {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  disabled={editPayment}
                  onClick={(e) => {
                    console.log(
                      "invoiceDashboardData.result",
                      invoiceDashboardData.result
                    );
                    if (e.target.checked) {
                      onSelectRow({
                        action: "add",
                        data: rows.map((r) => r.original),
                      });
                    } else {
                      onSelectRow({
                        action: "remove",
                        data: rows.map((r) => r.original),
                      });
                    }
                    if (e.target.checked) {
                      if (uniqueFilterKey === "paymentOne") {
                        console.log("invoiceselect", selectedAmount);
                        if (selectedAmount.length > 0) {
                          const filteredArray1 =
                            invoiceDashboardData.result?.filter(
                              (item1) =>
                                selectedAmount.every(
                                  (item2) => item2.id !== item1.invno
                                ) &&
                                (selectedAmount[0].id.substring(0, 2) ==
                                  "CD") ==
                                  (item1.invno.substring(0, 2) == "CD") &&
                                selectedAmount[0].localccy == item1.localccy &&
                                item1.collector_code ==
                                  selectedAmount[0].collector_code
                            );

                          filteredArray1?.forEach((item) => {
                            item.pmtstatus !== "Yes" &&
                              (!item.pmtreference ||
                                item.pmtreference.length === 0) &&
                              item.invoice_cat != "FINAL" &&
                              handleClick({
                                invcurr: item.invcurr,
                                localccy: item.localccy,
                                invID: item.invid,
                                invtype: item.invtype,
                                id: item.invno,
                                collector_code: item.collector_code,
                                original: {
                                  amount:
                                    item.pmtstatus == "Yes"
                                      ? "No"
                                      : item.invamt,
                                  localamount:
                                    item.pmtstatus == "Yes"
                                      ? "No"
                                      : item.localamt,
                                },
                              });
                          });
                        } else {
                          invoiceDashboardData.result
                            ?.filter(
                              (item1) =>
                                (invoiceDashboardData.result?.[0].invno.substring(
                                  0,
                                  2
                                ) ==
                                  "CD") ==
                                  (item1.invno.substring(0, 2) == "CD") &&
                                invoiceDashboardData.result?.[0].localccy ==
                                  item1.localccy &&
                                invoiceDashboardData.result?.[0]
                                  .collector_code == item1.collector_code
                            )
                            .forEach((item) => {
                              item.pmtstatus !== "Yes" &&
                                (!item.pmtreference ||
                                  item.pmtreference.length === 0) &&
                                handleClick({
                                  invcurr: item?.invcurr,
                                  localccy: item.localccy,
                                  invID: item.invid,
                                  invtype: item.invtype,
                                  id: item.invno,
                                  collector_code: item.collector_code,
                                  original: {
                                    amount:
                                      item.pmtstatus == "Yes"
                                        ? "No"
                                        : item.invamt,
                                    localamount:
                                      item.pmtstatus == "Yes"
                                        ? "No"
                                        : item.localamt,
                                  },
                                });
                            });
                        }
                      }
                    } else {
                      dispatch(updateSelectedAmount([]));
                      setObjectArray([]);
                      setselectedData([]);
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                  indeterminate={selectedAmount?.find((x) =>
                    invoiceDashboardData?.result?.find(
                      (y) => y.invid == x.invID
                    )
                  )}
                  checked={
                    selectedAmount?.[0] &&
                    !invoiceDashboardData?.result
                      ?.filter(
                        (item) =>
                          selectedAmount[0].collector_code ===
                            item.collector_code && item.invoice_cat != "FINAL"
                      )
                      .find(
                        (i) =>
                          !selectedAmount.map((x) => x.id).includes(i.invno) &&
                          !disAllowPaymentCondition(i)
                      )
                  }
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                if (disAllowPaymentCondition(row?.original)) {
                  return (
                    <IndeterminateCheckbox
                      disabled={editPayment}
                      checked={false}
                      onClick={(event) => {
                        console.log(
                          "invoiceDashboardData.result124",
                          invoiceDashboardData.result
                        );
                        if (uniqueFilterKey === "paymentOne") {
                          if (row?.original?.pmtstatus == "Yes") {
                            toast.error(
                              t("resource_message:invoiceAlreadyPaid")
                            );
                          } else if (row?.original?.invtypecode == "999") {
                            toast.error(t("Can not select Final Invoice"));
                          } else if (row?.original?.pmtreference) {
                            toast.error(
                              t("resource_message:paymentAlreadyInitiated") +
                                row?.original?.pmtreference
                            );
                          } else if (
                            selectedAmount[0].invcurr !== row?.original?.invcurr
                          ) {
                            toast.error(t("resource_message:multipleCurrency"));
                          } else if (
                            (selectedAmount[0].id.substring(0, 2) == "CD") !==
                            (row?.original?.invno.substring(0, 2) == "CD")
                          ) {
                            toast.error(
                              t(
                                "Container Deposite Invoices should be submitted seperately"
                              )
                            );
                          } else if (
                            isNaN(row?.original?.localamt) !==
                            isNaN(selectedAmount[0].localamount)
                          ) {
                            toast.error(
                              t("Can not select multiple local currency")
                            );
                          } else if (
                            selectedAmount?.[0] &&
                            selectedAmount[0].collector_code !=
                              row?.original?.collector_code
                          ) {
                            toast.error(
                              t("resource_message:sameCountryValidation")
                            );
                          }
                        }
                      }}
                    />
                  );
                } else {
                  return (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      checked={
                        !!selectedAmount.find(
                          (v) => v.id == row?.original?.invno
                        )
                      }
                      onClick={(event) => {
                        console.log(
                          "invoiceDashboardData.resultinside",
                          invoiceDashboardData.result
                        );
                        if (uniqueFilterKey === "paymentOne") {
                          if (row?.original?.pmtstatus == "Yes") {
                            event.target.checked = false;
                            toast.error(
                              t("resource_message:invoiceAlreadyPaid")
                            );
                          } else if (row?.original?.pmtreference) {
                            event.target.checked = false;
                            toast.error(
                              t("resource_message:paymentAlreadyInitiated") +
                                row?.original?.pmtreference
                            );
                          } else {
                            if (event.target.checked) {
                              onSelectRow({
                                action: "add",
                                data: row.original,
                              });
                            } else {
                              onSelectRow({
                                action: "remove",
                                data: row.original,
                              });
                            }
                          }

                          if (row.original.unAllocatedAmount) {
                            if (event.target.checked) {
                              const selectedCreditAmount = selecteCredData
                                .map((i) => Number(i.unAllocatedAmount))
                                .reduce((partialSum, a) => partialSum + a, 0);

                              if (
                                selectedCreditAmount > 0 &&
                                (amountDetails > 0) &
                                  (selectedCreditAmount > amountDetails)
                              ) {
                                event.target.checked = false;
                                toast.error(
                                  "Selected Invoice amount is greater than the Credit Balance"
                                );
                              }
                            } else {
                              onSelectRow({
                                action: "remove",
                                data: row.original,
                              });
                            }
                          }

                          if (row?.original?.pmtstatus !== "Yes") {
                            if (event.target.checked) {
                              setselectedData((prevArray) => {
                                const newObjectArray = [
                                  ...prevArray,
                                  row?.original,
                                ];

                                dispatch(
                                  updateSelectedBlListMultiPay(newObjectArray)
                                );
                                return newObjectArray;
                              });
                              //  dispatch(updateSelectedBlListMultiPay((item) => [...item, row?.original]));
                              handleClick({
                                invcurr: row?.original?.invcurr,
                                localccy: row?.original?.localccy,
                                invID: row.original.invid,
                                id: row.original.invno,
                                invtype: row.original.invtype,
                                collector_code: row.original.collector_code,
                                original: {
                                  amount:
                                    row.original.pmtstatus == "Yes"
                                      ? "No"
                                      : row.original.invamt,
                                  localamount:
                                    row.original.pmtstatus == "Yes"
                                      ? "No"
                                      : row.original.localamt,
                                },
                              });
                            } else {
                              const filteredData = selectedAmount.filter(
                                (item) =>
                                  item.id != row.original.invno.toString()
                              );
                              dispatch(updateSelectedAmount(filteredData));
                              setObjectArray(filteredData);
                              const filteredData2 = selectedBls.filter(
                                (item) =>
                                  item.invno != row.original.invno.toString()
                              );

                              dispatch(
                                updateSelectedBlListMultiPay(filteredData2)
                              );
                              setselectedData(filteredData2);
                            }
                          }
                        }
                      }}
                      disabled={
                        editPayment ||
                        (disableRowSelection?.accessor &&
                          disableRowSelection?.value &&
                          row.values[disableRowSelection.accessor] ===
                            disableRowSelection.value)
                      }
                    />
                  );
                }
              },
            },
            ...columns,
          ]);
        } else if (uniqueFilterKey === "paymentTwo") {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  disabled={editPayment}
                  onClick={(e) => {
                    // If the "Select All" checkbox is checked
                    if (e.target.checked) {
                      if (selectedAmountPayment.length > 0) {
                        const filteredRows =
                          paymentDashboardList.result?.filter(
                            (item) =>
                              selectedAmountPayment.every(
                                (selected) =>
                                  selected.pmtreference !== item.pmtreference
                              ) &&
                              selectedAmountPayment[0].currency ===
                                item.currency &&
                              selectedAmountPayment[0].collector_code ===
                                item.collector_code &&
                              item.pmtstatus?.toUpperCase() === "SUBMITTED" &&
                              selectedAmountPayment[0].liner_id ===
                                item.liner_id &&
                              selectedAmountPayment[0].customername ===
                                item.customername
                          );

                        const mergedRows = [
                          ...selectedAmountPayment,
                          ...filteredRows.filter(
                            (item) =>
                              !selectedAmountPayment.some(
                                (selected) =>
                                  selected.pmtreference === item.pmtreference
                              )
                          ),
                        ];

                        mergedRows.forEach((item) => {
                          handleClickForPaymentTwo({
                            pmtreference: item.pmtreference,
                            currency: item.currency,
                            pmt_amt: item.pmt_amt,
                            pmtmode: item.pmtmode,
                            pmtstatus: item.pmtstatus,
                            collector_code: item.collector_code,
                            liner_id: item.liner_id,
                            customername: item.customername,
                            original: {
                              amount: item.pmt_amt,
                              paymentMode: item.paymentmode,
                            },
                          });
                        });

                        onSelectRow({
                          action: "add",
                          data: mergedRows,
                        });
                      } else {
                        const filteredRows =
                          paymentDashboardList.result?.filter(
                            (item) =>
                              paymentDashboardList.result?.[0].currency ===
                                item.currency &&
                              paymentDashboardList.result?.[0]
                                .collector_code === item.collector_code &&
                              paymentDashboardList.result?.[0].liner_id ===
                                item.liner_id &&
                              paymentDashboardList.result?.[0].customername ===
                                item.customername &&
                              item.pmtstatus?.toUpperCase() === "SUBMITTED"
                          );

                        filteredRows?.forEach((item) => {
                          handleClickForPaymentTwo({
                            pmtreference: item.pmtreference,
                            currency: item.currency,
                            pmt_amt: item.pmt_amt,
                            pmtmode: item.pmtmode,
                            pmtstatus: item.pmtstatus,
                            collector_code: item.collector_code,
                            liner_id: item.liner_id,
                            customername: item.customername,
                            original: {
                              amount: item.pmt_amt,
                              paymentMode: item.paymentmode,
                            },
                          });
                        });

                        onSelectRow({
                          action: "add",
                          data: filteredRows,
                        });
                      }
                    } else {
                      // Reset selections when unchecked
                      dispatch(updateSelectedAmountPayment([]));
                      dispatch(updateSelectedRowsPayment([]));
                      setObjectArray([]);
                      setselectedData([]);
                      onSelectRow({
                        action: "remove",
                        data: rows.map((r) => r.original),
                      });
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                  indeterminate={
                    !!selectedAmountPayment?.find((x) =>
                      paymentDashboardList?.result?.find(
                        (y) => y.pmtreference === x.pmtreference
                      )
                    )
                  }
                  checked={
                    selectedAmountPayment?.[0] &&
                    !paymentDashboardList?.result
                      ?.filter(
                        (item) =>
                          selectedAmountPayment[0].collector_code ===
                            item.collector_code &&
                          selectedAmountPayment[0].currency === item.currency &&
                          item.pmtstatus?.toUpperCase() === "SUBMITTED" &&
                          selectedAmountPayment[0].liner_id === item.liner_id &&
                          selectedAmountPayment[0].customername ===
                            item.customername
                      )
                      .find(
                        (i) =>
                          !selectedAmountPayment
                            .map((x) => x.pmtreference)
                            .includes(i.pmtreference)
                      )
                  }
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                return (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    checked={
                      !!selectedAmountPayment.find(
                        (v) => v.pmtreference === row?.original?.pmtreference
                      ) && row.original.pmtstatus?.toUpperCase() === "SUBMITTED"
                    }
                    onClick={(event) => {
                      if (uniqueFilterKey === "paymentTwo") {
                        if (event.target.checked) {
                          if (row?.original.pmtstatus !== "Submitted") {
                            event.target.checked = false;
                            toast.error(
                              "This payment is not in submitted status."
                            );
                          } else if (
                            selectedAmountPayment.length > 0 &&
                            selectedAmountPayment[0]?.currency !==
                              row?.original?.currency
                          ) {
                            event.target.checked = false;
                            toast.error(t("resource_message:multipleCurrency"));
                          } else if (
                            selectedAmountPayment.length > 0 &&
                            selectedAmountPayment[0]?.customername !==
                              row?.original?.customername
                          ) {
                            event.target.checked = false;
                            toast.error("Different Users are not allowed");
                          } else if (
                            selectedAmountPayment.length > 0 &&
                            selectedAmountPayment[0]?.liner_id !==
                              row?.original?.liner_id
                          ) {
                            event.target.checked = false;
                            toast.error("Different Liners are not allowed");
                          } else {
                            onSelectRow({
                              action: "add",
                              data: row.original,
                            });
                            setselectedData((prevArray) => {
                              const newObjectArray = [
                                ...prevArray,
                                row.original,
                              ];
                              dispatch(
                                updateSelectedAmountPayment(newObjectArray)
                              );
                              return newObjectArray;
                            });
                          }
                        } else {
                          const filteredData = selectedAmountPayment.filter(
                            (item) =>
                              item.pmtreference !==
                              row.original.pmtreference.toString()
                          );
                          dispatch(updateSelectedAmountPayment(filteredData));
                          setObjectArray(filteredData);
                          setselectedData(filteredData);
                          onSelectRow({
                            action: "remove",
                            data: row.original,
                          });
                        }
                      }
                    }}
                    disabled={editPayment}
                  />
                );
              },
            },
            ...columns,
          ]);
        } else if (uniqueFilterKey === "instDashboard") {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  onClick={(e) => {
                    if (e.target.checked) {
                      if (
                        rows.filter((v) => v.original.status == "Submitted")
                          .length > 0
                      ) {
                        dispatch(
                          addSelectionToTableData(
                            rows
                              .filter((v) => v.original.status == "Submitted")
                              .map((v) => {
                                return {
                                  ...v.original,
                                  rowId: v.id,
                                };
                              })
                          )
                        );
                      }
                    } else {
                      dispatch(
                        removeSelectionfromInstTable(
                          rows.map((v) => {
                            return {
                              ...v.original,
                              rowId: v.id,
                            };
                          })
                        )
                      );
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                if (row.original.status == "Submitted") {
                  return (
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      onClick={(event) => {
                        const newSelectedData =
                          selectedRows.length > 0
                            ? [
                                {
                                  ...row.original,
                                  rowId: row.id,
                                },
                              ].concat(selectedRows)
                            : [
                                {
                                  ...row.original,
                                  rowId: row.id,
                                },
                              ];
                        if (event.target.checked) {
                          if (row.original.status == "Submitted") {
                            dispatch(updateSelectedTableData(newSelectedData));
                          } else {
                            toast.dark(
                              "Only submitted Instruction can be assigned."
                            );
                          }
                        } else {
                          dispatch(
                            updateSelectedTableData(
                              selectedRows.filter(
                                (v) =>
                                  v?.bl_event_id &&
                                  v?.bl_event_id !== row.original.bl_event_id
                              )
                            )
                          );
                        }
                      }}
                      disabled={
                        disableRowSelection?.accessor &&
                        disableRowSelection?.value &&
                        row.values[disableRowSelection.accessor] ===
                          disableRowSelection.value
                      }
                    />
                  );
                } else {
                  return (
                    <IndeterminateCheckbox
                      checked={false}
                      onClick={(event) => {
                        toast.error(
                          "Only submitted Instruction can be assigned."
                        );
                      }}
                      disabled={
                        disableRowSelection?.accessor &&
                        disableRowSelection?.value &&
                        row.values[disableRowSelection.accessor] ===
                          disableRowSelection.value
                      }
                    />
                  );
                }
              },
            },
            ...columns,
          ]);
        } else if (uniqueFilterKey == "mainDashboard") {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox
                  // disabled
                  onClick={(e) => {
                    if (e.target.checked) {
                      dispatch(
                        addSelectionToTableData(
                          rows.map((v) => {
                            return {
                              ...v.original,
                              rowId: v.id,
                            };
                          })
                        )
                      );
                    } else {
                      dispatch(
                        removeSelectionfromTable(
                          rows.map((v) => {
                            return {
                              ...v.original,
                              rowId: v.id,
                            };
                          })
                        )
                      );
                    }
                  }}
                  {...getToggleAllRowsSelectedProps()}
                />
              ),
              headerProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              cellProps: {
                style: {
                  maxWidth: selectionColumnWidth,
                },
              },
              Cell: ({ row }) => {
                return (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    onClick={(event) => {
                      const newSelectedData =
                        selectedRows.length > 0
                          ? [
                              {
                                ...row.original,
                                rowId: row.id,
                              },
                            ].concat(selectedRows)
                          : [
                              {
                                ...row.original,
                                rowId: row.id,
                              },
                            ];
                      if (event.target.checked) {
                        dispatch(updateSelectedTableData(newSelectedData));
                      } else {
                        dispatch(
                          updateSelectedTableData(
                            selectedRows.filter(
                              (v) => v?.blid && v?.blid !== row.original.blid
                            )
                          )
                        );
                      }
                    }}
                    disabled={
                      disableRowSelection?.accessor &&
                      disableRowSelection?.value &&
                      row.values[disableRowSelection.accessor] ===
                        disableRowSelection.value
                    }
                  />
                );
              },
            },
            ...columns,
          ]);
        } else if (selectionType == "checkbox") {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: (row) => (
                <IndeterminateCheckbox
                  checked={isAllChecked?.(row.data) == "Y"}
                  indeterminate={isAllChecked?.(row.data) == "S"}
                  onClick={() => {
                    if (isAllChecked?.(row?.data) == "Y") {
                      onSelectRow({ action: "removeAll", rows: row?.data });
                    } else {
                      onSelectRow({ action: "addAll", rows: row?.data });
                    }
                  }}
                ></IndeterminateCheckbox>
              ),
              Cell: ({ row }) => (
                <IndeterminateCheckbox
                  checked={isChecked?.(row?.original) == "Y"}
                  onClick={() => {
                    if (isChecked?.(row?.original) == "Y") {
                      onSelectRow({ action: "remove", row: row?.original });
                    } else {
                      onSelectRow({ action: "add", row: row?.original });
                    }
                  }}
                ></IndeterminateCheckbox>
              ),
            },
            ...columns,
          ]);
        }
        /* else{
          hooks.visibleColumns.push((columns) => [
            {
              id:"radioSelection",
              Header:()=>{},
              Cell:({row})=><IndeterminateRadioButton
              name="rdio"
              onClick={(e)=>{
                
                onSelectRow({row:row?.original})
              }}
              
              ></IndeterminateRadioButton>
            },
            ...columns
          ]);
        } */
      }
    }
  );
  useEffect(() => {
    if (uniqueFilterKey == "paymentinvoice")
      if (
        window.location.pathname.includes("blsliderpayment") &&
        columns.length == 2 &&
        pmtNumberdata.length > 0
      ) {
        // setPageSize(pmtNumberdata.length)
        toggleAllRowsSelected(true);
      }
    // rows.forEach(row => {
    //   row.isSelected = true;
    // });
  }, [pmtNumberdata]);

  useEffect(() => {
    if (rows[0]?.original?.pmtReference) {
      const data = rows
        .filter((i) => Object.keys(selectedRowIds).includes(i.id))
        .map((item) => ({
          ...item.values,
          paymentFundingId: item.original.paymentFundingId,
          pmtAmount: item.original.pmtAmount,
          allocatedAmount: item.original.allocatedAmount,
        }));

      onCustomSelectChange({
        list: data,
        rows: selectedRowIds,
      });
    } else {
      if (
        window.location.pathname.includes("blsliderpayment") ||
        (currentGridName == blDashboardName &&
          window.location.pathname.includes("payment"))
      ) {
        const data = rows
          .filter((i) => Object.keys(selectedRowIds).includes(i.id))
          .map((item) => ({
            ...item.values,
            invid: item.original.invid
              ? item.original.invid
              : item.original.invId,
          }));

        onCustomSelectChange({
          list: data,
          rows: selectedRowIds,
        });
      } else {
        const data = rows
          .filter((i) => Object.keys(selectedRowIds).includes(i.id))
          .map((item) => ({
            ...item.values,
            id: item.original.id,
          }));

        onCustomSelectChange({
          list: data,
          rows: selectedRowIds,
        });
      }
    }
  }, [selectedRowIds]);

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (child?.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children),
        });
      } else {
        if (child?.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            getTableBodyProps,
            headers,
            headerGroups,
            page,
            rows,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter,
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
