import {
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faLocation,
  faPause,
  faPlus,
  faPlusCircle,
  faRedo,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Card, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  matchPath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppDispatch, RootState } from '../../../store';
import { updateBlDetailsRowColumns } from '../../blDetails/slices/blDetailsTable';
import { toggleDocumentHistoryColumnShort } from '../../blDetails/slices/documentHistorySlice';
import FileUpload from '../../blPayment/FileUpload';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  fetchCompanyDetailsForAgentDelegation,
  getInstructionList,
  getInvoiceChange,
  getInvoiceChangeTypes,
  getReleaseTypeList,
  getReturnTypeList,
} from '../../dashboard/actions/instruction';
import {
  addInvoiceChange,
  approveRejectInvoiceChange,
} from './actions/Instruction';
import { toast } from 'react-toastify';
import { useAsyncDebounce } from 'react-table';
import { fetchAddressSuggestions2 } from '../../dashboard/actions/instruction';
import { fetchCompanyDetailsForVerification } from '../../registration/actions/Registration';
import { updateSelectedAddressResult } from '../../blRequestInstruction/components/instructionSlice/CarrierHaulageSlice';
import InputMask from 'react-input-mask';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { AESDecrypt } from '../../../../encrypt-util';
import { response } from 'msw';
import { updateSelectedDocIdDH } from '../../blDetails/slices/documentHistorySlice';
import { release } from 'os';
import i18next from 'i18next';
import { commonApproveReject } from '../../dashboard/actions/instruction';
import {
  updateDashbaordRefresh,
  updateSortStateDataTable,
} from '../../../common/components/DataTable/slices/DataTable';

import { useBackNavigation } from '../../../../hooks/BackNavigation';
import useDataCountry from '../../../../hooks/useDataCountry';
import SnoozedToButton from '../../instructionDashboard/components/SnoozedTo';
import { useLoading } from '../../../../middleware/loadingMiddleware';
import { getCurrencyDDList } from '../../blPayment/actions/payments';
import { resetInvoiceTable, updateInvoice, updateInvoiceCurrency } from './instructionSlice/InvoiceChangeSlice';
import useBlCountry from '../../../../hooks/useBlCountry';


const InvoiceChanges = () => {
  const { t, i18n } = useTranslation();
  const data = useSelector(
    (state: RootState) => state.invoiceChange.invoiceData
  );
  const rbac: any = useSelector((state: RootState) => state.userProfile);
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId)
  const [placeId, setPlaceId] = useState('');
  const registrationdata = useSelector(
    (state: RootState) => state.registration
  );
  /*   const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  //all instruction List
  const instructionList = useSelector(
    (state: RootState) => state.documentHistory.data
  );

  const location = useLocation();
  const { blNum, encryptdoc } = useParams();
  const docId = AESDecrypt(encryptdoc);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const returnTypeList = useSelector(
    (state: RootState) => state.invoiceChange.returnTypeList
  );

  const releaseTypeList = useSelector(
    (state: RootState) => state.invoiceChange.releaseTypeList
  );

  const blEventId_docInsDashboard: any = useSelector(
    (state: any) => state.dashboard.instructionDashboardList.result.filter((Item: any) => Item.bl_id == AESDecrypt(blNum))[0]?.bl_event_id
  );

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const disabledPath = [
    { path: `${APP_PATH_PREFIX}/bl/:blNum/802/:eventId` },
    { path: `${APP_PATH_PREFIX}/instruction/bl/:blNum/802/:eventId` },
    { path: `${APP_PATH_PREFIX}/bl/:blNum/invoice-change/:eventId` },
    { path: `${APP_PATH_PREFIX}/instruction/:blNum/802/:blId` },
    {
      path: `${APP_PATH_PREFIX}/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[3] !== 'instruction',
    },
    {
      path: `${APP_PATH_PREFIX}/instruction/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    {
      path: `${APP_PATH_PREFIX}/payment/bl/:blNum/:encrypt/:encryptdoc`,
      condition: location.pathname.split('/')[4] !== 'instruction',
    },
    { path: `${APP_PATH_PREFIX}/instruction/:blNum/:encrypt/:encryptdoc` },
  ].some(({ path, condition }: { path: string; condition?: boolean }) => {
    return (
      (condition ?? true) &&
      matchPath({ path, caseSensitive: true, end: true }, location.pathname)
    );
  });
  const currencyList = useSelector((state: RootState) => state.standaloneInvoice.countryDDList)

  const dataCountry = useDataCountry()

  const disableFields =
    profileDetails?.partnerType === 'liner' ||
    (!!disabledPath && profileDetails?.partnerType !== 'liner');

  const [blCountry, setBlCountry] = useBlCountry(
    disabledPath ? "view" : "new",
    "import",
    802
  );
   const language = useSelector((state:RootState)=> state.navbarTop.language);

  useEffect(() => {
    if(!blCountry || !linerId) return
    dispatch(
      getReturnTypeList({
        dataCountry: blCountry,
        linerId: linerId
      })
    );
    dispatch(
      getReleaseTypeList({
        partnerCountry: blCountry,
        linerId: linerId
      })
    )
    dispatch(
      getInvoiceChangeTypes({ partnerCountry: blCountry, linerId: linerId })
    )

    if ((!currencyList || currencyList.length < 1)
      && !disableFields
    ) dispatch(getCurrencyDDList()).unwrap().then((data: any) => {
      if (['KE', 'TZ'].includes(blCountry)) dispatch(updateInvoiceCurrency(data[0].currencyCode))
    });

    i18n.changeLanguage(
      `${blCountry}_${language}`
    );
  }, [blCountry, linerId,language]);

  const [taxId, setTaxId] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [masterMstPartnerId, setMasterMstPartnerId] = useState(null);
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [taxIdFormat, setTaxIdFormat] = useState('');
  const [byteArr, setByteArr] = useState('');
  const [loiUpload, setLoiUpload] = useState('');
  const [allowFrieghtInvoice, setAllowFrieghtInvoice] =
    useState<boolean>(false);
  const [allowLocalInvoice, setAllowLocalInvoice] = useState<boolean>(true);
  const [allowTimePendingInvoice, setAllowTimePendingInvoice] =
    useState<boolean>(false);
  const [poReference, setPoReference] = useState('');
  const [returnType, setReturnType] = useState(returnTypeList[0]?.value);
  const [status, setStatus] = useState('');

  const documentHistorydData = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const rowColumns = useSelector(
    (state: RootState) => state.blDetailsTable.rowColumns
  );

  const invoiceList = useSelector((state: RootState) => state.invoiceChange.invoiceList)

  const selectedInvoiceChange = useSelector(
    (state: RootState) => state.invoiceChange.invoiceData
  );
  const [matchCode, setMatchCode] = useState('');
  const [showHideMatchCode, setShowHideMatchCode] = useState(true);
  const [customerCode, setCustomerCode] = useState('');
  const [showHideCustomerCode, setShowHideCustomerCode] = useState(true);
  const selectedRouter = useSelector(
    (state: RootState) => state.documentHistory.selectedRouter
  );

  useEffect(() => {
    if (!(location.pathname.split('/')[3] == 'instruction') && docId && invoiceList.length > 0 && linerId) {
      dispatch(
        getInvoiceChange({
          docId: docId,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails.partnerId
              : linerId,
        })
      )
        .unwrap()
        .then((response: any) => {
          if (docId !== '') {
            setTaxId(response?.taxId || '');
            setValue('taxId', response?.taxId || '');
            setAddress(response?.address || '');
            setValue('address', response?.address || '');
            setEmail(response?.email || '');
            setValue('email', response?.email || '');
            setContactNo(response?.contactNo || '');
            setValue('contactNo', response?.contactNo || '');
            setCompanyName(response?.requestedpartnernm || '');
            setValue('companyName', response?.requestedpartnernm || '');
            setAllowFrieghtInvoice(response?.freightCharges);
            setValue('allowFrieghtInvoice', response?.freightCharges);
            setAllowLocalInvoice(response?.invoiceCharges);
            setValue('allowLocalInvoice', response?.invoiceCharges);
            setAllowTimePendingInvoice(response?.timePendingCharges);
            setValue('allowTimePendingInvoice', response?.timePendingCharges);
            setRemarks(response?.remarks);
            setValue('remarks', response?.remarks);
            setPoReference(response?.poReference);
            setValue('poReference', response?.poReference);
            setReturnType(response?.returnType);
            setValue('returnType', response?.returnType);
            setValue('releaseType', response?.releaseType);
            setStatus(response?.status);
            setValue('status', response?.status);
            setCustomerCode(response?.customerCode);
            setValue('customerCode', response?.customerCode || '');
            setValue('userRemarks', response?.userRemarks || '');
            setBlCountry(response?.country)
            // if (
            //   !instructionList
            //     .filter((v: any) => v?.docId === parseInt(docId))
            //     .map(
            //       (u: any) => u?.event === 'Completed' || u?.event === 'Rejected'
            //     )
            //     .includes(true)
            // ) {
            //   setMatchCode(
            //     selectedInvoiceChange?.matchCodeList?.length === 1
            //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
            //       : ''
            //   );
            //   setValue(
            //     'matchCode',
            //     selectedInvoiceChange?.matchCodeList?.length === 1
            //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
            //       : ''
            //   );
            //   setCustomerCode(
            //     selectedInvoiceChange?.matchCodeList?.length === 1
            //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
            //       : ''
            //   );
            //   setValue(
            //     'customerCode',
            //     selectedInvoiceChange?.matchCodeList?.length === 1
            //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
            //       : ''
            //   );
            // } else {
            //   setMatchCode(selectedInvoiceChange?.matchCode);
            //   setValue('matchCode', selectedInvoiceChange?.matchCode || '');
            //   setCustomerCode(selectedInvoiceChange?.customerCode);
            //   setValue('customerCode', selectedInvoiceChange?.customerCode || '');
            // }
          }
        });
    }
  }, [docId, invoiceList, linerId]);

  /*  useEffect(() => {
  setInitialValue();
  }, []); */
  useEffect(() => {
    setTimeout(() => {
      setInitialValue();
    }, 0);
  }, [selectedInvoiceChange]);

  const setInitialValue = () => {
    if (docId && !location.pathname.includes('instruction')) {
      setTaxId(selectedInvoiceChange?.taxId || '');
      setValue('taxId', selectedInvoiceChange?.taxId || '');
      setAddress(selectedInvoiceChange?.address || '');
      setValue('address', selectedInvoiceChange?.address || '');
      setEmail(selectedInvoiceChange?.email || '');
      setValue('email', selectedInvoiceChange?.email || '');
      setContactNo(selectedInvoiceChange?.contactNo || '');
      setValue('contactNo', selectedInvoiceChange?.contactNo || '');
      setCompanyName(selectedInvoiceChange?.requestedpartnernm || '');
      setValue('companyName', selectedInvoiceChange?.requestedpartnernm || '');
      setAllowFrieghtInvoice(selectedInvoiceChange?.freightCharges);
      setValue('allowFrieghtInvoice', selectedInvoiceChange?.freightCharges);
      setAllowLocalInvoice(selectedInvoiceChange?.invoiceCharges);
      setAllowTimePendingInvoice(selectedInvoiceChange?.timePendingCharges);
      setRemarks(selectedInvoiceChange?.remarks);
      setValue('remarks', selectedInvoiceChange?.remarks);
      // setValue('allowLocalInvoice', selectedInvoiceChange?.invoiceCharges);
      setPoReference(selectedInvoiceChange?.poReference);
      setValue('poReference', selectedInvoiceChange?.poReference);
      setReturnType(selectedInvoiceChange?.returnType);
      setValue('returnType', selectedInvoiceChange?.returnType);
      setValue('releaseType', selectedInvoiceChange?.releaseType);
      setStatus(selectedInvoiceChange?.status);
      setValue('status', selectedInvoiceChange?.status);
      setCustomerCode(selectedInvoiceChange?.customerCode);
      setValue('customerCode', selectedInvoiceChange?.customerCode || '');
      setValue('userRemarks', selectedInvoiceChange?.userRemarks || '');
      //submitted
      // if (
      //   !instructionList
      //     .filter((v: any) => v?.docId === parseInt(docId))
      //     .map((u: any) => u?.event === 'Completed' || u?.event === 'Rejected')
      //     .includes(true)
      // ) {
      //   setMatchCode(
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
      //       : ''
      //   );
      //   setValue(
      //     'matchCode',
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
      //       : ''
      //   );
      //   setCustomerCode(
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
      //       : ''
      //   );
      //   setValue(
      //     'customerCode',
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
      //       : ''
      //   );
      // } else {
      //   //approve/rejected
      //   setMatchCode(selectedInvoiceChange?.matchCode);
      //   setValue('matchCode', selectedInvoiceChange?.matchCode || '');
      //   setCustomerCode(selectedInvoiceChange?.customerCode);
      //   setValue('customerCode', selectedInvoiceChange?.customerCode || '');
      //   if (
      //     selectedInvoiceChange?.matchCodeList &&
      //     (selectedInvoiceChange?.matchCodeList.length <= 0 ||
      //       (selectedInvoiceChange?.matchCodeList.length > 0 &&
      //         selectedInvoiceChange?.matchCodeList
      //           .map(
      //             (v: any) => v.internalCode === '' || v.internalCode === null
      //           )
      //           .includes(false)))
      //   ) {
      //     setShowHideMatchCode(false);
      //   } else {
      //     setShowHideMatchCode(true);
      //   }

      //   if (
      //     selectedInvoiceChange?.matchCodeList &&
      //     (selectedInvoiceChange?.matchCodeList.length <= 0 ||
      //       (selectedInvoiceChange?.matchCodeList.length > 0 &&
      //         selectedInvoiceChange?.matchCodeList
      //           .map((v: any) => v.mapCode === '' || v.mapCode === null)
      //           .includes(false)))
      //   ) {
      //     setShowHideCustomerCode(false);
      //   } else {
      //     setShowHideCustomerCode(true);
      //   }
      // }
    }
  };

  useEffect(() => {
    if (docId === '' || location.pathname.includes('instruction')) {
      setTaxId('');
      setValue('taxId', '');
      setCompanyName('');
      setValue('companyName', '');
      setAddress('');
      setValue('address', '');
      setEmail('');
      setValue('email', '');
      setContactNo('');
      setValue('contactNo', '');
      setRemarks('');
      setValue('remarks', '');
      setAllowFrieghtInvoice(false);
      setValue('allowFrieghtInvoice', false);
      setAllowLocalInvoice(true);
      setValue('allowLocalInvoice', true);
      setAllowTimePendingInvoice(false);
      setValue('allowTimePendingInvoice', false);
      setPoReference('');
      setValue('poReference', '');
      setValue('userRemarks', '');
      setReturnType(returnTypeList[0]?.value);
      setValue('returnType', returnTypeList[0]?.value);
      setValue('releaseType', releaseTypeList[0]?.value);
      setStatus('');
      setValue('status', '');
      setCustomerCode('');
      setValue('customerCode', '');
      // if (
      //   !instructionList
      //     .filter((v: any) => v?.docId === parseInt(docId))
      //     .map((u: any) => u?.event === 'Completed' || u?.event === 'Rejected')
      //     .includes(true)
      // ) {
      //   setMatchCode(
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
      //       : ''
      //   );
      //   setValue(
      //     'matchCode',
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.internalCode || ''
      //       : ''
      //   );
      //   setCustomerCode(
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
      //       : ''
      //   );
      //   setValue(
      //     'customerCode',
      //     selectedInvoiceChange?.matchCodeList?.length === 1
      //       ? selectedInvoiceChange?.matchCodeList[0]?.mapCode || ''
      //       : ''
      //   );
      // } else {
      //   setMatchCode(selectedInvoiceChange?.matchCode);
      //   setValue('matchCode', selectedInvoiceChange?.matchCode || '');
      //   setCustomerCode(selectedInvoiceChange?.customerCode);
      //   setValue('customerCode', selectedInvoiceChange?.customerCode || '');
      // }
    }
  }, [location.pathname]);

  const resetForm = () => {
    setTaxId('');
    setAddress('');
    setCompanyName('');
    setAddress('');
    setEmail('');
    setCustomerCode('')
    setContactNo('');
    setRemarks('');
    setAllowTimePendingInvoice(false);
    setPoReference('');
    setReturnType('')

    dispatch(resetInvoiceTable())
    setValue('userRemarks', '')
  };
  const _onCompanyNameChange = useAsyncDebounce((value: string) => {
    dispatch(fetchCompanyDetailsForVerification(value));
  }, 200);
  const BackNavigation = useBackNavigation();
  const _onCloseForm = () => {
    function extractValuesFromUrl(url: any) {
      const params = new URLSearchParams(url);
      const sortParam = params.get('sort');

      if (sortParam) {
        const arr: any = sortParam.split('%2C');
        return arr;
      } else {
        return [];
      }
    }
    dispatch(toggleDocumentHistoryColumnShort(false));
    setTimeout(() => {
      BackNavigation();
      if (window.location.search) {
        const paramVal = extractValuesFromUrl(window.location.search);
        const splitedval = paramVal[0].split(',');
        setTimeout(() => {
          dispatch(
            updateSortStateDataTable({
              key: splitedval[0],
              value: splitedval[1],
            })
          );
        }, 100);
      }
    }, 500);
  };

  const _onTaxIdChange = useAsyncDebounce((value: string) => {
    setTaxId(value);
  }, 200);


  type FormValues = {
    taxId: string;
    address: string;
    companyName: string;
    email: string;
    contactNo: string;
    allowFrieghtInvoice: boolean | null;
    allowLocalInvoice: boolean | null;
    remarks: string;
    allowTimePendingInvoice: boolean | null;
    poReference: string;
    returnType: string;
    releaseType: string;
    status: string;
    matchCode: string;
    customerCode: string;
    userRemarks: string;
  };

  const validationSchema = ['KE', 'TZ'].includes(blCountry) ? Yup.object().shape({
    returnType: Yup.string().required('Shipment/BL Type is required').notOneOf(['0'], 'Shipment/BL Type is required')
  })
    : Yup.object().shape({
      taxId: Yup.string()
        .required('Tax ID is required')
        .when('country', {
          is: 'USA',
          then: Yup.string().matches(
            /^\d\d-\d{7}$/,
            'Invalid input format. It should match the pattern "DD-#######"'
          ),
        }),
      address: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_1:address') })
      ),
      companyName: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:companyName') })
      ),
      /*   remarks: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:remarks') })
      ), */
      remarks:
        rbac.rbacDetails.invoiceChangeApproveReject == 'Y'
          ? Yup.string().required(
            t('resource_3:isRequired', { field: t('resource_2:remarks') })
          )
          : Yup.string(),
      // returnType: Yup.string().required(
      //   t('resource_3:isRequired', { field: t('resource_2:returnType') })
      // ),
      // releaseType: Yup.string().required(
      //   t('resource_3:isRequired', { field: t('resource_2:releaseType') })
      // ),
      email: Yup.string()
        .email(t('resource_3:isInvalid', { field: t('resource_1:email') }))
        .required(t('resource_3:isRequired', { field: t('resource_1:email') })),
      /*  contactNo: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:contactNo') })
      ), */
      customerCode: Yup.string().required(
        t('resource_3:isRequired', { field: t('resource_2:customerCode') })
      ),
    });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (payload) => {
    try {
      //if (placeId != '') {
      const partnerId = profileDetails.partnerId;
      payload.address = address;
      payload.remarks = remarks;
      dispatch(
        addInvoiceChange({
          ...payload,
          blId: parseInt(AESDecrypt(blNum)) || null,
          requestedPartnerId: profileDetails?.partnerId,
          requestedpartnernm: profileDetails?.partnerName,
          masterPartnerId: masterMstPartnerId,
          linerId: linerId,
          country: blCountry,
          invChangegAccessList: invoiceList.map((item) => ({
            type: item.value,
            allowAccess: item.allowAccess ? 'Y' : 'N',
            currency: item.currency
          })),
          // byteArr: byteArr,
          //  fileName: loiUpload,
          placeId: placeId,
        })
      ).unwrap().then(() => {
        setTimeout(() => {
          dispatch(
            getInstructionList({
              blNum: AESDecrypt(blNum),
              country: profileDetails.partnerType == "liner" ? dataCountry : blCountry
            })
          );
        }
          , 2000)
      });
      reset();
      BackNavigation()
      dispatch(updateDashbaordRefresh(true))
      // } else {
      //   toast.error('Please select a valid Address from the suggesstion');
      // }
    } catch (err: any) { }
  });

  const onApproveReject = (status: string, type: string) => {
    if (status && type) {
      // debugger;
      if (
        (!remarks || remarks === '' || remarks == null) &&
        status.toUpperCase().includes('R')
      ) {
        toast.dark(t('resource_message:remarksMandatory'));
      } else if (
        status === 'A' && !['KE', 'TZ'].includes(blCountry) &&
        (!customerCode || customerCode === null || customerCode.trim() === '')
      ) {
        toast.dark('Customer Code is Mandatory');
        return false;
      } else {
        dispatch(
          commonApproveReject({
            instructionId: docId ? parseInt(docId) || null : null,
            instructionType: type || null,
            country: blCountry,
            linerRemarks: remarks || null,
            customerRemarks: null,
            status: status || null,
            emailTo: data?.email || null,
            //matchCode: matchCode || null,
            customerCode: customerCode || null,
          })
        )
          .unwrap()
          .then((response: any) => {
            if (response?.responseCode === 'Rejected') {
              toast.dark(t('resource_message:rejectedSuccessfully'));
            } else if (response?.responseCode === 'Completed') {
              toast.dark(t('resource_message:approveSuccessfully'));
            }
            dispatch(updateDashbaordRefresh(true));
            setTimeout(() => {
              dispatch(
                getInstructionList({
                  blNum: AESDecrypt(blNum),
                  country: profileDetails.partnerType == "liner" ? dataCountry : blCountry
                })
              );
            }
              , 2000)
          })
          .catch((error: any) => {
            toast.error(error);
          });

        //update instruction list and redirect to previous page

        BackNavigation();
      }
    }
  };

  const _onAddressChange = useAsyncDebounce((value: string) => {
    var countryCode = 'USA';
    if (blCountry === 'US') {
      countryCode = 'USA';
    } else if (blCountry === 'SG') {
      countryCode = 'SGP';
    } else if (
      blCountry === 'ZA'
    ) {
      countryCode = 'ZAF';
    }
    else if (
      blCountry === 'GB'
    ) {
      countryCode = 'GBR';
    }else if (
      blCountry === 'VN'
    ) {
      countryCode = 'VNM';
    } else if (blCountry === 'KE') {
      countryCode = 'KEN';
    } else if (blCountry == 'JO') {
      countryCode = 'JOR'
    }
    dispatch(
      fetchAddressSuggestions2({
        text: value,
        country: countryCode,
      })
    );
  }, 200);

  const selectedAddressResult = useSelector(
    (state: RootState) => state.carrierHaulage.selectedAddressResult
  );

  const isAddressSuggesstionLoading = useSelector(
    (state: RootState) => state.carrierHaulage.isAddressSuggesstionsLoading
  );

  const registrationAddressSuggesstion = useSelector(
    (state: RootState) => state.carrierHaulage.registrationAddressSuggesstion
  );

  const _onPoReferenceChange = useAsyncDebounce((value: string) => {
    setPoReference(value);
  }, 200);

  const _onReturnTypeChange = useAsyncDebounce((value: string) => {
    setReturnType(value);
  }, 200);

  return (
    <Card>
      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={_onCloseForm}
        />

        <h2 className='fs-0 text-primary mb-1'>
          {t('resource_2:invoiceChanges')}
        </h2>
      </Card.Header>

      <Card.Body>
        <div className='container'>
          <Form onSubmit={onSubmit} autoComplete='off'>
            <div className='row'>
              {t('resource_message:invoiceChangeInstruction') != '' && (
                <div className='col-lg-12 mb-1'>
                  {/* <Form.Label style={{ color: '#f5822a', marginBottom: '0px' }}>
                    {t('resource_message:invoiceChangeInstruction')}
                  </Form.Label> */}

                  <Form.Label style={{ color: '#f5822a' }}>
                    <ul style={{ paddingLeft: '15px' }}>
                      {t('resource_message:invoiceChangeInstruction')
                        .split('\n')
                        .map((v: any) => (
                          <li>{v}</li>
                        ))}
                    </ul>
                  </Form.Label>

                  {/* <Form.Label style={{ color: '#f5822a' }}></Form.Label> */}
                </div>
              )}
              {!['KE', 'TZ'].includes(blCountry) &&
                <>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.taxId'>
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_2:taxId')}&nbsp;
                      </Form.Label>
                      <InputMask
                        mask={taxIdFormat}
                        type='text'
                        maxLength={10}
                        {...register('taxId')}
                        className={`fs--1 form-control ${errors.taxId ? 'is-invalid' : ''
                          }`}
                        onChange={(e) => {
                          setTaxId(e.target.value);
                          _onTaxIdChange(e.target.value);
                        }}
                        onBlur={(e: any) => {
                          if (taxId !== '') {
                            dispatch(
                              fetchCompanyDetailsForAgentDelegation({
                                country: blCountry,
                                taxId: taxId,
                              })
                            )
                              .unwrap()
                              .then((response: any) => {
                                setCompanyName(response?.partnerNm);
                                setMasterMstPartnerId(response?.mstPartnerId)
                                setValue('companyName', response?.partnerNm);
                                setAddress(response?.address);
                                setValue('address', response?.address);
                                setEmail(response?.emailId);
                                setValue('email', response?.emailId);
                                setContactNo(response?.phnNo);
                                setValue('contactNo', response?.phnNo);
                              });
                          } else {
                            setCompanyName('');
                            setValue('companyName', '');
                            setAddress('');
                            setValue('address', '');
                            setEmail('');
                            setValue('email', '');
                            setContactNo('');
                            setValue('contactNo', '');
                          }
                          setTaxId(e.target.value);
                          setValue('taxId', e.target.value);
                        }}
                        value={taxId}
                        disabled={disableFields}
                      />
                      {
                        <div className='invalid-feedback'>
                          {errors.taxId?.message?.toString()}
                        </div>
                      }
                    </Form.Group>
                    <>
                      {registrationdata.taxId?.data?.reg_number !== undefined &&
                        registrationdata.taxId?.data?.reg_number === taxId && (
                          <span className='mt-3 text-primary fs--1 fw-bold'>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className='me-2'
                            />
                            <span>
                              {t('resource_4:companyNoVerifiedSuccessfully')}
                            </span>
                          </span>
                        )}
                    </>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.companyName'>
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_2:companyName')}&nbsp;
                      </Form.Label>
                      <Form.Control
                        {...register('companyName')}
                        className={`fs--1 form-control ${errors.companyName ? 'is-invalid' : ''
                          }`}
                        type='text'
                        maxLength={200}
                        onChange={({ target }) => {
                          setCompanyName(target.value);
                          //_onCompanyNameChange(target.value);
                        }}
                        value={companyName}
                        disabled={disableFields}
                      />
                      <div className='invalid-feedback'>
                        {errors.companyName?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.customerCode'>
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_2:customerCode')}
                      </Form.Label>
                      <Form.Control
                        {...register('customerCode')}
                        value={customerCode}
                        maxLength={30}
                        disabled={
                          data?.status === 'Completed' ||
                            data?.status === 'Rejected'
                            ? disableFields
                            : profileDetails.partnerType !== 'liner'
                              ? disableFields
                              : false
                        }
                        onChange={(e) => {
                          setCustomerCode(e.target.value);
                          setValue('customerCode', e.target.value);
                        }}
                        onBlur={(e)=>{
                          setCustomerCode(customerCode.trim())
                          setValue('customerCode',watch('customerCode').trim() )

                        }}
                        type='text'
                      />
                    </Form.Group>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group
                      style={{ position: 'relative' }}
                      controlId='blReqIns.contactNo'
                    >
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_1:address')}&nbsp;
                      </Form.Label>
                      <Form.Control
                        {...register('address')}
                        className={`fs--1 form-control ${errors.address ? 'is-invalid' : ''
                          }`}
                        as='textarea'
                        maxLength={200}
                        onChange={({ target }) => {
                          setAddress(target.value);
                          _onAddressChange(target.value);
                          dispatch(updateSelectedAddressResult(''));
                          if (target.value == '') {
                            setPlaceId('');
                          }
                        }}
                        disabled={disableFields}
                        value={address}
                      />
                      <div className='invalid-feedback'>
                        {errors.address?.message?.toString()}
                      </div>
                      {address &&
                        !selectedAddressResult &&
                        !isAddressSuggesstionLoading &&
                        !placeId &&
                        !disableFields &&
                        registrationAddressSuggesstion?.length > 0 ? (
                        <div
                          style={{ position: 'absolute' }}
                          className='w-100 rounded border bg-white  odex-registration-address d-flex flex-column justify-content-start align-items-start'
                        >
                          {registrationAddressSuggesstion.map((v) => (
                            <div
                              className='p-2 d-flex flex-row justify-content-start align-items-center fs--1 cursor-pointer'
                              key={v.placeId}
                              onClick={() => {
                                _onAddressChange(v.text);
                                dispatch(updateSelectedAddressResult(v.text));
                                setAddress(v.text);
                                setPlaceId(v.placeId);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faLocation}
                                className='text-primary me-2'
                              />
                              <span>{v.text}</span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.email'>
                      <Form.Label className='fs--1'>
                        <span className='mandatory'>*</span>
                        {t('resource_2:emailAddress')}&nbsp;
                      </Form.Label>
                      <Form.Control
                        {...register('email')}
                        className={`fs--1 form-control ${errors.email ? 'is-invalid' : ''
                          }`}
                        type='email'
                        maxLength={50}
                        onChange={(e) => setEmail(e.target.value)}
                        size='sm'
                        disabled={disableFields}
                        value={email}
                      />
                      <div className='invalid-feedback'>
                        {errors.email?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.contactNo'>
                      {/* // <span className='mandatory'>*</span> */}
                      <Form.Label>{t('resource_2:contactNo')}</Form.Label>&nbsp;
                      <InputGroup>
                        <div className='row g-0 w-100'>
                          <div className='col-12'>
                            <Form.Control
                              size='sm'
                              {...register('contactNo')}
                              className={`fs--1 form-control ${errors.contactNo ? 'is-invalid' : ''
                                }`}
                              type='text'
                              maxLength={20}
                              onChange={(e) => setContactNo(e.target.value)}
                              value={contactNo}
                              disabled={disableFields}
                            />
                            <div className='invalid-feedback'>
                              {errors.contactNo?.message?.toString()}
                            </div>
                          </div>
                        </div>
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className='col-lg-5 mb-3'>
                    <Form.Group controlId='invoiceChanges.poReference'>
                      <Form.Label className='fs--1'>
                        {t('resource_2:poReference')}&nbsp;
                      </Form.Label>
                      <Form.Control
                        {...register('poReference')}
                        className={`fs--1 form-control ${errors.poReference ? 'is-invalid' : ''
                          }`}
                        type='text'
                        onChange={({ target }) => {
                          setPoReference(target.value);
                          _onPoReferenceChange(target.value);
                        }}
                        value={poReference}
                        disabled={disableFields}
                        maxLength={20}
                      />
                      <div className='invalid-feedback'>
                        {errors.poReference?.message?.toString()}
                      </div>
                    </Form.Group>
                  </div>
                </>}
              <div className='col-lg-5 mb-3'>
                <Form.Group controlId='invoiceChanges.returnType'>
                  {['KE', 'TZ'].includes(blCountry) && <span className='mandatory'>*</span>}
                  <Form.Label className='fs--1'>
                    {t('resource_2:returnLoc')}&nbsp;
                  </Form.Label>
                  <Form.Select
                    aria-label={t('resource_2:returnType')}
                    {...register('returnType')}
                    className={`fs--1 form-control ${errors.returnType ? 'is-invalid' : ''
                      }`}
                    disabled={disableFields}
                    value={returnType}
                    onChange={(e) => {
                      setReturnType(e.target.value);
                      _onReturnTypeChange(e.target.value);
                    }}
                  >
                    {returnTypeList.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Form.Select>
                  <div className='invalid-feedback'>
                    {errors.returnType?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              <div className='col-lg-12 mb-2 pt-2'>
                {!['KE'].includes(blCountry) && <Form.Label
                  className='fs--1'
                  style={{ fontWeight: 'bold !important' }}
                >
                  {t('Issue these invoices in the name of above party ')}
                </Form.Label>}
                <div style={{
                  display: 'grid',
                  grid: `2fr 2fr 2fr 2fr/0.1fr 2fr${['KE', 'TZ'].includes(blCountry) ? ' 4fr' : ''}`,
                  alignItems: 'center',
                  gap: 7
                }}>
                  {invoiceList.map((item) =>
                    <>
                      <Form.Check
                        type='checkbox'
                        className='me-2'
                        disabled={disableFields}
                        onChange={({ target }) => {
                          dispatch(updateInvoice({
                            code: item.value,
                            key: 'allowAccess',
                            value: target.checked
                          }))
                        }}
                        checked={item.allowAccess}
                      />

                      <Form.Label className='fs--1 m-0'>{item.text}</Form.Label>

                      {['KE', 'TZ'].includes(blCountry) &&
                        <div style={{ width: '100px' }} className='mx-2'>
                          <Form.Select
                            value={item.currency}
                            className={`fs--1 form-control  form-select mx-2`}
                            disabled={disableFields || !item.allowAccess}
                            onChange={(e) => {
                              dispatch(updateInvoice({
                                code: item.value,
                                key: 'currency',
                                value: e.target.value
                              }))
                            }}
                          >
                            {disableFields ?
                              <option value={item.currency}>{item.currency}</option> :
                              currencyList.map((r: any) => {
                                return (
                                  <>
                                    <option value={r?.currencyCode} key={r?.currencyShortName}>
                                      {r?.currencyShortName}
                                    </option>
                                  </>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      }

                    </>
                  )
                  }
                </div>

              </div>

              <div className='col-lg-5 mb-3'>
                <Form.Group controlId='carrierHaulage.userRemark'>
                  <Form.Label className='fs--1'>
                    {t('resource_2:userRemark')}
                  </Form.Label>
                  <Form.Control
                    {...register('userRemarks')}
                    className={`fs--1 form-control ${errors.userRemarks ? 'is-invalid' : ''
                      }`}
                    as='textarea'
                    onChange={(e) => setValue('userRemarks', e.target.value)}
                    value={watch('userRemarks')}
                    maxLength={1000}
                    disabled={
                      profileDetails.partnerType == 'liner' || disableFields
                    }
                  />
                  <div className='invalid-feedback'>
                    {errors.userRemarks?.message?.toString()}
                  </div>
                </Form.Group>
              </div>

              {rbac.rbacDetails.invoiceChangeApproveReject === 'Y' ||
                disableFields ? (
                <div className='col-lg-5 mb-3'>
                  <Form.Group controlId='carrierHaulage.rejectionRemark'>
                    <Form.Label className='fs--1'>
                      <span className='mandatory'>*</span>
                      {t('resource_1:rejectRemark')}
                    </Form.Label>
                    <Form.Control
                      {...register('remarks')}
                      className={`fs--1 form-control ${errors.returnType ? 'is-invalid' : ''
                        }`}
                      as='textarea'
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                      disabled={
                        data?.status === 'Completed' ||
                          data?.status === 'Rejected' ||
                          profileDetails?.partnerType === 'customer'
                          ? true
                          : false
                      }
                    />
                    <div className='invalid-feedback'>
                      {errors.remarks?.message?.toString()}
                    </div>
                  </Form.Group>
                </div>
              ) : (
                <></>
              )}

              {/* {profileDetails?.partnerType === 'liner' ? (
                <div className='col-lg-5 mb-3 d-none'>
                  <Form.Group controlId='invoiceChanges.customerCode'>
                    <Form.Label className='fs--1'>
                      {'Customer Code'}&nbsp;
                      <span>
                        {' '}
                        {disableFields && (
                          <Button
                            variant='falcon-primary'
                            onClick={handleChangeCustomerCode}
                            //disabled={!(selectedBls ?.length > 0)}

                            size='sm'
                            style={{
                              marginRight: '0.5rem',
                              position: 'relative',
                            }}
                            //   disabled={totalAmount.toFixed(2) > 0 ? false : true}
                          >
                            <div className='d-flex'>
                              <FontAwesomeIcon
                                icon={faPlus}
                                className='me-2 mt-1 '
                              />
                              <span className='fs--1'>Add Customer Code </span>
                            </div>
                          </Button>
                        )} */}
              {/* <FontAwesomeIcon
                          onClick={handleChangeCustomerCode}
                          icon={faPlusCircle}
                          className='me-2 reject'
                        /> */}
              {/* </span>
                    </Form.Label>
                    {showHideCustomerCode === false && (
                      <Form.Control
                        value={customerCode}
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onChange={(e) => {
                          setCustomerCode(e.target.value);
                          setValue('customerCode', e.target.value);
                        }}
                        type='text'
                      />
                    )}
                    {showHideCustomerCode === true && (
                      <Form.Select
                        aria-label={'Customer Code'}
                        {...register('customerCode')}
                        className={`fs--1 form-control ${
                          errors.returnType ? 'is-invalid' : ''
                        }`}
                        disabled={
                          data?.status === 'Completed' ||
                          data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        value={customerCode}
                        onChange={(e) => {
                          setCustomerCode(e.target.value);
                          setValue('customerCode', e.target.value);
                        }}
                      >
                        <option value=''>Select</option>
                        {selectedInvoiceChange.matchCodeList.map(
                          (option: any) => (
                            <option
                              key={option?.mapCode}
                              value={option?.mapCode}
                            >
                              {option?.mapCode}
                            </option>
                          )
                        )}
                      </Form.Select>
                    )}
                  </Form.Group>
                </div>
              ) : (
                ''
              )} */}
              {disableFields && (
                <div className='col-lg-5 mb-3'>
                  <Form.Group controlId='invoiceChanges.status'>
                    <Form.Label className='fs--1'>
                      {t('resource_1:status')}&nbsp;
                    </Form.Label>
                    <Form.Control
                      {...register('status')}
                      className={'fs--1 form-control'}
                      type='text'
                      value={status}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              )}
              <div className='col-lg-10'>
                <div className='d-flex justify-content-center align-items-center mt-4'>
                  {/*   {profileDetails?.partnerType === 'liner' ? ( */}
                  {blCountry === dataCountry &&
                    rbac.rbacDetails.invoiceChangeApproveReject == 'Y' ? (
                    <div className='d-flex justify-content-center align-items-center'>
                      <Button
                        variant='success'
                        className={
                          data?.status === 'Completed' ||
                            data?.status === 'Rejected'
                            ? 'd-none'
                            : 'me-2 approve'
                        }
                        size='sm'
                        disabled={
                          data?.status === 'Completed' ||
                            data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('A', 'INVOICE_CHANGE');
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className='me-2 approve'
                        />
                        {t('resource_1:approve')}
                      </Button>

                      <Button
                        variant='danger'
                        className={
                          data?.status === 'Completed' ||
                            data?.status === 'Rejected'
                            ? 'd-none'
                            : 'reject me-2'
                        } /* 'reject' */
                        size='sm'
                        disabled={
                          data?.status === 'Completed' ||
                            data?.status === 'Rejected'
                            ? disableFields
                            : false
                        }
                        onClick={(e) => {
                          onApproveReject('R', 'INVOICE_CHANGE');
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className='me-2 reject'
                        />
                        {t('resource_1:reject')}
                      </Button>
                      {rbac.rbacDetails.snoozeCUD === 'Y' && !location.pathname.includes('/bl')
                        && status == 'Submitted' ? <SnoozedToButton blNum={AESDecrypt(blNum)} blEventId={blEventId_docInsDashboard} /> : ""}
                    </div>
                  ) : (
                    !disabledPath && (
                      <div className='d-flex justify-content-center align-items-center'>
                        <Button
                          variant='falcon-primary'
                          size='sm'
                          className='me-2'
                          type='submit'
                          disabled={
                            invoiceList.every((item) => !item.allowAccess)
                          }
                        >
                          <FontAwesomeIcon icon={faSave} className='me-2' />
                          {t('resource_1:submit')}
                        </Button>
                        <Button
                          className='me-2'
                          variant='falcon-danger'
                          size='sm'
                          onClick={resetForm}
                        >
                          <FontAwesomeIcon icon={faRedo} className='me-2' />
                          {t('resource_1:reset')}
                        </Button>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
};

export default InvoiceChanges;
function setByteArr(arg0: string) {
  throw new Error('Function not implemented.');
}

function setLoiUpload(name: string) {
  throw new Error('Function not implemented.');
}
