import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import {
  updateBanks,
  updatePymtData,
  updatePymtModeOptions,
  paymentLoader,
  clearPaymentId,
} from "../slices/blPaymentSlice";
import axiosOdex, { axiosCacheInstance } from "../../../../axiosSetup";
import { toast } from "react-toastify";
import { updatePymtRefNo } from "../../payment/paymentSlice";
import { AESDecrypt } from "../../../../encrypt-util";
import { StandaloneInvoiceDetails } from "../slices/standaloneInvoicePaymentSlice";
import { RootState } from "../../../store";
import axiosInstance from "../../../../axiosSetup";
import moment from "moment";

export const getPaymentBanks = createAsyncThunk(
  "paymentbankdropdown/getPaymentBanks",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        "/api/user-management/v1/partners/getPartnerBankAccountDetails?accountCurrency="
      );
      /* const options=[{
      value:response.data.map((item:any)=>`${item.bankName}--Account ${item.accountName}`),text:response.data.map((item:any)=>`${item.bankName}--Account ${item.accountName}`)
     }] */
      const options = response.data.map((item: any) => ({
        value: item.mstAccountId,
        text: item.accountAlias,
        bankName: item.bankName,
        accountNo: item.accountNo,
      }));
      /*    const options = [
            { value: '', text: '--select Bank--' },
            { value: 'bankofAmerica', text: 'Bank of America -- Account 1578688 ' },
            { value: 'citiGroup', text: 'Citi Group -- Account 1578645 ' },
            { value: 'UsBankCrop', text: 'U.S.Bancrop -- Account 1578645' },
          ]; */
      dispatch(updateBanks(options));
      return options;
      // return {
      //   ...response.data,
      // };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error);
      return error;

      // return {
      //   next: null,
      //   previous: null,
      //   list: [],
      // };
    }
  }
);

export const updatePaymentAttachment = createAsyncThunk(
  "payment/updatePaymentAttachment",
  async ({ pmtId, tempFilePath, tempFileName, linerId }: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v2/v2/payment/addPopToPayment",
        {
          pmtId: pmtId,
          serverFilePath: tempFilePath,
          tempFileName: tempFileName,
          linerId: linerId,
        }
      );
      if (response.data.status != "success")
        throw Error(response.data?.message ?? "Failed to update payment!");

      toast.success(response?.data?.message);
      return true;
    } catch (e: any) {
      toast.error(e?.message ?? "Failed to update payment!");
      return false;
    }
  }
);

export const submitPaymentBanks = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v2/v2/payment/submit",
        {
          ...state,
          //  linerId: state?.linerId,
        }
      );
      if (response?.data?.message && response?.data?.success == "true") {
        toast.success(response.data.message, {
          theme: "colored",
        });
      } else {
        toast.error(response?.data?.message ?? "Something went wrong", {
          theme: "colored",
        });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? "Something went wrong", {
        theme: "colored",
      });
      dispatch(paymentLoader(false));
      return error;
    }
  }
);

export const addInvoiceToExistingPayment = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    // debugger
    // dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/payment/addInvoiceToExistingPayment`,
        {
          ...state,
        }
      );
      if (response) {
        setTimeout(() => {
          toast.success(`${Object.values(response?.data)[0]}`, {
            theme: "colored",
          });
        }, 1500);
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
    }
  }
);

export const getTransactionDetails = createAsyncThunk(
  "blSliderPayment/getTransactionDetails",
  async (params: any) => {
    try {
      const response = await axiosOdex.get(
        `/api/edo-rest-v2/v2/payment/getTransactionsByCcyAndAmount`,
        { params }
      );
      interface transactionResponse {
        transactionTime?: string;
        pmtReference?: string;
        bnkRefNo?: string;
        pmtAmount?: number;
        pmtFundCurrency?: string;
        payorAccountName?: string;
        remarks?: string;
      }
      return (
        response.data?.map((item: transactionResponse) => ({
          transaction_date: item.transactionTime,
          payment_ref_no: item?.pmtReference ?? "",
          bank_ref_no: item?.bnkRefNo ?? "",
          amt: item?.pmtAmount ?? "",
          ccy: item?.pmtFundCurrency ?? "",
          payorName: item?.payorAccountName ?? "",
          remarks: item?.remarks ?? "",
        })) ?? []
      );
    } catch (error) {
      console.error("Error fetching transaction details:", error);
      return [];
    }
  }
);

export const checkDuplicateReferenceNo = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    // debugger
    // dispatch(paymentLoader(true));
    /*    try {
      const response = await axiosOdex.get(
        `/api/edo-rest-v1/v1/payment/checkDuplicateReferenceNoExist?refNo=${state.inputValue}&currency=${state.selectedCcy}`
      );
      if (
        `${Object.values(response?.data)[0]}`.includes(
          'Duplicate Reference No Does Not Exist.'
        )
      ) {
   
        return true;
      } else if (
        `${Object.values(response?.data)[0]}`.includes(
          'Duplicate Reference No Exist for same Customer'
        )
      ) { */
    /*   toast.error(`${Object.values(response?.data)[0]}`, {
          theme: 'colored',
        }); */
    //  return Object.keys(response?.data)[0];
    //return response.data;
    //  }
    try {
      const response = await axiosOdex.get(
        `/api/edo-rest-v2/v2/payment/checkDuplicateReferenceNoExist?refNo=${state.inputValue}&currency=${state.selectedCcy}`
      );
      if (
        `${Object.values(response?.data)[0]}`.includes("different Currency")
      ) {
        toast.error(`${Object.values(response?.data)[0]}`);
      } else {
        if (
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. does Not exists."
          )
        ) {
          /*   toast.success(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          }); */
          return true;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. already exists."
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: "colored",
          });
          //  return Object.keys(response?.data)[0];
          // return response.data;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. already in use. Provide different Reference No."
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: "colored",
          });
        }
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      dispatch(paymentLoader(false));
      // return false;
    }
  }
);

export const updatePaymentReferenceNo = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    try {
      var response;
      console.log("state.serverFilePath", state.serverFilePath);
      state.serverFilePath == null
        ? (response = await axiosOdex.post(
            `/api/edo-rest-v2/v2/payment/updatePaymentReferenceNo?pmtId=${state.selectedPymtId}&newRefNO=${state.inputValue}&currency=${state.selectedCcy}&amount=${state.pymtAmt}&country=${state.country}&refType=${state.refType}&linerId=${state.linerId}`
          ))
        : (response = await axiosOdex.post(
            `/api/edo-rest-v2/v2/payment/updatePaymentReferenceNo?pmtId=${state.selectedPymtId}&newRefNO=${state.inputValue}&currency=${state.selectedCcy}&amount=${state.pymtAmt}&country=${state.country}&refType=${state.refType}&linerId=${state.linerId}&serverFilePath=${state.serverFilePath}&tempFileName=${state.tempFileName}`
          ));
      if (response) {
        toast.success(`${Object.values(response?.data.message).join("")}`, {
          theme: "colored",
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);
export const cancelPayment = createAsyncThunk(
  "payment/cancelPayment",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v2/v2/payment/cancelPayment?pmtId=${state.pmtId}&currency=${state.currency}`
      );
      if (response) {
        toast.success(`${Object.values(response?.data)[0]}`, {
          theme: "colored",
        });
        /*   setTimeout(() => {
        }, 2000); */
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);

export const sendMailToLiner = createAsyncThunk(
  "payment/sendToLiner",
  async (payload: any) => {
    return (
      await axiosInstance.post(`/api/edo-rest-v2/v2/payment/sendToLiner`, {
        pmtId: payload.pmtId,
        countryCode: payload.country,
        linerId: payload.linerId,
        amount: payload.amount,
        reference: payload.reference,
        payeeEmail: payload.payeeEmail,
      })
    ).data;
  }
);

export const revisitPayment = createAsyncThunk(
  "payment/revisitPayment",
  async (pmtId: number) => {
    return (
      await axiosInstance.post(
        `/api/edo-rest-v2/v2/payment/revisitPayment?pmtId=${pmtId}`
      )
    ).data;
  }
);

export const rejectPayment = createAsyncThunk(
  "payment/rejectPayment",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/payment/rejectPayment?pmtId=${state.pmtId}&currency=${state.currency}&remarks=${state.rejectionMsg}`
      );
      if (response) {
        toast.error(`${Object.values(response?.data)[0]}`, {
          theme: "colored",
        });
        /*      setTimeout(() => {
          window.location.href = '/payment';
        }, 2000); */
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
      // return false;
    }
  }
);

export const acceptPayment = createAsyncThunk(
  "payment/acceptPayment",
  async (state: any) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v2/v2/payment/approvePaymentByLiner?pmtId=${state.pmtId}&bnkRefNo=${state.bnkRefNo}&currency=${state.currency}&amount=${state.amount}&linerId=${state.linerId}&country=${state.country}&remarks=${state.remarks??""}&adminRefNumber=${state.adminRefNumber}`
      );
      if (Object.entries(response?.data)[0] != null) {
        toast.success("Payment Approved successfully", {
          theme: "colored",
        });
      } else {
        toast.error(`Entered Bank Reference number is not available`, {
          theme: "colored",
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
    }
  }
);
export const mergePayment = createAsyncThunk(
  "payment/acceptPayment",
  async (state: any) => {
    console.log("stateee", state);
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v2/v2/payment/mergePayment",

        state
      );
      console.log("Response received:", response?.data);

      console.log("Response received:", response?.data);

      // Extract the key-value pair from response
      const entries = Object.entries(response?.data || {});

      if (entries?.length > 0) {
        const [key, value] = entries[0];

        // Check if the key contains "successfully"
        //   if (typeof key === "string" && key.includes("successfully")) {
        if (response.data.message.includes("Successfully")) {
          // Show success toast with the full message (key + value)
          //   const fullMessage = `${key}: ${value}`;
          // Explicitly assert that fullMessage is a string
          toast.success(response.data.message, { theme: "colored" });
        } else if (response?.data?.message) {
          const errorMessage = response.data.message;

          // Show error toast with the message only
          toast.error(errorMessage, { theme: "colored" });
        }
      } else {
        toast.error("Unexpected response format", { theme: "colored" });
      }
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;
      console.error(error.message);
    }
  }
);

export const checkDuplicateReferenceNoByPymtId = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.get(
        `/api/edo-rest-v2/v2/payment/checkDuplicateReferenceNoExistByPmtId?refNo=${state.inputValue}&currency=${state.selectedCcy}&pmtId=${state.selectedPymtId}`
      );

      if (
        `${Object.values(response?.data)[0]}`.includes("different Currency")
      ) {
        toast.error(`${Object.values(response?.data)[0]}`);
        // return response.data;
      } else {
        if (
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. does Not exists."
          )
        ) {
          /*   toast.success(`${Object.values(response?.data)[0]}`, {
            theme: 'colored',
          }); */

          //  debugger

          return true;
        } else if (
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. already exists."
          ) ||
          `${Object.values(response?.data)[0]}`.includes(
            "Payment Reference No. already in use. Provide different Reference No."
          )
        ) {
          toast.error(`${Object.values(response?.data)[0]}`, {
            theme: "colored",
          });
          //  return Object.keys(response?.data)[0];
          // return response.data;
        }
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      dispatch(paymentLoader(false));
      // return false;
    }
  }
);

export const getPymtIdByPmtRefNo = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.get(
        `/api/edo-rest-v1//v1/payment/${state.inputValue}?pmtMode=${state.paymentMode}`
      );
      return response;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);

      // return false;
    }
  }
);

export const getPymtConfDetails = createAsyncThunk(
  "blPayment/getPaymentConfig",
  async (state: any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/edo-rest-v2/v2/payment/getPaymentConfig?paymentMode=${state.paymentMode}&linerId=${state.linerId}&country=${state.paymentCountry}`,
        {
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );
      return {
        minLength: 10,
        maxLength: 25,
        paymentProofAttachment: "O",
        sendToLiner: "N",
        ...response?.data,
      };
    } catch (e) {
      return {
        minLength: 10,
        maxLength: 25,
        paymentProofAttachment: "O",
        sendToLiner: "N",
      };
    }
  }
);

export const submitPaymentAddFund = createAsyncThunk(
  "Payment/addFund",
  async (payload: any) => {
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v1/v1/payment/addFund",
        {
          pmtReference: payload?.pmtReference,
          pmtMode: parseInt(payload?.pmtMode),
          currency: payload?.currency,
          pmtProofAtt: payload?.pmtProofAtt || "",
          payorBankName: payload?.payorBankName || "",
          payorAccountId: payload?.payorAccountId || "",
          pmtStatus: payload?.pmtStatus || "",
          pmtType: payload?.pmtType || "",
          pmtAmount: parseInt(payload?.pmtAmount) || null,
          linerAccountId: 1,
          linerId: payload.linerId,
        }
      );
      return { success: true, data: response.data };
    } catch (e) {
      const error = e as AxiosError<any>;
      console.error("Error: ", error);

      return { success: false, data: error.response?.data?.message || "" };
    }
  }
);

export const getPymtModeOptions = createAsyncThunk(
  "pymtModedropdown/getPymtModeOptions",
  async (payload: any, { dispatch }) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/edo-rest-v2/v2/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=PYMT_MODE&linerId=${payload.selectedLinerId}&countryCode=${payload.partnerCountry}`,
        {
          headers: {
            Authorization:
              "Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev",
          },
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );

      const pymtModeOptions = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));

      dispatch(updatePymtModeOptions(pymtModeOptions));
      return pymtModeOptions;
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getPymtDetailsByPymtNo = createAsyncThunk(
  "payment/getPymtDetailsByPymtNo",
  async (state: any, { dispatch }) => {
    try {
      dispatch(clearPaymentId());
      const response = await axiosOdex.get(
        `/api/edo-rest-v2/v2/payment/getPaymentById/${AESDecrypt(state.pymtId)}`
      );
      dispatch(updatePymtData(response.data));
      if (response?.data?.payorAccountId) {
        dispatch(getAccDetailsByAccountId(response.data.payorAccountId));
      }

      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getPymtRefNo = createAsyncThunk(
  "payment/PaymentRefNo",
  async (payload: any, { dispatch }) => {
    const csrfMeta =
      document.querySelector<HTMLMetaElement>("meta[name='_csrf']");
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v2/v2/payment/generatePmtReferenceNoByCountry?countryCode=${payload?.partnerCountry}`,
        {
          "X-CSRF-TOKEN": csrfMeta?.content || "",
          "Content-Type": "application/x-www-form-urlencoded",
          headers: {
            Authorization:
              "Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev",
          },
        }
      );
      dispatch(updatePymtRefNo(response.data));
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);

export const updateBankPymtRefNo = createAsyncThunk(
  "payment/updateBankPymtRefNo",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/payment/updatePaymentRefNo?pmtId=${state.pmtId}&newRefNO=${state.newRefNO}`,
        {
          headers: {
            Authorization:
              "Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev",
          },
        }
      );
      // toast.success(response.data[0].message, {
      //   theme: 'colored',
      // });
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);

export const editBankPymtRefNo = createAsyncThunk(
  "payment/editBankPymtRefNo",
  async (state: any, { dispatch }) => {
    try {
      const response = await axiosOdex.post(
        `/api/edo-rest-v1/v1/payment/editPayment`,
        state,
        {
          headers: {
            Authorization:
              "Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev",
          },
        }
      );
      toast.success(response.data.message, {
        theme: "colored",
      });
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);
export const getSearchFiltter = createAsyncThunk(
  "searchFiltter/getSearchFiltter",
  async (partnerCountry: any, { dispatch, getState }: any) => {
    const state: RootState = getState();
    try {
      const response = await axiosCacheInstance.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=SEARCH_FILTER&linerId=${state.navbarTop.linerId}&countryCode=${partnerCountry}`,
        {
          headers: {
            Authorization:
              "Bearer jmVm90UwpC5gNPBYiEEMlFmwq9HKo524YxxVWz43FrAWvELEUzSxzsUSYGWwCA8WmvYwNoqdKhlFBehn6n7IkPv0pq9Y9wRVx90fTegJtUCcBmGLLhyEyLj6ZlXle1JAlDatIiOUurZI6CquQJTQ5ajuduAQvo0HswRERfsikOZ2skYj6g4RKCUK8dEPQUgr73pDIL3tjfuQkcTSKBcCvT10LQZpCzLodVON3lj6Sa3IYFz2xE1JUhb6nev",
          },
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );

      const searchFiltter = response.data.map((item: any) => ({
        value: item.codeId,
        text: item.codeDesc,
      }));
    } catch (e) {
      const error = e as AxiosError;
      return error;
    }
  }
);

export const getMultiCountryAllowance = createAsyncThunk(
  "quickPay/getCountryList",
  async (payload: any) => {
    try {
      const response = await axiosCacheInstance.get(
        `/api/edo-rest-v1/v1/mstCode/getMstCodeListByTypeAndLanguage?language=EN&codeType=MULTI_COUNTRY_ALLOWANCE&linerId=${payload.linerId}&countryCode=${payload.country}`,
        {
          cache: {
            ttl: 10 * 60 * 1000,
          },
        }
      );
      const options = response.data.map((item: any) => ({
        value: item.codeDesc,
        text: item.codeDesc,
        full: item.codeDesc,
      }));
      const filteredArray = options.filter((item: any) => item.value === "Y");

      if (filteredArray !== undefined && filteredArray.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);

      return {};
    }
  }
);

export const submitAutoPayment = createAsyncThunk(
  "",
  async (state: any, { dispatch }) => {
    dispatch(paymentLoader(true));
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v1/v1/payment/submitPayment",
        {
          ...state,
          //  linerId: state?.linerId,
        }
      );
      if (response?.data?.message) {
        toast.success(response.data.message, {
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong", {
          theme: "colored",
        });
      }
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      dispatch(paymentLoader(false));
      return error;
    }
  }
);

export const validateStandaloneInvoiceForPayment = createAsyncThunk(
  "validateStandaloneInvoiceForPayment",
  async (state: StandaloneInvoiceDetails) => {
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v2/v2/invoice/validateInvoiceDetailsForPayment",
        {
          invoiceId: null,
          invoiceNo: state.invoiceNo || null,
          invoiceAmount: state.invoiceAmount || null,
          invoiceCurrency: state.invoiceCurrency || null,
        }
      );
      return {
        ...response?.data,
        rowNumber: state.rowNumber,
      };
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);

export const addStandaloneInvoice = createAsyncThunk(
  "addStandaloneInvoice",
  async (state: StandaloneInvoiceDetails[]) => {
    try {
      const response = await axiosOdex.post(
        "/api/edo-rest-v2/v2/invoice/addStandaloneInvoice",
        state.map((invoice: StandaloneInvoiceDetails) => {
          return {
            invoiceId: invoice.invoiceId,
            invoiceNo: invoice.invoiceNo,
            invoiceAmount: invoice.invoiceAmount,
            invoiceCurrency: invoice.invoiceCurrency,
          };
        })
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);

export const getCurrencyDDList = createAsyncThunk(
  "getCurrencyDDList",
  async () => {
    try {
      const response = await axiosOdex.get(
        "/api/user-management/v1/country/getCurrencyDDList"
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      console.error(error.message);
      toast.error(error.message, {
        theme: "colored",
      });
      return error;
    }
  }
);

export const getPlatformFees = createAsyncThunk(
  "blPayment/getPaymentFees",
  async (payload: any) => {
    try {
      const response = await axios.get(
        `/api/payment-gateway-v2/v2/platform/charges?pmtAmount=${payload.amount}&countryCode=${payload.country}&currency=${payload.ccy}&linerId=${payload.linerId}&pmtMode=${payload.paymentMode}`
      );

      return {
        platformFee: response.data.platformFee,
        platformChargeConfigId: response.data.platformChargeConfigId,
      };
    } catch (e) {
      const error = e as AxiosError;
      return {
        platformFee: 0,
        platformChargeConfigId: "",
      };
    }
  }
);

export const getAccDetailsByAccountId = createAsyncThunk(
  "blPayment/getAccDetailsByAccountId",
  async (payload: any) => {
    try {
      const response = await axiosInstance.get(
        `/api/user-management/v1/partners/getAccountDetailsByAccId?accId=${payload}`
      );
      return {
        bankName: response.data.bankName,
        accNo: response.data.accountNo,
      };
    } catch {
      return { bankName: "", accNo: "" };
    }
  }
);

export const getCurrencyDDListByBankNm = createAsyncThunk(
  "blPayment/getCurrencyDDListByBankNm",
  async (payload: { country: string }) => {
    try {
      const response = await axios.get(
        `/api/user-management/v1/country/getCurrencyDDListWithoutSession?country=${payload.country}`
      );
      return response?.data;
    } catch (e) {
      const error = e as AxiosError;

      return null;
    }
  }
);
