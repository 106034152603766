import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BLDetailsList } from "../../dashboard/types";
import { PaymentBlDetails } from "../../payment/paymentSlice";

interface MultiBlPaymentTypes {
  selectedRowsMultiPay: PaymentBlDetails[];
  selectedRowsMultiPayTable: any;
  selectedRowsPaymentMultiPayTable: any;
  selectedAmountAll: any;
  selectedAmountPaymentAll: any;
  selectedRowsInvoice: any[];
  selectedRowsPayment: any[];
}

const initialState: MultiBlPaymentTypes = {
  selectedRowsMultiPay: [],
  selectedRowsMultiPayTable: {},
  selectedAmountAll: [],
  selectedAmountPaymentAll: [],
  selectedRowsPaymentMultiPayTable: [],
  selectedRowsInvoice: [],
  selectedRowsPayment: [],
};

export const multiBlPaymentSlice = createSlice({
  name: "multiBlPayment",
  initialState,
  reducers: {
    updateSelectedBlListMultiPay: (
      state,
      action: PayloadAction<PaymentBlDetails[]>
    ) => {
      state.selectedRowsMultiPay = action.payload;
    },
    updateSelectedBlListMultiPayTable: (state, action: PayloadAction<any>) => {
      state.selectedRowsMultiPayTable = action.payload;
    },
    updateSelectedpaymentMultiPayTable: (state, action: PayloadAction<any>) => {
      state.selectedRowsPaymentMultiPayTable = action.payload;
    },
    updateSelectedAmount: (state, action: PayloadAction<any>) => {
      state.selectedAmountAll = action.payload;
    },
    updateSelectedAmountPayment: (state, action: PayloadAction<any>) => {
      state.selectedAmountPaymentAll = action.payload;
    },
    updateSelectedRowsInvoice: (state, action: PayloadAction<any[]>) => {
      state.selectedRowsInvoice = action.payload;
    },
    updateSelectedRowsPayment: (state, action: PayloadAction<any[]>) => {
      state.selectedRowsPayment = action.payload;
    },
  },
});

export const {
  updateSelectedBlListMultiPay,
  updateSelectedBlListMultiPayTable,
  updateSelectedAmount,
  updateSelectedRowsInvoice,
  updateSelectedpaymentMultiPayTable,
  updateSelectedRowsPayment,
  updateSelectedAmountPayment,
} = multiBlPaymentSlice.actions;

export default multiBlPaymentSlice.reducer;
