import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import {
  toggleColumnSettingsDashboard,
  updateToggle,
} from './UserDetailsSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateRowColumns, updateUserDetails } from './UserDetailsSlice';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AESEncrypt } from '../../../encrypt-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from './User';
import { getAllUserDetailsByUser } from './action/UserDetails';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { AESDecrypt } from '../../../encrypt-util';
import { getUserProfile } from '../../common/actions/userDetails';
import FilterView from '../../common/components/FilterView';
import { getColumnViews } from '../../common/actions/dataTable';
import { updateParams } from '../../common/components/DataTable/slices/DataTable';
import { updateCurrentPageUserDashboard } from '../dashboard/slices/dashboardSlice';
import { getDashboardData } from '../dashboard/actions/dashboard';
import quieryString from 'query-string';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';
import dataTableBgImg from '../../../../src/assets/img/illustrations/cornerRight-1.png';
import { updateDashbaordName } from '../../common/slices/navbarTop';
import OdexSpinner from '../../common/components/OdexSpinner';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';
import DashboardContainer from '../../../components/common/DashboardContainer';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
const dashboarName = 'USER_DASHBOARD';
const uniqueFilterKey = 'userDetails';
const defaultSort = 'user_id desc';

const UserDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { userID } = useParams();
  const { t, i18n } = useTranslation();

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({ filterState: null, search: null, grids: null });

  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);

  const userDetails = useSelector((state: RootState) => state.userList);
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const showColumnSettings = useSelector(
    (state: RootState) => state.userList.showColumnSettings
  );
  const loading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const dataLoading = useSelector(
    (state: RootState) => state.userList.dataLoading 
  );

  const grids = useSelector((state: RootState) => state.dataTable.userGrids);
  const sortState = useSelector(
    (state: RootState)=> state.dataTable.sortState
  );
  const data = useSelector(
    (state: RootState) => state.dashboard.userDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const currentPageUser = useSelector(
    (state: RootState) => state.dashboard.currentPageUser
  );

  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const userTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.userTopSearchState
  );

  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageUserDashboard(page));
  };

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };

  const getParams = () => {
    return {
      user_id: AESDecrypt(location?.pathname?.split?.('/')?.[3]) || '',
    };
  };

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();
    
    useEffect(() => {
      callback.current = effect;
    }, [effect]);
  
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };
  
      const timer = setTimeout(handler, delay);
      
      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const filterStateLocal = filterState?.[uniqueFilterKey];
  const sort = (quieryString.parse(location.search)?.sort as string)
    ?.split(',')
    ?.join(' ');

  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const userDashboardDefinition = grids?.[0]?.columnConfigGrid || '';
    if (!userDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(userDashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  // Getting the user country
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry =
    storedCountry ?? linerDataCountry ?? profileDetails.partnerCountry ?? null;

  useEffect(() => {
    dispatch(updateDashbaordName(dashboarName));
  }, []);

  useEffect(() => {
    if (dataCountry && linerId) {
      dispatch(
        getColumnViews({ gridName: dashboarName, country: dataCountry, linerId : linerId })
      );
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadUserDashboard(false);
  }, [currentPageUser]);

  useEffect(() => {
    loadUserDashboard(true);
  }, [location.pathname, linerId]);

  // useEffect(() => {
  //   const currentStateValue = {
  //     filterState: JSON.stringify(filterStateLocal),
  //     search: location.search,
  //     grids: grids,
  //   };

  //   if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
  //     loadUserDashboard(true);
  //     setPreviousState(currentStateValue);
  //   }
  // }, [filterStateLocal, location.search, grids, linerId]);

  useDebouncedEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };

    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      loadUserDashboard(true);
      setPreviousState(currentStateValue);
    }
  }, [filterStateLocal, location.search, grids, linerId], 700);

  const loadUserDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};
        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            dispatch(
              getDashboardData({
                gridName: dashboarName,
                payload: {
                  fields: /*  SelectedViewFields?.fields || */ [],
                  filters: finalFilterApiState ?? {},

                  size: 10,
                  sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
                  page: currentPageUser,
                },
                dataCountry: dataCountry,
                partnerId: profileDetails.partnerId,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }
        }
      } else if (
        (profileDetails.partnerType === 'customer' &&
          profileDetails.partnerId &&
          grids?.[0]?.whereClause) ||
        profileDetails?.partnerType === 'liner'
      ) {
        dispatch(
          getDashboardData({
            gridName: dashboarName,
            payload: {
              fields: /* SelectedViewFields?.fields || */ [],
              filters:
                selectedTopSearchFilter?.where === '' ? userTopSearchState : {},
              size: 10,
              sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
              page: currentPageUser,
            },
            dataCountry: dataCountry,
            partnerId: profileDetails.partnerId,
            where: grids?.[0]?.whereClause
              ? AESEncrypt(grids?.[0]?.whereClause)
              : null,
            parameter:
              selectedTopSearchFilter?.where !== ''
                ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                : {},
          })
        );
      }
    } else if (
      !location.pathname.includes('blsliderinvoice/') &&
      location.pathname.includes('/payment') &&
      location?.state?.from == undefined
    ) {
      if (
        (profileDetails.partnerType === 'customer' &&
          profileDetails.partnerId &&
          grids?.[0]?.whereClause) ||
        profileDetails.partnerType === 'liner'
      ) {
        dispatch(
          getDashboardData({
            gridName: dashboarName,
            payload: {
              fields: /* SelectedViewFields?.fields || */ [],
              filters:
                selectedTopSearchFilter?.where == '' ? userTopSearchState : {},
              size: 10,
              sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
              page: currentPageUser,
            },
            dataCountry: dataCountry,
            partnerId: profileDetails.partnerId,
            where: grids?.[0]?.whereClause
              ? AESEncrypt(grids?.[0]?.whereClause)
              : null,
            parameter:
              selectedTopSearchFilter?.where !== ''
                ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                : {},
          })
        );
      }
    }
  };

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  //slider
  useEffect(() => {
    if (matchPath(`${APP_PATH_PREFIX}/users/details/:userID`, location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-2',
          outletClass: 'col-lg-10',
        })
      );

      //setAccountList(partnerData.data.partnerAccountList.list.map((v:any)=>v));
    } else if (matchPath(`${APP_PATH_PREFIX}/users`, location.pathname)) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
      if (
        userDetails?.data?.userData?.list?.length !== 0 &&
        userDetails?.data?.userData?.list?.find(
          (v: any) => v?.mstUsrId === null
        ) !== undefined
      ) {
        dispatch(getUserProfile())
          .unwrap()
          .then((response: any) => {
            dispatch(
              getAllUserDetailsByUser({
                partnerId: response?.data?.partnerId,
                usrId: response?.data?.userId,
              })
            );
          });
      }
    }
  }, [location.pathname]);

  const _onCloseForm = () => {
    dispatch(
      updateToggle({
        ...userDetails,
        toggle: false,
      })
    );
    navigate(`${APP_PATH_PREFIX}/users`);
  };

  useEffect(() => {
    dispatch(getUserProfile())
      .unwrap()
      .then((response: any) => {
        if (userDetails.data.userData.list.map((v: any) => v).length === 0) {
          dispatch(
            getAllUserDetailsByUser({
              partnerId: response?.data?.partnerId,
              usrId: response?.data?.userId,
            })
          );
        }
      });
  }, []);
  
  const sideWidth = useMemo(
    () =>
      Object.entries({
        [`/users`]:0,
      }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9,
    [location.pathname]
  );
  var path = location.pathname;
  var parts = path.split('/');
  var blslider = parts[1];

  return (
    <DashboardContainer
      sideWidth={sideWidth}
      inner={false}
      innerOffset={0}
      middleScroll={false}
      middleChildren={
        location.pathname.includes('/users/details') || location.pathname.includes('/users/new') ? (
          <div className={`flex-1 h-100 smoothDiv w-100`}>
            <Card className='h-100'>
              <Card.Header className='border-bottom d-flex align-items-center'>
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  className='cursor-pointer fa-lg mb-1 me-1'
                  onClick={_onCloseForm}
                />
                <h2 className='fs-0 text-primary mb-1'>
                  {AESDecrypt(userID?.toString()).toString() !== '0' ? t("resource_2:editUser") : t("resource_2:addUser")}
                </h2>
              </Card.Header>
              <Card.Body className='p-0 d-flex flex-column'>
                <div
                  className={`${userDetails.rowColumns.outletClass} zindex10`}
                  style={{ margin: '25px' }}
                >
                  <User />
                </div>
              </Card.Body>
            </Card>
          </div>
        ) : null
      }
    >
      <FilterView
        loadList={loadUserDashboard}
        showAddUserButton={
          userDetails?.data?.userData?.list.length !== 0 &&
          location.pathname === '/users'
        }
        showColumnSettings={location.pathname === '/users'}
        toggleColumnSettings={toggleColumnSettingsDashboard}
        configViews={
          grids?.find?.((g) => g?.gridName === dashboarName)
            ?.configGroup || []
        }
        showExport={location.pathname === '/users'}
        showRightDiv={
          location.pathname.includes('/users/details')
            ? false
            : true
        }
        uniqueFilterKey={uniqueFilterKey}
        data={data}
        columns={columns}
        dataLoading={dataLoading}
        columnSettingsPersistKey='@odex/user/v1'
        dashboardName={dashboarName}
        gridDef={grids}
      />
      {columns?.length > 0 && (
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ''}
          gridName={dashboarName}
          sortable
          searchable={false}
          showColumnFilter={false}
          selection={false}
          perPage={10}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '10')}
          hasNext={data?.hasNext}
          currentPage={currentPageUser}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) =>
            setCurrentPage(pageNumber)
          }
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={onHideColumnSettings}
          uniqueFilterKey={uniqueFilterKey}
          staticHiddenCols={configs?.staticHiddenCols || []}
          tableMinHeight={400}
          dataLoading={loading}
          customSort={true}
          previous={userDetails?.data?.userData?.previous || ''}
          next={userDetails?.data?.userData?.next || ''}
          shortPrepend='users_'
          defaultHiddenCols={configs?.defaultHiddenCols || []}
          configGroup={grids?.find?.(g => g?.gridName === dashboarName)?.configGroup || []}
        />
      )}
    </DashboardContainer>
  );
};

export default UserDetails;
