import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router';
import { RootState } from '../app/store';
import { useBlDashName, useInstDashName, useInvDashName } from './useDashName';

/**
 * Returns onBack function that on call will navigate to the previous
 * Card
 * @returns funnction
 */
export function useBackNavigation(inner=true) {
  const navigate = useNavigate();
  const location = useLocation();
  const { blNum, custId } = useParams();
  const blDashboardName = useBlDashName()
  let datatableState: string = useSelector(
    (state: RootState) => state.navbarTop.selectedDashbaordName
  );
  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';

  const paths = {
    BL_DASHBOARD_CUSTOMER: {
      home: `${APP_PATH_PREFIX}/home`,
      document: `${APP_PATH_PREFIX}/bl/${encodeURIComponent(blNum ?? '')}`
    },
    BL_DASHBOARD_LINER: {
      home: `${APP_PATH_PREFIX}/home`,
      document: `${APP_PATH_PREFIX}/bl/${encodeURIComponent(blNum ?? '')}`
    },
    INVOICE_DASHBOARD_LINER: { 
      home: `${APP_PATH_PREFIX}/payment`,
      document: `${APP_PATH_PREFIX}/payment/${encodeURIComponent(blNum ?? '')}`,
      standalone: `${APP_PATH_PREFIX}/payment/addInvoice`,
    },
    INVOICE_DASHBOARD_CUSTOMER: { 
      home: `${APP_PATH_PREFIX}/payment`,
      document: `${APP_PATH_PREFIX}/payment/${encodeURIComponent(blNum ?? '')}`,
      standalone: `${APP_PATH_PREFIX}/payment/addInvoice`,
    },
    INSTRUCTION_DASHBOARD_LINER: {
      home: `${APP_PATH_PREFIX}/instruction`,
      document: `${APP_PATH_PREFIX}/instruction/${encodeURIComponent(blNum ?? '')}`,
    },
    INSTRUCTION_DASHBOARD_CUSTOMER: {
      home: `${APP_PATH_PREFIX}/instruction`,
      document: `${APP_PATH_PREFIX}/instruction/${encodeURIComponent(blNum ?? '')}`,
    },
    EMAIL_LOGS: {
      home: `${APP_PATH_PREFIX}/emailDashboard`
    },
    USER_DASHBOARD: { home: `${APP_PATH_PREFIX}/user` },
    STANDING_INSTRUCTION: { home: `${APP_PATH_PREFIX}/standing` },
    PAYMENT_DASHBOARD: {home: `${APP_PATH_PREFIX}/paymentDashboard`},
    CUSTOMER_DASHBOARD: {
      home: `${APP_PATH_PREFIX}/mappingdashboard`,
      customer: `${APP_PATH_PREFIX}/mappingdashboard/customerdetails/${custId}`
    }
  };
  
  const onBack = () => {
    const search = location.search
    if (!paths.hasOwnProperty(datatableState)) {
      datatableState = blDashboardName
    }
    
    if(inner){
      const outer:string = (()=>{
        if(location.pathname.includes('getInvoiceRequest'))return 'home'
      if(location.pathname.includes('addInvoice'))return 'standalone'

      if (location.pathname.includes(`${APP_PATH_PREFIX}/bl/`) &&
          !matchPath(`${APP_PATH_PREFIX}/bl/:blNum/`, location.pathname))return 'document'
      
    
      if (['edituser', 'addcode'].some((i)=>location.pathname.includes(i))
      )return 'customer'

      return 'home'
    })()
      
      navigate((paths as any)[datatableState][outer]+search)
      return
    }

    navigate((paths as any)[datatableState]['home']+search)
    
  };
  return onBack;
}
