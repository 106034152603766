import { useTranslation } from "react-i18next";

export const Home = (data) => {
  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const {t}=useTranslation();
  return {
    label: t('resource_1:dashboard'),
    labelDisable: true,
    children: [
      data?.rbacDetails?.blDashboard === 'Y' && {
        name: t('resource_2:blDashboard'),
        icon: 'chart-pie',
        to: `${APP_PATH_PREFIX}/home`,
        active: true,
      },
      data?.rbacDetails?.invDashboard === 'Y' && {
        name: t('resource_2:invoiceDashboard'),
        to: `${APP_PATH_PREFIX}/payment`,
        active: false,
        icon: 'shopping-cart',
      },
      data?.rbacDetails?.instructionDashboard === 'Y' && {
        name: t("resource_1:instruction"), 
        to: `${APP_PATH_PREFIX}/instruction`,
        active: true,
        icon: 'book',
      },
      data?.rbacDetails?.registrationMstDashboard === 'Y' && {
        name: t('resource_2:registrationMaster'),
        to: `${APP_PATH_PREFIX}/registrationmaster`,
        active: true,
        icon: 'file-pen',
      },
      data?.rbacDetails?.payDetailsDashboard === 'Y' && {
        name: t('resource_2:paymentDetails'),
        to: `${APP_PATH_PREFIX}/paymentDashboard`,
        active: false,
        icon: 'pay',
      },
      data?.rbacDetails?.paymentDashboard === 'Y' && {
        name: t("resource_1:transactions"),
        to: `${APP_PATH_PREFIX}/payment-fund`,
        active: false,
        icon: 'credit-card',
      },
      data?.rbacDetails?.standingInstDashboard === 'Y' && {
        name: t('resource_2:standingInstruction'),
        to: `${APP_PATH_PREFIX}/standing`,
        active: false,
        icon: 'list-ul',
      },
      data?.rbacDetails?.reportDashboard === 'Y' && {
        name: t('resource_1:report'),
        to: `${APP_PATH_PREFIX}/report`,
        active: false,
        icon: 'report',
      },
      data?.rbacDetails?.receiptDashboard === 'Y' && {
        name: t('resource_1:receipt'),
        to: `${APP_PATH_PREFIX}/receipt`,
        active: false,
        icon: 'receipt',
      },
      data?.rbacDetails?.emailDashboard === 'Y' && 
      {
        name: t('resource_2:emailDashboard'),
        to: `${APP_PATH_PREFIX}/emailDashboard`,
        active: false,
        icon: 'emailDashboard',
      },
      data?.rbacDetails?.mappingDashboard === 'Y' && {
        name: t('resource_2:customerMapping'),
        to: `${APP_PATH_PREFIX}/mappingdashboard`,
        active: false,
        icon: 'user-plus',
      },
      data?.rbacDetails?.gateInGateOutDashboard === 'Y' &&
       {
        name: t('resource_2:gateInOut'),
        to: `${APP_PATH_PREFIX}/gateinout`,
         active: false,
        icon: 'gateinout',
     },
     (data?.rbacDetails?.userMgmtCreate === 'Y' ||
      data?.rbacDetails?.userMgmtView === 'Y') && {
      name: t('resource_1:users'),
      active: false,
      icon: 'user',
      to: `${APP_PATH_PREFIX}/users`,
    }
    ].filter((i) => i),
  };
};

const getRoutes = (data) => {
  return [Home(data)];
};

export default getRoutes;
