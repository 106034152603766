import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import useDataCountry from "./useDataCountry";
import { useLoading } from "../middleware/loadingMiddleware";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCountryConfig } from "../app/features/dashboard/actions/instruction";

/**
 * NOTE: it does NOT make any API calls,
 * make sure the appropriate APIs are called for 
 * oblDetails / blEvent / instDashboard view
 * 
 * @param event 
 * @param type 
 * @param blEventId 
 * @returns 
 */
export default function useBlCountry( 
    event:"new"|"view",
    type:"import"|"export", 
    instId:number|null
    ){
    const [viewCountry, setViewCountry] = useState(null)
    const [config, setConfig] = useState('')
    const loadingNew = useLoading(['instruction/getOBLDetails','instruction/getCoutryConfig'])
    const dataCountry = useDataCountry();
    const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
    const form = useSelector((state:RootState)=>state.oblDetails.oblForm)

   const dispatch = useDispatch<AppDispatch>()
   useEffect(()=>{
    if(linerId && form.pod && form.pol && event!='view')
    dispatch(getCountryConfig({
        import:type == "import",
        linerId,
        instId:instId,
        pod:form.pod?.substring(0,2),
        pol:form.pol?.substring(0,2),
        spol:form.startPol?.substring(0,2),
        fpod:form.fpod?.substring(0,2)
    })).unwrap().then((configVal)=>{
        setConfig(configVal)
    })
   },[type,linerId,form,event])

    const newCountry = (()=>{
            if(type == "import"){
                return (form.fpod && config != 'POD' && form.fpod.trim() != ''?form.fpod:form.pod)?.substring(0,2)
            }
            return (form.startPol && config != 'POL' &&form.startPol.trim() != ''?form.startPol:form.pol)?.substring(0,2); 
   })()

   if (event == 'view'){
    return [viewCountry??dataCountry, setViewCountry]
   }
   return [loadingNew||!config?null:newCountry??dataCountry, setViewCountry]
}