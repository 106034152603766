/* eslint-disable react-hooks/exhaustive-deps */
import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import DataTable from '../../common/components/DataTable';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';
import {
  toggleDHColumnSettings,
  updateSelectedEventIdDH,
  updateSelectedDocIdDH,
  updateSelectedRoute,
  updateInstructionID,
  clearDocumentHistory,
} from './slices/documentHistorySlice';

import infiniteLoop from '../../../assets/img/animated-icons/infinite-loop.json';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { updateBackNavUrl } from '../../common/slices/documentViewSlice';
import {
  getInstructionList,
  getOBLDetails,
} from '../dashboard/actions/instruction';
import {
  getInstructionStatus,
  getPaymentStatus,
  getReleaseStatus,
  toggleDeliveryOrderEntry,
} from '../dashboard/slices/dashboardSlice';
import { toggleCarrierHaulage } from '../../common/components/OblDetailSlice';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import { toast } from 'react-toastify';
import { useInstDashName, useInvDashName } from '../../../hooks/useDashName';
import { useLoading } from '../../../middleware/loadingMiddleware';
import useBlCountry from '../../../hooks/useBlCountry';
import useDataCountry from '../../../hooks/useDataCountry';

const DocumentHistory = () => {
  const { t } = useTranslation();
  const dashboardData = useSelector((state: RootState) => state.dashboard.data);
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);
  const data = useSelector((state: RootState) => state.documentHistory.data);
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const dashboard = (() => {
    if (
      matchPath(`${APP_PATH_PREFIX}/instruction/:blnum`, location.pathname) ||
      location.pathname.includes('instruction/bl')
    )
      return `${APP_PATH_PREFIX}/instruction`;
    if (
      matchPath(`${APP_PATH_PREFIX}/payment/:blnum`, location.pathname) ||
      location.pathname.includes('payment/bl')
    )
      return `${APP_PATH_PREFIX}/payment`;
    return `${APP_PATH_PREFIX}`;
  })();
  const { blNum } = useParams();

  const datatableState = useSelector(
    (state: RootState) => state.dashboard.currentGridName
  );

  const selectedEventId = useSelector(
    (state: RootState) => state.documentHistory.selectedEventId
  );

  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );

  /*   const dataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  ); */
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const isDocumentHistoryLoading = useSelector(
    (state: RootState) => state.documentHistory.isDocumentHistoryLoading
  );

  const historyLoading = useLoading(['instruction/getInstruction'])

  const isOblDetailsLoading = useSelector(
    (state: RootState) => state.oblDetails.isOblDetailsLoading
  );

  const showDHColumnSettings = useSelector(
    (state: RootState) =>
      state.documentHistory.showDocumentHistoryColumnSettings
  );

  const instructionTypes = useSelector(
    (state: RootState) => state.documentHistory.instructionTypes
  );
  const instructionIdList = instructionTypes.map((option: any) => {
    return option.value.toString();
  });
  const blnumberdata = dashboardData?.list?.filter(
    (item: any) => item.id == AESDecrypt(blNum)
  );

  const [blCountry,_] = useBlCountry("new", "import",null)
  const dataCountry = useDataCountry()

  const instDashboardName = useInstDashName()
  const invDashboardName = useInvDashName()

  var releaseStatus: any;
  var paymentStatus: any;
  var instructionStatus: any;
  useEffect(() => {
    if (blnumberdata?.[0]?.id && selectedLinerId)
      dispatch(
        getOBLDetails({
          blNumber: blnumberdata[0]?.id,
          selectedLinerId:
            profileDetails.partnerType === 'liner'
              ? profileDetails?.partnerId
              : selectedLinerId,
        })
      );
  }, [blNum, selectedLinerId]);

  useEffect(() => {
    if (oblForm.haulage === 'Carrier Haulage') {
      dispatch(toggleCarrierHaulage(false));
    } else {
      dispatch(toggleCarrierHaulage(true));
    }
  }, [oblForm.haulage]);

  useEffect(() => {
    if ([`${APP_PATH_PREFIX}/bl/:blNum`, `${APP_PATH_PREFIX}/payment/:blNum`, `${APP_PATH_PREFIX}/instruction/:blNum`].find((i) => matchPath(i, location.pathname))) {
      dispatch(updateSelectedEventIdDH(null))
    }
  },[location.pathname])

  const timeoutRef = useRef<any>()

  useEffect(() => {
    // this gymnastics with timeout is to push the call back by one render
    // to stop extra event API calls when country changes (one with previous country value and one with new)
    if(((!blCountry || blCountry == '') 
      && (profileDetails.partnerType != "liner" || !dataCountry)) && timeoutRef.current){
      dispatch(clearDocumentHistory())
      clearTimeout(timeoutRef.current)
    }
    else timeoutRef.current = setTimeout(()=>{
    if ((profileDetails.partnerType == "liner" && dataCountry) || (
      blNum && blCountry && blCountry != '')) {
      dispatch(
        getInstructionList({
          blNum: AESDecrypt(blNum),
          country: dataCountry
        })
      );
    }
  }, 0)
  }, [blCountry, blNum, dataCountry, profileDetails.partnerType]);

  useEffect(() => {
    statusBasedOnEvent();
    var val = dashboardData.list.filter((i: any) => i.number === AESDecrypt(blNum));
    val.map((i: any) => (paymentStatus = i.paymentStatus));

    if (paymentStatus !== undefined) {
      paymentStatus = JSON.stringify(paymentStatus).replace(/[{""}]/g, '');
      dispatch(getPaymentStatus(paymentStatus));
    }
    val.map((item: any) => (releaseStatus = item.releaseStatus));

    if (releaseStatus != undefined) {
      releaseStatus = JSON.stringify(releaseStatus).replace(/[{""}]/g, '');

      dispatch(getReleaseStatus(releaseStatus));
    }

    val.map((item: any) => (instructionStatus = item.instructionStatus));

    if (instructionStatus !== undefined) {
      instructionStatus = JSON.stringify(instructionStatus).replace(
        /[{""}]/g,
        ''
      );
      dispatch(getInstructionStatus(instructionStatus));
    }
  }, [blNum]);

  const statusBasedOnEvent = () => {
    let newData = data.filter((item: any) =>
      item.event === 'Rejected' && item.document === 'Delivery Order Entry'
        ? dispatch(toggleDeliveryOrderEntry(true))
        : dispatch(toggleDeliveryOrderEntry(false))
    );

    return newData;
  };

  const columns: IDataTableColumn[] = [
    {
      accessor: 'blEventId',
      Header: 'ID',
    },
    {
      accessor: 'blId',
      Header: 'BL ID',
    },
    {
      accessor: 'docId',
      Header: 'Doc Id',
    },
    {
      accessor: 'document',
      Header: 'resource_1:document',
      minWidth: 180,
      width: 190,
      maxWidth: 200,
      columnFilterType: 'search',
      Cell: (rowData: any) => {
        const {
          instructionId,
          document,
          blEventId,
          documentNumber,
          blId,
          docId,
          invAccess,
          docCategory
        } = rowData.row.original;

        if (blEventId === selectedEventId) {
          if (
            !(
              (instructionId?.toLowerCase() === '780' ||
                instructionId?.toLowerCase() === '102' ||
                instructionId?.toLowerCase() === '103' ||
                instructionId?.toLowerCase() === '781' ||
                //  instructionId?.toLowerCase() === '781' ||
                instructionId?.toLowerCase() === '207' ||
                instructionId?.toLowerCase() === '381') &&
              documentNumber
            )
          ) {
            return <span className='fw-bold'>{document}</span>;
          }
        }

        return (
          <span
            onClick={() => {
              if (instructionId?.toLowerCase() === '880') {
                return
              }
              dispatch(updateSelectedEventIdDH(blEventId));
              dispatch(updateSelectedDocIdDH(docId));
              if (datatableState == instDashboardName) {
                dispatch(updateSelectedRoute('instruction'));
              } else if (datatableState == invDashboardName) {
                dispatch(updateSelectedRoute('payment'));
              } else {
                dispatch(updateSelectedRoute(location.pathname.split('/')[1]));
              }

              dispatch(
                updateBackNavUrl(
                  matchPath(`${APP_PATH_PREFIX}/instruction/:blNum`, location.pathname)
                    ? `/instruction/${encodeURIComponent(blNum || '')}`
                    : `/bl/${encodeURIComponent(blNum || '')}`
                )
              );
              if (
                (instructionId?.toLowerCase() === '780' ||
                  instructionId?.toLowerCase() === '102' ||
                  instructionId?.toLowerCase() === '103' ||
                  instructionId?.toLowerCase() === '781' ||
                  instructionId?.toLowerCase() === '381' ||
                  instructionId?.toLowerCase() === '899' ||
                  docCategory == 'Invoice') &&
                documentNumber
              ) {
                if (
                  invAccess?.toUpperCase() === 'Y' ||
                  instructionId?.toLowerCase() === '781'
                ) {
                  window.open(
                    `${process.env.REACT_APP_CLIENT_APP_HOST}/api/edo-rest-v2/v2/invoice/openInvoicePDF?invNo=${documentNumber}&partnerId=${profileDetails.partnerId}`,
                    '_blank'
                  );
                } else {
                  toast.dark(
                    t('resource_message:frieghtInvoiceContactConsignee')
                  );
                }
              } else if (instructionId?.toLowerCase() == '206' && docId) {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/edo-rest-v2/v2/common/openDocument?id=${docId}&moduleTp=DO_RELEASE_PDF&linerId=${profileDetails.partnerType === 'liner'
                    ? profileDetails?.partnerId
                    : selectedLinerId
                  }`,
                  '_blank'
                );
              } else if (instructionId?.toLowerCase() == '207' && docId) {
                window.open(
                  `${process.env.REACT_APP_CLIENT_APP_HOST
                  }/api/edo-rest-v2/v2/common/openDocument?id=${docId}&moduleTp=PDF_RELEASE_RECEIPTS&linerId=${profileDetails.partnerType === 'liner'
                    ? profileDetails?.partnerId
                    : selectedLinerId
                  }`,
                  '_blank'
                );
              } else if (instructionId?.toLowerCase() == '206' && docId) {
                navigate(
                  `${dashboard}/bl/${encodeURIComponent(
                    blNum || ''
                  )}/payment/${AESEncrypt(docId)}${window.location.search || ''
                  }`
                );
              } else if (
                instructionId?.toLowerCase() == '805' ||
                instructionId?.toLowerCase() == '804' ||
                instructionId?.toLowerCase() == '801' ||
                instructionId?.toLowerCase() == '802' ||
                instructionId?.toLowerCase() == '806' ||
                instructionId?.toLowerCase() == '808' ||
                instructionId?.toLowerCase() == '810' ||
                instructionId?.toLowerCase() == '811' ||
                instructionId?.toLowerCase() == '812' ||
                instructionId?.toLowerCase() == '813' ||
                instructionId?.toLowerCase() == '814' ||
                instructionId?.toLowerCase() == '815' ||
                instructionId?.toLowerCase() == '816'
              ) {
                dispatch(updateInstructionID(instructionId));
                navigate(
                  `${dashboard}/bl/${encodeURIComponent(
                    blNum || ''
                  )}/${AESEncrypt(instructionId)}/${AESEncrypt(docId)}${location?.search || ''
                  }`
                );
              } else {
                dispatch(updateInstructionID(instructionId));
                navigate(
                  `${dashboard}/bl/${encodeURIComponent(
                    blNum || ''
                  )}/${AESEncrypt(instructionId)}${window.location.search || ''
                  }${['802', 'invoice changes', '801'].includes(
                    instructionId?.trim()?.toLowerCase()
                  )
                    ? `/${blId}`
                    : ''
                  }`
                );
              }
            }}
            className={`${(instructionId?.toLowerCase() === '880') ||
              (document === 'Payment' &&
                rowData.row.original.docStatus === 'N' &&
                rowData.row.original.event === 'Submitted') ||
              (document === 'Payment' &&
                rowData.row.original.event === 'Cancelled') ||
              (document === 'Payment' && rowData.row.original.docStatus === 'C')
                ? ''
                : 'text-primary cursor-pointer'
            }   ${'' === document ? '' : ''}`}
            style={
              (instructionId?.toLowerCase() === '880') ||
                (document === 'Payment' &&
                  rowData.row.original.docStatus === 'N' &&
                  rowData.row.original.event === 'Submitted') ||
                (document === 'Payment' &&
                  rowData.row.original.event === 'Cancelled') ||
                (document === 'Payment' && rowData.row.original.docStatus === 'C')
                ? { pointerEvents: 'none' }
                : {}
            }>
            {document}
          </span>
        );
      },
    },
    {
      accessor: 'documentNumber',
      Header: t('resource_2:documentNo'),
      minWidth: 180,
      width: 190,
      maxWidth: 200,
    },
    {
      accessor: 'event',
      Header: 'resource_1:event',
      minWidth: 130,
      width: 140,
      maxWidth: 150,
      columnFilterType: 'search',
    },
    {
      accessor: 'status',
      Header: 'resource_1:status',
      minWidth: 100,
      width: 110,
      maxWidth: 120,
      Cell: (rowData: any) => {
        const { status } = rowData.row.original;
        //  const newDataval = statusBasedOnEvent();

        //     for (let i = 0; i < newDataval.length; i++) {

        if (rowData.row.original.docStatus === 'Y') {
          //  if (rowData.row.original.event === 'Received') {
          return (
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='text-primary ps-1'
              size='lg'
            />
          );
          // }if
        } else if (rowData.row.original.docStatus === 'N') {
          return (
            <FontAwesomeIcon
              icon={faTimesCircle}
              className='text-danger ps-1'
              size='lg'
            />
          );
        }
        // }
        //return '';
      },
      //columnFilterType: 'search',
    },

    {
      accessor: 'time',
      Header: 'resource_2:time',
      minWidth: 150,
      width: 160,
      maxWidth: 180,
      columnFilterType: 'date-range',
      Cell: (rowData: any) => {
        const { time } = rowData.row.original;

        // return new Date(time).toLocaleString();
        return new Date(time + ' UTC').toLocaleString();
      },
    },
    /*  {
      accessor: 'ccy',
      Header: 'resource_1:ccy',

      columnFilterType: 'search',
    },
    {
      accessor: 'amount',
      Header: 'resource_1:amount',
      Cell: (rowData: any) => {
        return rowData?.row?.original?.amount;
      },

      // columnFilterType: 'search',
      columnNumberFilter: {
        min: 10,
        max: 1000,
      },
    }, */
    {
      accessor: 'user',
      Header: 'resource_1:user',
      minWidth: 150,
      width: 180,
      maxWidth: 220,
      Cell: (rowData: any) => {
        const instructionStatusList = ['Completed', 'Rejected'
          , 'Approved by L4', 'Approved by L3', 'Approved by L2', 'Approved by L1'];

        const user =
          [...instructionIdList, '811', '812']?.includes(rowData?.row?.original?.instructionId) &&
            instructionStatusList?.includes(rowData?.row?.original?.event) && // checks status
            profileDetails?.partnerType != 'liner'
            ? ''
            : rowData?.row?.original?.user;
        return <span>{user}</span>;
      },
    },
  ];

  const _onHideDHColumnSettings = () => {
    dispatch(toggleDHColumnSettings(false));
  };

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const localFilterState = useSelector(
    (state: RootState) => state.dataTable.localFilterState
  );

  useEffect(() => { }, [filterState, localFilterState]);

  return isDocumentHistoryLoading || isOblDetailsLoading ? (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <Lottie
        animationData={infiniteLoop}
        loop={true}
        style={{ height: '80px', width: '80px' }}
      />
    </div>
  ) : (
    <div className='w-100 h-100 overflow-auto border-right d-flex flex-column'>
      <DataTable
        data={data}
        columns={columns}
        dataLoading={historyLoading}
        sortable={true}
        selection={false}
        searchable={false}
        pagination={true}
        tableMinHeight={400}
        perPage={1000}
        gridName='DOCUMENT_HISTORY'
        showColumnFilter={true}
        showExport={false}
        showColumnSettings={showDHColumnSettings}
        onHide={_onHideDHColumnSettings}
        columnSettingsPersistKey='@odex/document-history/v1'
        showPagination={false}
        defaultHiddenCols={['blEventId', 'blId', 'docId']}
        staticHiddenCols={['blEventId', 'blId', 'docId']}
        localFilter={true}
        uniqueFilterKey='filterKey'
        addTbodyBreak
      />
    </div>
  );
};

export default DocumentHistory;
