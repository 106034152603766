import { useContext, useEffect, useMemo, useState } from "react";
import { Navbar, Nav, FormControl, ButtonGroup, Button  } from "react-bootstrap";
import classNames from "classnames";
import AppContext from "../../../context/Context";
import Logo from "../../common/Logo";
import { navbarBreakPoint, topNavbarBreakpoint } from "../../../config";
import TopNavRightSideNavItem from "./TopNavRightSideNavItem";
import { useLocation, matchPath } from "react-router";
import { Form, InputGroup, Dropdown } from "react-bootstrap";
import queryString from "query-string";
import { getCodeList } from "../../../app/features/blRequestInstruction/components/actions/Instruction";
import { useDispatch } from "react-redux";
import { getDashboardData } from "../../../app/features/dashboard/actions/dashboard";
import { getLanguageList } from "../../../app/features/dashboard/actions/instruction";

import { useSelector } from "react-redux";
import {
  updateBlTopSearchState,
  updateInvoiceTopSearchState,
  updateSearchInputValueNT,
  updateTopSearchFilter,
  updatePrevSearchInput,
  updateInstructionTopSearchState,
  updateUserTopSearchState,
  updateLinerId,
  updateReportTopSearchState,
  updatePaymentTopSearchState,
  updateMappingTopSearchState,
  updateLanguage,
} from "../../../app/common/slices/navbarTop";
import { getSearchFiltter } from "../../../app/features/blPayment/actions/payments";
import { updateLinerDataCountry } from "../../../app/common/slices/userDetails";
import i18next from "i18next";
import {
  AESEncrypt,
  AESEncryptforDashboard,
  Base64Encode,
} from "../../../encrypt-util";
import {
  updateCurrentPageBlDashboard,
  updateCurrentPageInvoiceDashboard,
  updateCurrentPageInstructionDashboard,
  updateCurrentPageUserDashboard,
  updateCurrentPagePayment,
  updateCurrentPageEmailDashboard,
  updateCurrentPageReportDashboard,
  clearDashboards,
  clearMappingDashboard,
} from "../../../app/features/dashboard/slices/dashboardSlice";
import { updateCookie } from "../../../app/common/actions/userDetails";
import useReportGridName from "../../../app/features/reportDashboard/useReportGridName";
import { updateDashbaordRefresh } from "../../../app/common/components/DataTable/slices/DataTable";
import { fetchLinerNameByCountry } from "../../../app/features/registration/actions/Registration";
import {
  updateSelectedAmount,
  updateSelectedAmountPayment,
  updateSelectedRowsInvoice,
} from "../../../app/features/multiBlPayment/slices/multiBlPaymentSlice";
import { setMappingTopSearchFilter } from "../../../app/features/customerMappingDashboard/MappingSlice";
import {
  useBlDashName,
  useInstDashName,
  useInvDashName,
} from "../../../hooks/useDashName";
import { useTranslation } from "react-i18next";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const NavbarTop = () => {
  const dispatch = useDispatch();

  const searchInputValue = useSelector(
    (state) => state.navbarTop.searchInputValue
  );
  const { reportGridName } = useReportGridName();
  const prevSearchInput = useSelector(
    (state) => state.navbarTop.prevSearchInput
  );
  const {t} = useTranslation()
  const generateHeaderLabel = (s) => {
    let count = 1;
    for (let i in s) {
        if (s.charAt(i) === s.charAt(i).toUpperCase()) {
            count++;
        }
    }
    return count ? `resource_${count}:${s}` : s;
};
  const selectedDashbaordName = useSelector(
    (state) => state.navbarTop.selectedDashbaordName
  );
  const location = useLocation();
  const selectedTopSearchFilter = useSelector(
    (state) => state.navbarTop.selectedTopSearchFilter
  );
  const [prevPathName, setPrevPathName] = useState("/home");
  //const [language, setLanguage] = useState("")
  const linerCountryList = useSelector(
    (state) => state.userProfile.countryList
  );
  const grids = useSelector((state) => state.dataTable.grids);
  const invoiceGrids = useSelector((state) => state.dataTable.invoiceGrids);
  const instructionsGrids = useSelector(
    (state) => state.dataTable.instructionsGrids
  );
  const userGrids = useSelector((state) => state.dataTable.userGrids);
  const paymentGrids = useSelector((state) => state.dataTable.paymentGrids);
  const receiptGrids = useSelector((state) => state.dataTable.receiptGrids);
  const emailGrids = useSelector((state) => state.dataTable.emailGrids);
  const getInGetOutGrids = useSelector(
    (state) => state.dataTable.getInGetOutGrids
  );
  const mappingGrids = useSelector((state) => state.dataTable.mappingGrids);
  const reportGrids = useSelector((state) => state.dataTable.reportGrids);
  const languageList = useSelector((state) => state.navbarTop.languageList);
  
  const getGirdList = (gridName, gridWhere, sorts) => {
    dispatch(
      getDashboardData({
        gridName: gridName,
        payload: {
          fields: [],
          filters: {},
          size: 20,
          sorts: [`${sorts}`],
          page: 1,
        },
        dataCountry: dataCountry,

        partnerId:
          profileDetails.partnerType === "customer"
            ? profileDetails.partnerId
            : null,
        parameter:
          selectedTopSearchFilter?.where !== ""
            ? {
                searchParameter: searchInputValue.trim(),
                searchWhere: selectedTopSearchFilter?.where,
              }
            : {},
        where: AESEncrypt(gridWhere) || {},
      })
    );
  };

  const getSearchFilterList = (values) => {
    const searchFilter = values?.[0]?.searchFilter;
    let searchFilterList = [];

    if (searchFilter) {
      searchFilterList = JSON.parse(searchFilter);
    }
    return searchFilterList;
  };

  const instDashboardName = useInstDashName();
  const invDashboardName = useInvDashName();
  const blDashboardName = useBlDashName();

  const searchFilterList = getSearchFilterList(
    selectedDashbaordName === "BL_DASHBOARD_CUSTOMER"
      ? grids
      : selectedDashbaordName === "BL_DASHBOARD_LINER"
      ? grids
      : selectedDashbaordName === "INVOICE_DASHBOARD_CUSTOMER"
      ? invoiceGrids
      : selectedDashbaordName === "INVOICE_DASHBOARD_LINER"
      ? invoiceGrids
      : selectedDashbaordName === "INSTRUCTION_DASHBOARD_CUSTOMER"
      ? instructionsGrids
      : selectedDashbaordName === "INSTRUCTION_DASHBOARD_LINER"
      ? instructionsGrids
      : selectedDashbaordName === "USER_DASHBOARD"
      ? userGrids
      : selectedDashbaordName === reportGridName
      ? reportGrids
      : selectedDashbaordName === "PAYMENT_DASHBOARD"
      ? paymentGrids
      : selectedDashbaordName === "PAYMENT_RECEIPT"
      ? receiptGrids
      : // : selectedDashbaordName === 'EMAIL_TEMPLATE_DASHBOARD'
      selectedDashbaordName === "EMAIL_LOGS"
      ? emailGrids
      : selectedDashbaordName === "GATE_DASHBOARD_CUSTOMER"
      ? getInGetOutGrids
      : selectedDashbaordName === "CUSTOMER_DASHBOARD"
      ? mappingGrids
      : []
  );

  const param =
    '{"' +
    selectedTopSearchFilter?.id +
    '": ["' +
    searchInputValue.trim() +
    '"]}';

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const getGridName = () => {
    if (location.pathname === `${APP_PATH_PREFIX}/home`) {
      return blDashboardName;
    } else if (location.pathname === `${APP_PATH_PREFIX}/payment`) {
      return invDashboardName;
    } else if (location.pathname === `${APP_PATH_PREFIX}/instruction`) {
      return instDashboardName;
    } else if (location.pathname === `${APP_PATH_PREFIX}/report`) {
      return reportGridName;
    } else if (location.pathname === `${APP_PATH_PREFIX}/paymentDashboard`) {
      return "PAYMENT_DASHBOARD";
    } else if (location.pathname === `${APP_PATH_PREFIX}/receipt`) {
      return "PAYMENT_RECEIPT";
    } else if (location.pathname === `${APP_PATH_PREFIX}/emailDashboard`) {
      // return 'EMAIL_TEMPLATE_DASHBOARD';
      return "EMAIL_LOGS";
    } else if (location.pathname === `${APP_PATH_PREFIX}/gateinout`) {
      return "GATE_DASHBOARD_CUSTOMER";
    } else if (location.pathname === `${APP_PATH_PREFIX}/mappingdashboard`) {
      return "CUSTOMER_DASHBOARD";
    } else return null;
  };

  const [linerData, setLinerData] = useState([]);

  const getSortOrder = () => {
    if (location.pathname === `${APP_PATH_PREFIX}/home`) return "lastupdateddate desc";
    if (location.pathname === `${APP_PATH_PREFIX}/payment`) return "blid asc";
    if (location.pathname === `${APP_PATH_PREFIX}/instruction`) return "timestamp desc";
    if (location.pathname === `${APP_PATH_PREFIX}/users`) return "user_id desc";
    if (location.pathname === `${APP_PATH_PREFIX}/report`) return "bl_no desc";
    if (location.pathname === `${APP_PATH_PREFIX}/paymentDashboard`) return "pmtmode desc";
    if (location.pathname === `${APP_PATH_PREFIX}/receipt`) return "receipt_id desc";
    if (location.pathname === `${APP_PATH_PREFIX}/emailDashboard`) return "email_logs_id desc";
    if (location.pathname === `${APP_PATH_PREFIX}/gateinout`) return "bl_id desc";
    if (location.pathname === `${APP_PATH_PREFIX}/mappingdashboard`) return "id desc";

    return null;
  };

  const getGridWhere = (
    grids,
    invoiceGrids,
    instructionsGrids,
    userGrids,
    reportGrids,
    paymentGrids,
    getInGetOutGrids,
    mappingGrids
  ) => {
    if (location.pathname === `${APP_PATH_PREFIX}/home`) return grids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/payment`) return invoiceGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/instruction`)
      return instructionsGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/users`) return userGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/report`) return reportGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/paymentDashboard`)
      return paymentGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/receipt`) return receiptGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/emailDashboard`)
      return emailGrids?.[0]?.whereClause;
    if (location.pathname === `${APP_PATH_PREFIX}/gateinout`)
      return getInGetOutGrids?.[0]?.whereClause;
    if (location.pathname == `${APP_PATH_PREFIX}/mappingdashboard`)
      return mappingGrids?.[0]?.whereClause;
    // Add other paths if necessary
    return null;
  };
  const gridName = getGridName();

  const gridWhere = getGridWhere(
    grids,
    invoiceGrids,
    instructionsGrids,
    userGrids,
    reportGrids,
    paymentGrids,
    receiptGrids,
    emailGrids,
    getInGetOutGrids,
    mappingGrids
  );
  const gridSort = getSortOrder();

  useEffect(() => {
    const isPathValid =
      location.pathname === `${APP_PATH_PREFIX}/home` ||
      location.pathname === `${APP_PATH_PREFIX}/payment` ||
      location.pathname === `${APP_PATH_PREFIX}/instruction` ||
      location.pathname === `${APP_PATH_PREFIX}/report` ||
      location.pathname === `${APP_PATH_PREFIX}/paymentDashboard` ||
      location.pathname === `${APP_PATH_PREFIX}/receipt` ||
      location.pathname === `${APP_PATH_PREFIX}/emailDashboard` ||
      location.pathname === `${APP_PATH_PREFIX}/gateinout` ||
      location.pathname === `${APP_PATH_PREFIX}/mappingdashboard`;
    const isPrevPathValid =
      prevPathName === `${APP_PATH_PREFIX}/home` ||
      prevPathName === `${APP_PATH_PREFIX}/payment` ||
      prevPathName === `${APP_PATH_PREFIX}/instruction` ||
      prevPathName === `${APP_PATH_PREFIX}/report` ||
      prevPathName === `${APP_PATH_PREFIX}/paymentDashboard` ||
      prevPathName === `${APP_PATH_PREFIX}/receipt` ||
      prevPathName === `${APP_PATH_PREFIX}/emailDashboard` ||
      prevPathName === `${APP_PATH_PREFIX}/gateinout` ||
      prevPathName === `${APP_PATH_PREFIX}/mappingdashboard`;

    if (isPathValid && isPrevPathValid) {
      if (gridName /* && gridWhere && gridSort */) {
        if (prevSearchInput) getGirdList(gridName, gridWhere, gridSort);
        dispatch(updateBlTopSearchState({}));
        dispatch(updateInvoiceTopSearchState({}));
        dispatch(updateInstructionTopSearchState({}));
        dispatch(updateUserTopSearchState({}));
        dispatch(updateSearchInputValueNT(""));
      }
    }
    dispatch(updatePrevSearchInput(searchInputValue.trim()));
    setPrevPathName(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    dispatch(updateTopSearchFilter(searchFilterList[0]));
  }, [JSON.stringify(searchFilterList)]);

  const handleFilterChange = (e) => {
    const selectedId = e.target.value;
    const selectedFilter = searchFilterList.find(
      (option) => option.id === selectedId
    );

    if (selectedFilter) {
      dispatch(updateTopSearchFilter(selectedFilter));
      dispatch(setMappingTopSearchFilter(""));
    }
  };

  const _updateSearchInputValue = (e) => {
    if (!e.target.value) {
      if (location.pathname === `${APP_PATH_PREFIX}/home`) {
        dispatch(updateBlTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/payment`) {
        dispatch(updateInvoiceTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/instruction`) {
        dispatch(updateInstructionTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/users`) {
        dispatch(updateUserTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/report`) {
        dispatch(updateReportTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/paymentDashboard`) {
        dispatch(updatePaymentTopSearchState({}));
      } else if (location.pathname === `${APP_PATH_PREFIX}/mappingdashboard`) {
        dispatch(setMappingTopSearchFilter(""));
      }
    }
    if (
      searchInputValue.trim() &&
      !e.currentTarget.value &&
      gridName /*  && gridWhere */
    ) {
      dispatch(updateDashbaordRefresh(true));
    }
    dispatch(updateSearchInputValueNT(e.target.value));
  };

  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig,
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes("chat");
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const [showDropShadow, setShowDropShadow] = useState(false);

  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;

  const handleBurgerMenu = () => {
    navbarPosition === "top" && setConfig("navbarCollapsed", !navbarCollapsed);

    (navbarPosition === "vertical" || navbarPosition === "combo") &&
      setConfig("showBurgerMenu", !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  const partnerCountry = profileDetails.partnerCountry;
  const linerDataCountry = useSelector(
    (state) => state.userProfile.linerDataCountry
  );
  const linerId = useSelector((state) => state.navbarTop.linerId);
  const language = useSelector((state)=> state.navbarTop.language);

 // console.log("language",language)

  // useEffect(() => {
  //   dispatch(updateLinerId('1'));
  // }, []);

  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;
    
  useEffect(() => {
    window.addEventListener("scroll", setDropShadow);
    return () => window.removeEventListener("scroll", setDropShadow);
  }, []);


  useEffect(()=>{
      if(dataCountry&&linerId){
        dispatch(getLanguageList({dataCountry, linerId})).unwrap().then((list)=>{
            dispatch(updateLanguage(list?.[0]?.text??'EN'))
        })
      }
   },[dataCountry, linerId])

  useEffect(() => {
    const localLiner = window.localStorage.getItem("liner_id");
    if (
      localLiner &&
      linerData.length > 0 &&
      !linerData.find((i) => i && i.value == localLiner)
    ) {
      window.localStorage.setItem("liner_id", linerData[0].value);
      dispatch(updateLinerId(linerData[0].value));
    }
  }, [linerData]);

  useEffect(() => {
    if (linerCountryList && linerCountryList.length == 1 && !storedCountry) {
      dispatch(
        updateLinerDataCountry(
          storedCountry ||
            linerCountryList[0]?.countryCode ||
            profileDetails.partnerCountry
        )
      );
      const userSelectedCountry = [
        {
          loginId: profileDetails.email,
          country:
            storedCountry ||
            linerCountryList[0]?.countryCode ||
            profileDetails.partnerCountry ||
            "",
        },
      ];
      window.localStorage.setItem(
        "@user-selected-datacountry",
        JSON.stringify(userSelectedCountry)
      );
      i18next.changeLanguage(
        `${storedCountry ||
          linerCountryList[0]?.countryCode ||
          profileDetails.partnerCountry}_${language}`
      );
    }
  }, [linerCountryList,language]);
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      dispatch(updateSearchInputValueNT(searchInputValue.trim()));
      if (searchInputValue.trim().length > 0) {
        if (matchPath(location.pathname, `${APP_PATH_PREFIX}/home`)) {
          dispatch(updateCurrentPageBlDashboard(1));
          dispatch(updateBlTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: blDashboardName,
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["lastupdateddate desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(grids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/report`)) {
          dispatch(updateCurrentPageReportDashboard(1));
          dispatch(updateReportTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              reportGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: reportGridName,
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["bl_no desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(reportGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/payment`)) {
          dispatch(updateCurrentPageInvoiceDashboard(1));
          dispatch(updateInvoiceTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              invoiceGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: invDashboardName,
                payload: {
                  //fields: SelectedViewFields?.fields || [],
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["blid asc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(invoiceGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/instruction`)) {
          dispatch(updateCurrentPageInstructionDashboard(1));
          dispatch(updateInstructionTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              instructionsGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: instDashboardName,
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["timestamp desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(instructionsGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/users`)) {
          dispatch(updateCurrentPageUserDashboard(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              userGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "USER_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["user_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(userGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/paymentDashboard`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              paymentGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "PAYMENT_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["pmtmode desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(paymentGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/mappingdashboard`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          dispatch(setMappingTopSearchFilter(searchInputValue.trim()));
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/receipt`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              receiptGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "PAYMENT_RECEIPT",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["receipt_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(receiptGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/emailDashboard`)) {
          // dispatch(updateCurrentPagePayment(1));
          dispatch(updateCurrentPageEmailDashboard(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              emailGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                // gridName: 'EMAIL_TEMPLATE_DASHBOARD',
                gridName: "EMAIL_LOGS",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["email_logs_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(emailGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/gateinout`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));

          dispatch(
            getDashboardData({
              gridName: "GATE_DASHBOARD_CUSTOMER",
              payload: {
                fields: [],
                filters:
                  selectedTopSearchFilter?.where == "" ? JSON.parse(param) : {},
                size: 20,
                sorts: ["bl_id desc"],
                page: 1,
              },
              dataCountry: dataCountry,
              parameter:
                selectedTopSearchFilter?.where !== ""
                  ? {
                      searchParameter: searchInputValue.trim(),
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
              where: AESEncrypt(getInGetOutGrids?.[0]?.whereClause) || {},
              partnerId: profileDetails.partnerId,
            })
          );
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/receipt`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              receiptGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "PAYMENT_RECEIPT",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["receipt_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(receiptGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/emailDashboard`)) {
          // dispatch(updateCurrentPagePayment(1));
          dispatch(updateCurrentPageEmailDashboard(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              emailGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                // gridName: 'EMAIL_TEMPLATE_DASHBOARD',
                gridName: "EMAIL_LOGS",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["email_logs_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(emailGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        } else if (matchPath(location.pathname, `${APP_PATH_PREFIX}/gateinout`)) {
          dispatch(updateCurrentPagePayment(1));
          dispatch(updateUserTopSearchState(JSON.parse(param)));
          if (
            (profileDetails?.partnerType === "customer" &&
              profileDetails?.partnerId &&
              getInGetOutGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            dispatch(
              getDashboardData({
                gridName: "GET_IN_OUT_DASHBOARD",
                payload: {
                  fields: [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? JSON.parse(param)
                      : {},
                  size: 20,
                  sorts: ["bl_id desc"],
                  page: 1,
                },
                dataCountry: dataCountry,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue.trim(),
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
                where: AESEncrypt(getInGetOutGrids?.[0]?.whereClause) || {},
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
              })
            );
          }
        }
      } else {
        dispatch(setMappingTopSearchFilter(""));
        dispatch(updateDashbaordRefresh(true));
      }
    }
  };
  useEffect(() => {
    if (
      profileDetails.partnerType !== "liner" &&
      profileDetails?.partnerCountry
    ) {
      dispatch(
        fetchLinerNameByCountry({
          country_code: profileDetails?.partnerCountry,
        })
      ).then((returnedData) => {
        dispatch(
          updateCookie({
            key: Base64Encode(AESEncryptforDashboard("liner_id")),
            value: Base64Encode(
              AESEncryptforDashboard(
                window.localStorage.getItem("liner_id")
                  ? window.localStorage.getItem("liner_id")
                  : returnedData?.payload[0]?.value.toString()
              )
            ),
          })
        )
          .unwrap()
          .then(() => {
            setLinerData(returnedData?.payload);
            dispatch(
              updateLinerId(
                window.localStorage.getItem("liner_id")
                  ? window.localStorage.getItem("liner_id")
                  : returnedData?.payload[0]?.value.toString()
              )
            );
          });
      });
    } else {
      dispatch(
        updateCookie({
          key: Base64Encode(AESEncryptforDashboard("liner_id")),
          value: Base64Encode(
            AESEncryptforDashboard(
              window.localStorage.getItem("liner_id")
                ? window.localStorage.getItem("liner_id")
                : profileDetails?.partnerId?.toString()
            )
          ),
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            updateLinerId(
              window.localStorage.getItem("liner_id")
                ? window.localStorage.getItem("liner_id")
                : profileDetails?.partnerId
            )
          );
        });
    }
  }, [profileDetails?.partnerCountry]);
  return (
    <Navbar
      className={classNames("navbar-glass  fs--1 navbar-top sticky-kit", {
        "navbar-glass-shadow": showDropShadow && !isChat,
      })}
      expand={
        navbarPosition === "top" || navbarPosition === "combo"
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames("toggle-icon-wrapper me-md-3 me-2", {
          "d-lg-none": navbarPosition === "top",
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === "vertical" || navbarPosition === "combo",
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={100} id="topLogo" />

      <Nav navbar className={`flex-row align-items-center`} as="ul">
        {!(profileDetails?.partnerType === "liner") ? (
          <Nav.Item
            as="li"
            className="flex-row align-items-center justify-content-start"
          >
            <InputGroup>
              <InputGroup.Text
                className="rounded-pill-l bg-100 fs--1"
                style={{ fontWeight: 450 }}
              >
                {t('resource_1:carrier')}
              </InputGroup.Text>
              {linerData.length > 1 ? (
                <Form.Select
                  aria-label="Carrier"
                  className="rounded-pill-r text-primary fs--1"
                  size="md"
                  value={linerId}
                  onChange={(e) => {
                    const value = e.target.value;
                    dispatch(
                      updateCookie({
                        key: Base64Encode(AESEncryptforDashboard("liner_id")),
                        value: Base64Encode(
                          AESEncryptforDashboard(e.target.value)
                        ),
                      })
                      //  window.location.reload()
                    )
                      .unwrap()
                      .then(() => {
                        setTimeout(() => dispatch(updateLinerId(value)), 1000);
                        localStorage.setItem(`liner_id`, value);
                        dispatch(clearDashboards());
                        dispatch(updateSelectedRowsInvoice([]));
                        dispatch(updateSelectedAmount([]));
                        // dispatch(updateSelectedAmountPayment([]));
                      })
                      .catch(() => {});
                  }}
                >
                  {linerData.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </Form.Select>
              ) : (
                <InputGroup.Text
                  className="rounded-pill-r text-primary fs--1"
                  style={{
                    fontWeight: 450,
                    backgroundColor: "rgb(249, 250, 253)",
                  }}
                >
                  {linerData.length === 1 && linerData[0].text}
                </InputGroup.Text>
              )}
            </InputGroup>
          </Nav.Item>
        ) : (
          <Nav.Item
            as="li"
            className="flex-row align-items-center justify-content-start"
          >
            {linerCountryList?.length > 1 ? (
              <InputGroup>
                <Form.Select
                  aria-label="Country"
                  className="rounded-pill text-primary fs-0"
                  size="md"
                  onChange={(e) => {
                    dispatch(updateLinerDataCountry(e.target.value));
                    dispatch(clearDashboards());
                    dispatch(
                      updateCookie({
                        key: Base64Encode(
                          AESEncryptforDashboard("partner_country")
                        ),
                        value: Base64Encode(
                          AESEncryptforDashboard(e.target.value)
                        ),
                      })
                    );
                    const userSelectedCountry = [
                      {
                        loginId: profileDetails.email,
                        country: e.target.value || "",
                      },
                    ];
                    window.localStorage.setItem(
                      "@user-selected-datacountry",
                      JSON.stringify(userSelectedCountry)
                    );
                    i18next.changeLanguage(`${e.target.value}_${language}`);
                  }}
                  value={dataCountry}
                >
                  {linerCountryList?.map((option) => (
                    <option
                      key={option.countryCode}
                      value={option?.countryCode}
                    >
                      {option?.countryName}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
            ) : (
              <></>
            )}
          </Nav.Item>
        )}

        <Nav.Item
          as="li"
          className="ps-4 flex-row align-items-center justify-content-start"
        >
          {searchFilterList?.length > 0 ? (
            <InputGroup className="row g-0">
              <div className="col-5">
                <Form.Select
                  aria-label="Carrier"
                  className="rounded-pill-l bg-100 fs--1 h-100"
                  onChange={handleFilterChange}
                  value={selectedTopSearchFilter?.id}
                >
                  {searchFilterList?.map((option) => (
                    <option
                      className="text-dark"
                      key={option.id}
                      value={option.id}
                    >
                      {t(generateHeaderLabel(option.id))}
                    </option>
                  ))}
                </Form.Select>
              </div>

              <div className="col-7">
                <FormControl
                  type="search"
                  className="rounded-pill-r text-primary fs-0 shadow-none"
                  placeholder={t("resource_1:searchPlaceHolder")}
                  onChange={_updateSearchInputValue}
                  onKeyDown={handleSearch}
                  value={searchInputValue}
                />
              </div>
            </InputGroup>
          ) : (
            <></>
          )}
        </Nav.Item>
      </Nav>

      <Nav.Item>
      {languageList.length > 1 && <ButtonGroup className=" ms-5" >
         {languageList.map((option, i) => (
                    <Button  
                      onClick={(e) => {
                                dispatch(updateLanguage(option.text))
                                }}
                                className={`btn btn-falcon-default fs--1 
                                  ${i==0?"rounded-pill-l":""}
                                  ${i==languageList.length -1?"rounded-pill-r":""}
                                  `}
                      style={language == option.text ?
                        {
                        background:'#f5822a',
                        color:'#fff',
                        border:'#ee6b07'
                        }:{}
                    }
                  
                    >
                     <FontAwesomeIcon
                     
                                 icon={faLanguage}
                                 className='text-facon ps-1'
                                 size='lg'
                               /> {option.text}
                    </Button>
                  ))}
        </ButtonGroup>}
      </Nav.Item>

      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
