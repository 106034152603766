import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { useBackNavigation } from '../../../hooks/BackNavigation';
import useDataCountry from '../../../hooks/useDataCountry';
import MappingUserList from './MappingUserList';
import { AppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAddCodeFilters, setCompanyDetails } from './MappingSlice';
import { getCompanyDetails, saveEditCompanyDetails } from './MappingActions';
import { useParams } from 'react-router-dom';
import CustomerCodeList from './MappingCustomerCodeList';
import { fetchLinerNameByCountry, getCountryList } from '../registration/actions/Registration';
import { count } from 'console';


const CustomerDetailsSlider = ({ disable = true }) => {
  const { t } = useTranslation();
  const { custId } = useParams()

  const onBack = useBackNavigation();
  const dataCountry = useDataCountry();
  const companyDetails = useSelector((state: RootState) => state.mappingDashboard.companyDetails)
  const dispatch = useDispatch<AppDispatch>();

  const countryOptions = useSelector(
    (state: RootState) => state.registration.countryOptions
  );
  const language = useSelector((state:RootState)=> state.navbarTop.language);

  useEffect(() => {
    i18next.changeLanguage(
      `${dataCountry}_${language}`
    );
    if (dataCountry)
      dispatch(fetchLinerNameByCountry({ country_code: dataCountry })).unwrap().then((data) => {
        if (data?.length > 0) {
          dispatch(setAddCodeFilters({ liner: data[0].value }))
        }
      }).catch((error) => {
        console.error(error);
      });

  }, [dataCountry,language]);
console.log(companyDetails?.country,'<<companyDetails?.country');
  useEffect(() => {
    if (custId) {
      dispatch(getCompanyDetails({ custId }))
    }
  }, [custId]);

  useEffect(() => {
    dispatch(getCountryList(1))
  }, []);

  return (
    <Card className='h-100 overflow-auto'>

      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={onBack}
        />
        <h2 className='fs-0 text-primary mb-1'>{'Customer Details'}</h2>
      </Card.Header>

      <Card.Body>
        <div className='row'>
          <div className='col-lg-3 mb-3'>
            <Form.Label className='fs--1'>
              {t('resource_2:companyName')}
            </Form.Label>
            <Form.Control
              aria-label={t('resource_2:companyName')}
              className='fs--1'
              disabled={true}
              value={companyDetails?.companyName || ''}
              onChange={(e) => {
                dispatch(setCompanyDetails({
                  companyName: e.target.value
                }))
              }}
            >
            </Form.Control>
          </div>

          <div className='col-lg-3 mb-3'>
            <Form.Label className='fs--1'>
              {t(t('resource_2:phoneNo'))}
            </Form.Label>
            <Form.Control
              aria-label={t(t('resource_2:phoneNo'))}
              className='fs--1'
              disabled={disable}
              value={companyDetails?.phoneNo || ''}
              onChange={(e) => {
                dispatch(setCompanyDetails({
                  phoneNo: e.target.value.trim()
                }))
              }}
            >
            </Form.Control>
          </div>

          <div className='col-lg-3 mb-3'>
            <Form.Label className='fs--1'>
              {t(t('resource_2:taxId'))}
            </Form.Label>
            <Form.Control
              aria-label={t(t('resource_2:taxId'))}
              className='fs--1'
              disabled={disable}
              value={companyDetails?.taxId || ''}
              onChange={(e) => {
                dispatch(setCompanyDetails({
                  taxId: e.target.value.trim()
                }))
              }}
            >
            </Form.Control>
          </div>
          <div className='col-lg-3 mb-3'>
            <Form.Group controlId="registration.country">
              <Form.Label className="fs--1">
                <span className="mandatory">*</span>
                {t("resource_1:country")}
              </Form.Label>
              <Form.Select
                className={`fs--1 form-control`}
                value={companyDetails?.country || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  dispatch(setCompanyDetails({
                    country: value
                  }))
                }}
              >
                <option value="" hidden></option>
                {countryOptions?.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                )) ?? []}
              </Form.Select>
            </Form.Group>
          </div>

          <div className='col-lg-12 mb-3'>
            <Form.Label className='fs--1'>
              {t(t('resource_1:address'))}
            </Form.Label>
            <Form.Control
              type='textarea'
              as='textarea'
              aria-label={t(t('resource_1:address'))}
              className='fs--1'
              disabled={disable}
              value={companyDetails?.address || ''}
              onChange={(e) => {
                dispatch(setCompanyDetails({
                  address: e.target.value
                }))
              }}
            >
            </Form.Control>
          </div>
        </div>

        <div className='d-flex justify-content-center align-items-center'>
          <Button
            className='me-2'
            variant='falcon-primary'
            size='sm'
            type='submit'
            disabled={disable}
            onClick={() => {
              if (companyDetails) {
                dispatch(saveEditCompanyDetails({ custId }))
              } else {
                console.error("Company details is null");
              }
            }}
          >
            <FontAwesomeIcon icon={faSave} className='me-2' />
            {t('resource_1:submit')}
          </Button>
        </div>

        <CustomerCodeList />
        <MappingUserList />

      </Card.Body>

    </Card>
  );
};

export default CustomerDetailsSlider;
