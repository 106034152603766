import { useEffect, useRef, useState } from "react";

import moment from "moment";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  faMailBulk,
  faMailForward,
  faMailReply,
  faPaperPlane,
  faPlane,
  faRepeat,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppDispatch, RootState } from "../../store";
import DataTable from "../../common/components/DataTable";
import useDataCountry from "../../../hooks/useDataCountry";
import FilterView from "../../common/components/FilterView";
import OblDetails from "../../common/components/OblDetails";
import { IConfigsDefinition } from "../../utils/columnConfig";
import { AESDecrypt, AESEncrypt } from "../../../encrypt-util";
import { IDataTableColumn } from "../../common/types/dataTable";
import { getColumnViews } from "../../common/actions/dataTable";
import { toggleColumnSettingsPayDash } from "./paymntDashSlice";
import { getDashboardData } from "../dashboard/actions/dashboard";
import { updateDashbaordName } from "../../common/slices/navbarTop";
import { updateCurrentPagePayment } from "../dashboard/slices/dashboardSlice";
import DashboardContainer from "../../../components/common/DashboardContainer";
import { useGenerateColumnConfig } from "../../../hooks/useGenerateColumnConfig";
import {
  updateDashbaordRefresh,
  updateParams,
  updateSortStateDataTable,
} from "../../common/components/DataTable/slices/DataTable";
import { mergePayment, sendMailToLiner } from "../blPayment/actions/payments";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  updateSelectedAmountPayment,
  updateSelectedpaymentMultiPayTable,
  updateSelectedRowsInvoice,
  updateSelectedRowsPayment,
} from "../multiBlPayment/slices/multiBlPaymentSlice";

const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
const gridName = "PAYMENT_DASHBOARD";
const dashboardName = "PAYMENT_DASHBOARD";
const PaymentDashboard = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  //const [selectedPayment, setSelectedPayment] = useState<any>(null);

  const paymentDetailsCheckboxAccess =
    useSelector((state: RootState) => state.userProfile)?.rbacDetails
      ?.paymentDetailsCheckboxAccess ?? "N";
  const refresh = useSelector(
    (state: RootState) => state.dataTable.refreshDashboard
  );
  const selectionInitialState = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsPaymentMultiPayTable
  );
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPagePayment
  );
  const selectedAmountPayment = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountPaymentAll
  );

  const data = useSelector(
    (state: RootState) => state.dashboard.paymentDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const selection = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsInvoice
  );

  const grids = useSelector((state: RootState) => state.dataTable.paymentGrids);
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: "{}",
    search: "",
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: "{}",
    search: "",
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const sortState = useSelector(
    (state: RootState) => state.dataTable.sortState
  );
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPagePayment(page));
  };
  const selectedRowsPayment = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsPayment
  );

  const paymentTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.paymentTopSearchState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const rbac = useSelector((state: RootState) => state.userProfile);
  const dataCountry = useDataCountry();
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const selectionDisabledForLiner =
    profileDetails.partnerType?.toLowerCase() === "liner" ? false : true;
  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();

    useEffect(() => {
      callback.current = effect;
    }, [effect]);
    useEffect(() => {
      console.log("selectionInitialState", selectionInitialState);
    }, [selectionInitialState]);
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };

      const timer = setTimeout(handler, delay);

      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }
  var newselectedRowsPayment: any = [];
  useEffect(() => {
    console.log("selectedRowsPayment.length", selectedRowsPayment);
    // Directly filter selectedRowsPayment based on the 'Submitted' status
    newselectedRowsPayment = selectedAmountPayment
      .filter(
        (item: any) =>
          item.pmtmode && item.pmtstatus.toUpperCase() == "SUBMITTED"
      )
      .map((item: any) => item.pmtmode);

    console.log(
      "Filtered Payment Modes:",
      //selectedAmountPayment
      selectedAmountPayment.filter(
        (item: any) =>
          item && item.pmtmode && item.pmtstatus.toUpperCase() == "SUBMITTED"
      )

      //newselectedRowsPayment
    );
    const uniqueList = newselectedRowsPayment
      .filter((item: any) => item !== undefined && item !== null) // Remove undefined and null items
      .filter(
        (item: any, index: number, arr: any[]) => arr.indexOf(item) === index
      );
    dispatch(updateSelectedRowsPayment(uniqueList));
    console.log(
      "selectedrowslength",
      selectedRowsPayment,
      selectedRowsPayment.length
    );
  }, [selectedAmountPayment, dispatch, data]);
  useEffect(() => {
    if (
      selectedAmountPayment.length === 0 &&
      location.pathname.split("/")[0] === "paymentDashboard"
    ) {
      navigate(`${APP_PATH_PREFIX}/paymentDashboard${location.search}`);
    }
  }, [selectedAmountPayment]);
  const generateColumnConfig = useGenerateColumnConfig();

  useEffect(() => {
    const paymentDashboardDefinition = grids?.[0]?.columnConfigGrid || "";

    if (!paymentDashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(paymentDashboardDefinition)),
        getParams
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error("Column Error: ", e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(updateDashbaordName(gridName));
  }, []);

  const filterStateLocal = filterState?.["paymentTwo"];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(",")
    ?.join?.(" ");
  //   (profileDetails.partnerType === 'customer' &&
  //   profileDetails.partnerId &&
  //   grids?.[0]?.whereClause) ||
  // profileDetails.partnerType === 'liner'

  useEffect(() => {
    if (location?.pathname && dataCountry && linerId) {
      dispatch(
        getColumnViews({
          gridName: dashboardName,
          country: dataCountry,
          linerId: linerId,
        })
      );
    }
    dispatch(updateParams({}));
    // dispatch(
    //   updateSortStateDataTable({
    //     key: null,
    //   })
    // );
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadPaymentDashboard(false);
  }, [currentPage]);
  const _onSelect = (data: any) => {
    // dispatch(updateSelectedBlListMultiPay([...selectedBlData,data.list]));
  };
  useEffect(() => {
    if (refresh) loadPaymentDashboard(false);
    // server sometimes takes time to update the view so the delay
    setTimeout(() => dispatch(updateDashbaordRefresh(false)), 2000);
  }, [refresh]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  // useEffect(() => {
  //   if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
  //     loadPaymentDashboard(true);
  //     setPreviousState(currentState);
  //   }
  // }, [currentState, linerId]);

  useDebouncedEffect(
    () => {
      if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
        loadPaymentDashboard(true);
        setPreviousState(currentState);
      }
    },
    [currentState, linerId],
    700
  );

  useEffect(() => {
    loadPaymentDashboard(true);
  }, [linerId]);

  const loadPaymentDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || "",
              filterStateLocal?.[k]?.to || "",
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === "string"
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};
          const currentDate = new Date();

          if (grids[0]) {
            let timeFlag = "";
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.["paymentTwo"]
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == "invdt"
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == "day" && dateRange) {
              filterApiState.invdt[1] = moment()
                .subtract(Math.abs(dateRange.to), "days")
                .endOf("day")
                .toISOString();
              filterApiState.invdt[0] = moment()
                .subtract(Math.abs(dateRange.from), "days")
                .startOf("day")
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === "customer" &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === "liner"
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: gridName,
                  payload: {
                    fields: /*  SelectedViewFields?.fields || */ [],
                    filters:
                      !selectedTopSearchFilter ||
                      selectedTopSearchFilter?.where == "" ||
                      searchInputValue.trim() === ""
                        ? finalFilterApiState
                        : {},

                    size: 20,
                    sorts:
                      sortState &&
                      sortState?.key != null &&
                      sortState?.value != null
                        ? [`${sortState?.key} ${sortState?.value}`]
                        : sort
                        ? [sort]
                        : ["pmtmode desc"],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId:
                    profileDetails.partnerType === "customer"
                      ? profileDetails.partnerId
                      : null,
                  where: grids?.[0]?.whereClause
                    ? AESEncrypt(grids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== "" &&
                    searchInputValue.trim() !== ""
                      ? {
                          searchParameter: searchInputValue,
                          searchWhere: selectedTopSearchFilter?.where,
                        }
                      : {},
                })
              );
            }, 250);
          }
        }
      } else {
        if (
          (profileDetails.partnerType === "customer" &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails?.partnerType === "liner"
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */ [],
                  filters:
                    selectedTopSearchFilter?.where === ""
                      ? paymentTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                    sortState?.key != null &&
                    sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                      ? [sort]
                      : ["pmtmode desc"],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }, 250);
        }
      }
    } else {
      if (
        location.pathname.includes("/paymentDashboard") &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === "customer" &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails.partnerType === "liner"
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                gridName: gridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */ [],
                  filters:
                    selectedTopSearchFilter?.where == ""
                      ? paymentTopSearchState
                      : {},
                  size: 20,
                  sorts:
                    sortState &&
                    sortState?.key != null &&
                    sortState?.value != null
                      ? [`${sortState?.key} ${sortState?.value}`]
                      : sort
                      ? [sort]
                      : ["pmtmode desc"],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === "customer"
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }, 250);
        }
      }
    }
  };

  const showColumnSettings = useSelector(
    (state: RootState) => state.paymentDashboard.showColumnSettings
  );

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : "",
      pmtmode: blNum ? AESDecrypt(blNum) : "",
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);
  useEffect(() => {
    console.log("selectedRowsPayment.lengthinside", selectedRowsPayment);
  }, [selectedRowsPayment]);
  const FilterViewChild = (() => {
    console.log("selectedRowsPayment?.length", selectedRowsPayment);
    return (
      <>
        {rbac.rbacDetails.createMergePayment == "Y" && (
          <>
            <Button
              variant="falcon-primary"
              onClick={() => {
                /*    console.log(
                  "selectedRowsPayment.length",
                  selectedRowsPayment.length
                );
                // Directly filter selectedRowsPayment based on the 'Submitted' status
                const newselectedRowsPayment = selectedAmountPayment
                  .filter(
                    (item: any) =>
                      item.pmtmode &&
                      item.pmtstatus.toUpperCase() == "SUBMITTED"
                  )
                  .map((item: any) => item.pmtmode);

                console.log(
                  "Filtered Payment Modes:",
                  //selectedAmountPayment
                  selectedAmountPayment.filter(
                    (item: any) =>
                      item.pmtmode &&
                      item.pmtstatus.toUpperCase() == "SUBMITTED"
                  )
                  //newselectedRowsPayment
                );

                dispatch(updateSelectedRowsPayment(newselectedRowsPayment)); */

                dispatch(mergePayment(selectedRowsPayment))
                  .unwrap()
                  .then((response: any) => {
                    console.log("mergeehere1234", response);

                    /*        const entries = Object.entries(response?.data || {});
                    if (entries?.length > 0) {
                      const [key, value] = entries[0];
 */
                    // Check if the key contains "successfully"
                    //   if (Object.keys(response)[0].includes("successfully")) {
                    if (response.message.includes("Successfully")) {
                      console.log("logeheremerge");
                      // dispatch(updateSelectedRowsPayment([]));

                      setTimeout(() => {
                        dispatch(updateDashbaordRefresh(true));
                        dispatch(updateSelectedAmountPayment([]));
                        dispatch(updateSelectedRowsPayment([]));
                        dispatch(updateSelectedpaymentMultiPayTable({}));
                      }, 2000);
                    }
                  });
              }}
              disabled={
                !selectedRowsPayment ||
                selectedRowsPayment?.length == 0 ||
                selectedRowsPayment?.length == 1 ||
                selectedRowsPayment.every((item) => item === undefined)
              }
              size="sm"
              style={{
                marginRight: "0.5rem",
                position: "relative",
                float: "left",
              }}
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faCreditCard} className="me-2 mt-1 " />
                <span>Merge Payment</span>
              </div>
            </Button>
          </>
        )}
      </>
    );
  })();
  useEffect(() => {
    console.log("selectedRowsPayment", selectedRowsPayment.length);
  }, [selectedRowsPayment]);
  const prevSelectionsRef = useRef<any>(null); // to store previous selections

  useEffect(() => {
    const selections: any = {};

    data.result?.forEach((d, i) => {
      if (
        selectedRowsPayment.includes(d.pmtmode) &&
        !d?.paymentRefNo &&
        !(d?.paidIndicator === "1")
      )
        selections[i] = true;
    });

    // Dispatch updated selections for the table
    dispatch(updateSelectedpaymentMultiPayTable(selections));
  }, [selectedRowsPayment, data]);

  const sideWidth =
    Object.entries({
      [`/paymentDashboard`]: 0,
      [`/blsliderpayment/:blNum`]: 6,
    }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9;

  return (
    <>
      <DashboardContainer
        sideWidth={sideWidth}
        middleChildren={
          location.pathname.split("/")[1] == "blsliderinvoice" ? (
            <OblDetails
              blslider={`/blsliderinvoice/${location.pathname.split("/")[1]}`}
            />
          ) : (
            <div className={`flex-1 h-100 smoothDiv w-100`}>
              <Outlet />
            </div>
          )
        }
      >
        <FilterView
          loadList={loadPaymentDashboard}
          showUploadButton
          showExport={true}
          showColumnSettings={true}
          dataLoading={false}
          toggleColumnSettings={toggleColumnSettingsPayDash}
          columnSettingsPersistKey="@odex/paydash/v1"
          uniqueFilterKey="paymentTwo"
          child={<div>{FilterViewChild}</div>}
          configViews={
            grids?.find?.((g: any) => g?.gridName === dashboardName)
              ?.configGroup || []
          }
          // child={resendButton}
          showRightDiv={!!matchPath(location.pathname, "/paymentDashboard")}
          dashboardName={dashboardName}
          /*      child={FilterViewChild} */
          data={data}
          gridDef={grids}
        />
        {columns?.length > 0 && (
          <DataTable
            data={data?.result || null}
            columns={columns}
            dataCountry={dataCountry || ""}
            sortable
            searchable={false}
            showColumnFilter={false}
            selection={
              rbac.rbacDetails.sendPaymentToLiner == "Y" ||
              rbac.rbacDetails.createMergePayment == "Y"
                ? true
                : false
            }
            onCustomSelectChange={_onSelect}
            selectionInitialState={selectionInitialState}
            disableRowSelection={{
              accessor: "payment",
              value: "Paid",
            }}
            onSelectRow={(dat) => {
              if (dat?.action === "add") {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsPayment([
                      ...selectedRowsPayment,
                      ...dat?.data?.map((d: any) => d.pmtmode),
                    ])
                  );
                } else {
                  dispatch(
                    updateSelectedRowsPayment([
                      ...selectedRowsPayment,
                      dat?.data?.pmtmode,
                    ])
                  );
                }
              } else {
                if (dat?.data?.length > 0) {
                  dispatch(
                    updateSelectedRowsPayment(
                      selectedRowsPayment.filter(
                        (f: any) =>
                          !dat?.data?.map((v: any) => v?.pmtmode).includes(f)
                      )
                    )
                  );
                } else {
                  dispatch(
                    updateSelectedRowsPayment(
                      selectedRowsPayment.filter(
                        (f: any) => f !== dat?.data?.pmtmode
                      )
                    )
                  );
                }
              }
            }}
            clearSelection={() => {
              dispatch(updateSelectedpaymentMultiPayTable({}));
            }}
            perPage={20}
            pagination
            numberPagination
            maxPage={parseInt(data?.maxPage || "100")}
            hasNext={data?.hasNext}
            currentPage={currentPage}
            gridId={grids?.[0]?.gridId || 0}
            onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
            showExport={false}
            showColumnSettings={showColumnSettings}
            onHide={() => {
              dispatch(toggleColumnSettingsPayDash(false));
            }}
            columnSettingsPersistKey="@odex/paydash/v1"
            customSort={true}
            uniqueFilterKey="paymentTwo" //"paymentTwo"""
            staticHiddenCols={[
              ...(rbac.rbacDetails.sendPaymentToLiner == "Y"
                ? []
                : ["to_liner_time", "to_liner_email"]),
              ...(rbac.rbacDetails.revisitPayment == "Y"
                ? []
                : ["revisit_time", "revisit_email"]),
              ...(rbac.rbacDetails.paymentDashboardRemarks == "Y"
                ? []
                : ["user_remarks"]),
              ...(configs?.staticHiddenCols ?? []),
            ]}
            defaultHiddenCols={[
              ...(rbac.rbacDetails.sendPaymentToLiner == "Y"
                ? []
                : ["to_liner_time", "to_liner_email"]),
              ...(rbac.rbacDetails.revisitPayment == "Y"
                ? []
                : ["revisit_time", "revisit_email"]),
              ...(rbac.rbacDetails.paymentDashboardRemarks == "Y"
                ? []
                : ["user_remarks"]),
            ]}
            tableMinHeight={400}
            gridName={dashboardName}
            dataLoading={false}
            configGroup={grids?.[0]?.configGroup || []}
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default PaymentDashboard;
