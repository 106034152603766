import axios from "axios";
import exp from "constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useBackNavigation } from "../../../hooks/BackNavigation";
import { resetQuickPayTable } from "../quickpay/quickPaySlice";
import { PayPalCardFieldsIndividualFieldOptions } from "@paypal/react-paypal-js";
import { Button } from "react-bootstrap";
import OdexSpinner from "../../common/components/OdexSpinner";
import { updateDashbaordRefresh } from "../../common/components/DataTable/slices/DataTable";
import {
  updateSelectedAmount,
  updateSelectedRowsInvoice,
} from "../multiBlPayment/slices/multiBlPaymentSlice";

function PayPalCard({ payload, quickPay }: any) {
  const nameFieldRef = useRef<any>(null);
  const cardFieldRef = useRef<any>(null);
  const cvvFieldRef = useRef<any>(null);
  const expFieldRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [onSubmit, setOnSubmit] = useState<null | (() => void)>(null);

  const navigate = useNavigate();
  const onBack = useBackNavigation();
  const dispatch = useDispatch();
  useEffect(() => {
    const initializeCardFields = async () => {

      const paypal = window.paypal;

      if (nameFieldRef?.current) nameFieldRef.current.innerHTML = "";
      if (cardFieldRef?.current) cardFieldRef.current.innerHTML = "";
      if (cvvFieldRef?.current) cvvFieldRef.current.innerHTML = "";
      if (expFieldRef?.current) expFieldRef.current.innerHTML = "";

      const cardField =
        paypal &&
        paypal?.CardFields?.({
          createOrder: async () => {
            setLoading(true);

            return axios
              .post(
                "/api/payment-gateway-v2/v2/gateway/paypal/order/initiation", payload,
                { headers: { Accept: "*/*", }, }
              )
              .then((response) => {
                return response?.data?.id;
              })
          },
          onApprove: async ({ orderID }) => {

            const { data } = await axios.get("/api/payment-gateway-v2/v2/gateway/paypal/order/getOrder/" + orderID)
            var bin_country_code = data?.payment_source?.card.bin_details.bin_country_code;
            if (bin_country_code !== "US") {
              throw Error("Please use a US-issued card")
            }

            const response = await axios.post(`/api/payment-gateway-v2/v2/gateway/paypal/order/${orderID}/capture`,
              {}, { headers: { accept: "*/*", "Content-Type": "application/json", } })

            if (response?.data?.error) {
              if (response?.data?.error.details?.[0]?.issue == "INSTRUMENT_DECLINED") {
                // actions.restart();
                return;
              }
              throw new Error(
                response?.data?.error.details?.[0]?.description ??
                "Payment Unsuccessful"
              );
            }

            if (quickPay) {
              dispatch(resetQuickPayTable());
              navigate(`${process.env.APP_PATH_PREFIX || ''}/quickpay`);
              toast.success(
                `Payment successful, details will be mailed to ${payload.email}`,
                {
                  theme: "colored",
                }
              );
              setLoading(false);
              return;
            }

            toast.success("Payment successful", {
              theme: "colored",
            });
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true));
              dispatch(updateSelectedRowsInvoice([]));
              dispatch(updateSelectedAmount([]));
            }, 3000);
            setTimeout(() => {
              dispatch(updateDashbaordRefresh(true))
            }, 2000)
            onBack(); // Navigate back after success
            setLoading(false); // Hide loading state
          },

          onError: (err: any) => {
            console.error("PayPal Error: ", err);

            // try {
            //   const errorMatch = err?.message?.match(/\{.*\}/);
            //   if (errorMatch) {
            //     const errorObject = JSON.parse(errorMatch[0]);
            //     const errorMessage = errorObject?.message;

            //     toast.error(errorMessage || "An unexpected error occurred.");
            //   } else {
            //     toast.error("An unexpected error occurred.");
            //   }
            // } catch (parseError) {
            //   console.error("Error parsing PayPal response:", parseError);
            //   toast.error("An error occurred while processing the payment.");
            // }
            toast.error(err.message);
            setLoading(false)
          },
        });

      const fieldStyle = {
        style: {
          input: {
            outline: "none",
            "padding-top": "5px",
            "padding-bottom": "5px",
          },
          div: {
            padding: "0",
          },
        },
      };

      if (cardField?.isEligible()) {
        cardField
          .NameField({ ...fieldStyle, placeholder: "Cardholder Name" })
          .render(nameFieldRef.current);
        cardField.NumberField(fieldStyle).render(cardFieldRef.current);
        cardField.CVVField(fieldStyle).render(cvvFieldRef.current);
        cardField.ExpiryField(fieldStyle).render(expFieldRef.current);

        setOnSubmit(() => async () => {
          const data = await cardField.getState();
          console.log("dataaaa", data);
          if (data.fields.cardNameField.isEmpty) {
            return;
          }
          cardField
            .submit()
            .then((data) => {
              console.log("dataaaahere", data);
              console.log(data);
            })
            .catch((e) => {
              console.log(e);
            });
        });
      }
    };

    initializeCardFields();
  }, [payload?.pymtMode]);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <div className="row w-100" ref={null}>
        <div className="col-lg-12" ref={nameFieldRef}></div>
        <div className="col-lg-12" ref={cardFieldRef}></div>
        <div className="col-lg-5" ref={expFieldRef}></div>
        <div className="col-lg-5" ref={cvvFieldRef}></div>
      </div>
      {false ? (
        <OdexSpinner />
      ) : (
        <Button
          onClick={onSubmit ?? (() => { })}
          disabled={onSubmit == null || loading}
          /* style={{
            width: "95%",
            padding: "12px",
            fontSize: "16px",
            color: "#ffffff",
            backgroundColor: loading ? "#cccccc" : "#0070ba",
            border: "none",
            borderRadius: "8px",
            cursor: loading ? "not-allowed" : "pointer",
            fontWeight: "600",
          }} */
          style={{
            minWidth: "150px", // Ensures the button has a longer width
            padding: "9px 20px", // Increases padding for a larger button
            fontSize: "16px",
            color: "#ffffff",
            backgroundColor: loading ? "#cccccc" : "#0070ba", // Matches the blue color
            border: "none",
            borderRadius: "20px", // Makes the button more rounded
            cursor: loading ? "not-allowed" : "pointer",
            fontWeight: "bold", // Ensures the text is bold
            textAlign: "center", // Centers the text
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow for depth
          }}
        >
          {loading ? "Processing" : "Pay"}
        </Button>
      )}
    </div>
  );
}

export default PayPalCard;
