import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getBadges } from '../../dashboard/components/helpers';
import { useTranslation } from 'react-i18next';
import { AESEncrypt } from '../../../../encrypt-util';
import { useLocation } from 'react-router-dom';
import Divider from '../../../../components/common/Divider';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncThunkAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  getInstructionAgentTypes,
  getInstructionDelegationTypes,
} from '../../dashboard/actions/instruction';
import { updateInstructionID } from '../../blDetails/slices/documentHistorySlice';

interface IProps {
  isDisabled?: boolean;
  selectedRows?: any[];
}

const AddInstruction = ({ isDisabled, selectedRows }: IProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { blNum } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const partnerCountry = profileDetails.partnerCountry;
  const instructionStatus = useSelector(
    (state: RootState) => state.dashboard.showInstruction
  );
  const rbac = useSelector((state: RootState) => state.userProfile);

  const instructionTypes = useSelector(
    (state: RootState) => state.documentHistory.instructionTypes
  );

  const standingInstructionTypes = useSelector(
    (state: RootState) => state.standingInstructionList.instructionTypes
  );

  const [dataCheck, setDataCheck] = useState({
    DOE: false,
    AD: true,
    IC: false,
    DM: false,
    RI: false,
  });

  const linerId = useSelector((state:RootState)=>state.navbarTop.linerId)
  
  const language = useSelector((state:RootState)=> state.navbarTop.language);

  useEffect(() => {
    if (partnerCountry !== null) {
      dispatch(getInstructionAgentTypes({partnerCountry, linerId, language}));
      dispatch(getInstructionDelegationTypes({partnerCountry, linerId, language}));
    }
    // dispatch(getInstructionTypes(partnerCountry));
  }, [partnerCountry, linerId, language]);

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const [isShipper, setIsShipper] = useState(false);
  const [isConsignee, setIsConsignee] = useState(false);
  const blPartyList = useSelector(
    (state: RootState) => state.documentHistory.blPartyList
  );
  const oblForm = useSelector((state: RootState) => state.oblDetails.oblForm);
  useEffect(() => {
    blPartyList.forEach((item: any) => {
      if (
        parseInt(item.partyId) == profileDetails.partnerId &&
        item.partyType == 'CZ'
      ) {
        setIsShipper(true);
      }
      if (
        parseInt(item.partyId) == profileDetails.partnerId &&
        item.partyType == 'CN'
      ) {
        setIsConsignee(true);
      }
    });
  }, [blPartyList, oblForm]);

  const instructionItems = instructionTypes.map((option: any) => {
    return (
      <div>
        {option.index !== 0 ? <Dropdown.Divider /> : <></>}
        <Dropdown.Item
          disabled={
            option?.value === '812' && !isConsignee
              ? true
              : option?.value === '813' && !isShipper
              ? true
              : false
          }
          onClick={() => {
            // dispatch(updateSelectedDocIdDH(''));
            //  dispatch(updateSelectedEventIdDH(''));
            dispatch(updateInstructionID(option.value));
            navigate(
              `${encodeURIComponent(blNum || '')}/instruction/${AESEncrypt(
                option.value
              )}${location?.search || ''}`
            );
          }}
        >
          {option.text}
        </Dropdown.Item>
      </div>
    );
  });

  const standingInstructionItems = standingInstructionTypes.map(
    (option: any) => {
      return (
        <div>
          {option.index !== 0 ? <Dropdown.Divider /> : <></>}
          <Dropdown.Item
            disabled={
              option?.value === '812' && !isConsignee
                ? true
                : option?.value === '813' && !isShipper
                ? true
                : false
            }
            onClick={() => {
              dispatch(updateInstructionID(option.value));
              // dispatch(updateSelectedDocIdDH(''));
              //  dispatch(updateSelectedEventIdDH(''));
              navigate(`${APP_PATH_PREFIX}/standing/${AESEncrypt(option.value)}`);
            }}
          >
            {option.text}
          </Dropdown.Item>
        </div>
      );
    }
  );

  return (
    <>
      {profileDetails?.partnerType !== 'liner' &&
      rbac.rbacDetails.multiInstructCreate === 'Y' &&
      location.pathname.split('/')[1] === 'home' ? (
        <div className='d-flex justify-content-start align-items-between me-2 '>
          <Dropdown drop='down'>
            <Dropdown.Toggle
              className='no-caret-dropdown-toggle cursor-pointer z-inxe-agent'
              as='div'
            >
              <Button
                variant='falcon-primary'
                size='sm'
                style={{ marginRight: '0.5rem', position: 'relative' }}
              >
                <div className='d-flex'>
                  <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                  <span>{t("resource_1:instruct")}</span>
                </div>
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                position: 'relative',
                zIndex: 1000000,
              }}
            >
              <div>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(updateInstructionID(805));
                    if (selectedRows)
                      navigate(
                        `/multiBl/${AESEncrypt(
                          selectedRows
                            .map((v) => v.blid)
                            .filter(
                              (value: any, index: any, self: any) =>
                                self.indexOf(value) === index
                            )
                            .join(',')
                            .toString()
                        )}/instruction/${AESEncrypt('805')}`
                      );
                  }}
                  disabled={isDisabled}
                >
                  {t('resource_4:logisticsPartnerDelegationByConsignee')}
d
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(updateInstructionID(814));
                    if (selectedRows)
                      navigate(
                        `/multiBl/${AESEncrypt(
                          selectedRows
                            .map((v) => v.blid)
                            .filter(
                              (value: any, index: any, self: any) =>
                                self.indexOf(value) === index
                            )
                            .join(',')
                            .toString()
                        )}/instruction/${AESEncrypt('814')}`
                      );
                  }}
                  disabled={isDisabled}
                >
                  {t('resource_4:logisticsPartnerDelegationByShipper')}
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ''
      )}
      {profileDetails?.partnerType !== 'liner' &&
      rbac.rbacDetails.agentCreate === 'Y' &&
      location.pathname.split('/')[1] === 'instruction' ? (
        <div className='d-flex justify-content-start align-items-between me-2'>
          <Dropdown drop='down'>
            <Dropdown.Toggle
              className='no-caret-dropdown-toggle cursor-pointer z-inxe-agent'
              as='div'
            >
              <Button
                variant='falcon-primary'
                size='sm'
                style={{ marginRight: '0.5rem', position: 'relative' }}
              >
                <div className='d-flex'>
                  <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                  <span>{t("resource_1:instruct")}</span>
                </div>
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                {instructionItems}
                {/* <Dropdown.Item
                  onClick={() => {
                    navigate(`${APP_PATH_PREFIX}/instruction/805`);
                  }}
                  disabled={false}>
                  {t('resource_4:agent_nomination_to_carrier')}
                </Dropdown.Item>
                <Divider className='mt-0' />
                <Dropdown.Item
                  onClick={() => {
                    navigate(`${APP_PATH_PREFIX}/instruction/809`);
                  }}
                  disabled={false}>
                  {t('resource_4:agent_nomination_to_consignee')}
                </Dropdown.Item> */}
                {/*  <Divider /> */}
                {/*   <Dropdown.Item
                  onClick={() => {
                    navigate(`${APP_PATH_PREFIX}/instruction/810`);
                  }}
                  disabled={false}>
                  {t('resource_2:blanketDelegation')}
                </Dropdown.Item> */}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ''
      )}

      {profileDetails?.partnerType !== 'liner' &&
      rbac.rbacDetails.agentCreate === 'Y' &&
      location.pathname.split('/')[1] === 'standing' ? (
        <div className='d-flex justify-content-start align-items-between me-2'>
          <Dropdown drop='down'>
            <Dropdown.Toggle
              className='no-caret-dropdown-toggle cursor-pointer z-inxe-agent'
              as='div'
            >
              <Button
                variant='falcon-primary'
                size='sm'
                style={{ marginRight: '0.5rem', position: 'relative' }}
              >
                <div className='d-flex'>
                  <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
                  <span>{t("resource_1:instruct")}</span>
                </div>
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>{standingInstructionItems}</div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default AddInstruction;
/* function dispatch(
  arg0: AsyncThunkAction<AxiosError<unknown, any> | undefined, any, {}>
) {
  throw new Error('Function not implemented.');
} */
