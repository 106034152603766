import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Col, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useEffect, useState, useTransition } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updateAmountMode } from "../blPayment/slices/blPaymentSlice";
import { useDispatch } from "react-redux";
import { StandaloneInvoiceDetails } from "./slices/standaloneInvoicePaymentSlice";
import {
  getCurrencyDDList,
  getPlatformFees,
  getMultiCountryAllowance,
} from "./actions/payments";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import useDataCountry from "../../../hooks/useDataCountry";
import {
  roundingPrecision,
  useAmountFormatter,
} from "../../../hooks/useAmountFormatter";

const PayableAmount = ({ dataCountry }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const paymentMode = useSelector(
    (state: RootState) => state.blPayment.paymentMode
  );
  const selectedBls = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPay
  );
  const platformfee = useSelector(
    (state: RootState) => state.blPayment.platformFee
  );
  const multiCurrencyAllow = useSelector(
    (state: RootState) => state.blPayment.multiCurrencyAllow
  );

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';
  const location = useLocation();
  const matchPth =
    matchPath(
      {
        path: `${APP_PATH_PREFIX}/bl/:blNum/payment`,
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: `${APP_PATH_PREFIX}/instruction/bl/:blNum/payment`,
        caseSensitive: true,
        end: true,
      },
      location.pathname
    ) ||
    matchPath(
      {
        path: `${APP_PATH_PREFIX}/payment/bl/:blNum/payment`,
        caseSensitive: true,
        end: true,
      },
      location.pathname
    );
  const disableFields =
    profileDetails?.partnerType === "liner" ||
    (matchPth && profileDetails?.partnerType !== "liner")
      ? true
      : false;
  const [selectedCcy, setSelectedCcy] = useState("usd");

  const selectedDocHistory: any = useSelector(
    (state: RootState) => state.documentHistory.data
  );
  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );
  const standaloneInvoiceList = useSelector(
    (state: RootState) => state.standaloneInvoice.data
  );
  const unpaidData = useSelector((state: RootState) =>
    state.invoiceUnpaid.data.filter((item) => !!item.ccy && item.ccy !== "")
  );
  const newStandaloneInvoiceList: any = standaloneInvoiceList.map(
    (invoice: StandaloneInvoiceDetails) => {
      return {
        amount: invoice.invoiceAmount,
        id: invoice.invoiceNo,
        invID: invoice.invoiceId,
        invcurr: invoice.invoiceCurrency,
        ccy: invoice.invoiceCurrency,
      };
    }
  );

  const newUnpaidData: any = unpaidData
    .map((invoice: any) => ({
      amount: invoice.amount,
      id: invoice.invno,
      invID: invoice.invid,
      invcurr: invoice.ccy,
      ccy: invoice.ccy,
    }))
    .filter(
      (item) =>
        item.invcurr ===
        (unpaidData.find((item) => item.ccy != "USD")?.ccy ?? "USD")
    );

  const filteredselectedAmount = matchPath(
    `${APP_PATH_PREFIX}/payment/addInvoice/invoicePayment`,
    location.pathname
  )
    ? newStandaloneInvoiceList
    : location.pathname.includes("unpaid")
    ? newUnpaidData
    : selectedAmount.filter(
        (item: { invcurr: any; id: any; localamount: any }) =>
          item?.invcurr === selectedAmount[0]?.invcurr &&
          (item?.id?.substring(0, 2) == "CD") ==
            (selectedAmount[0]?.id.substring(0, 2) == "CD") &&
          isNaN(item.localamount) == isNaN(selectedAmount[0].localamount)
      );

  const selectedLinerId = useSelector(
    (state: RootState) => state.navbarTop.linerId
  );
  const [amount, setAmount] = useState("");
  useEffect(() => {
    if (
      amount &&
      filteredselectedAmount !== "" &&
      [7, 8, 10, "7", "8", "10"].includes(paymentMode)
    ) {
      dispatch(
        getPlatformFees({
          amount: amount,
          ccy: filteredselectedAmount?.[0]?.invcurr,
          country: dataCountry,
          paymentMode,
          linerId: selectedLinerId,
        })
      );
    }
  }, [
    amount,
    filteredselectedAmount?.[0]?.invcurr,
    dataCountry,
    selectedLinerId,
    paymentMode,
  ]);

  const usdPayableAmount =
    window.location.pathname === "/payment/new" ||
    window.location.pathname === "/payment/addInvoice/invoicePayment" ||
    location.pathname.includes("unpaid")
      ? filteredselectedAmount.reduce((accumulator: any, currentObj: any) => {
          const amountAsNumber: number = parseFloat(currentObj.amount);
          if (!isNaN(amountAsNumber)) {
            return accumulator + amountAsNumber;
          }
          return accumulator;
        }, 0)
      : selectedDocHistory
          .filter((v: any) => v.ccy === "USD")
          .map((i: any) => (i.docStatus !== "Y" ? Number(i.amount) : Number(0)))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const zarPayableAmount =
    window.location.pathname === "/payment/new" ||
    window.location.pathname === "/payment/addInvoice/invoicePayment" ||
    location.pathname.includes("unpaid")
      ? selectedBls
          ?.filter((v: any) => v.ccy === "ZAR")
          .map((i: any) => Number(i.amount))
          .reduce((partialSum: any, a: any) => partialSum + a, 0)
      : selectedDocHistory
          .filter((v: any) => v.ccy === "ZAR")
          .map((i: any) => Number(i.amount))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const kesPayableAmount =
    window.location.pathname === "/payment/new" ||
    window.location.pathname === "/payment/addInvoice/invoicePayment" ||
    location.pathname.includes("unpaid")
      ? filteredselectedAmount
          .map((i: any) => Number(i.localamount))
          .reduce((partialSum: any, a: any) => partialSum + a, 0)
      : selectedDocHistory
          //.filter((v: any) => v.ccy === 'KES')
          .map((i: any) => Number(i.localamt))
          .reduce((partialSum: any, a: any) => partialSum + a, 0);

  const formatNumber = useAmountFormatter(filteredselectedAmount?.[0]?.invcurr);

  useEffect(() => {
    setAmount(
      filteredselectedAmount.reduce((a: any, b: any) => +a + b.amount, 0)
    );

    dispatch(
      updateAmountMode(
        filteredselectedAmount
          .reduce((a: any, b: any) => +a + b.amount, 0)
          .toFixed(2)
          .toString()
      )
    );
  }, [selectedCcy, usdPayableAmount, zarPayableAmount, kesPayableAmount]);

  useEffect(() => {
    if (!countryDDList || countryDDList.length < 1)
      dispatch(getCurrencyDDList());
    setSelectedCcy(filteredselectedAmount?.[0]?.invcurr);
  }, []);

  const rbac = useSelector((state: RootState) => state.userProfile);

  const countryDDList = useSelector(
    (state: RootState) => state.standaloneInvoice.countryDDList
  );

  const amountMap: any = {
    USD: formatNumber(usdPayableAmount, {
      ccy: "USD",
      round: roundingPrecision(
        filteredselectedAmount.map((i: any) => +i.amount)
      ),
    }),
    KES: formatNumber(kesPayableAmount, {
      ccy: "KES",
      round: roundingPrecision(
        filteredselectedAmount.map((i: any) => +i.amount)
      ),
    }),
    ZA: formatNumber(zarPayableAmount, {
      ccy: "ZAR",
      round: roundingPrecision(
        filteredselectedAmount.map((i: any) => +i.amount)
      ),
    }),
  };

  const invoiceCountry =
    selectedAmount[0]?.collector_code !== undefined && selectedAmount.length > 0
      ? selectedAmount[0]?.collector_code
      : dataCountry;

  useEffect(() => {
    dispatch(
      getMultiCountryAllowance({
        country: invoiceCountry,
        linerId: selectedLinerId,
      })
    );
  }, [invoiceCountry]);

  return (
    <Form.Group as={Row} controlId="payableAmount.input">
      <div
        style={{
          display: [7, 8, 10, "7", "8", "10"].includes(paymentMode)
            ? "grid"
            : "none",
          grid: "4fr 4fr 2fr/1fr 1.3fr",
          alignItems: "center",
        }}
      >
        <Form.Label className="fs--1 opacity-75 my-0">
          Invoice Amount
        </Form.Label>
        <Form.Label className="fs--1 opacity-75 my-0 mx-1">
          {filteredselectedAmount?.[0]?.invcurr}
          <span className="mx-2 my-0">{Number(amount).toFixed(2)}</span>
        </Form.Label>
        <Form.Label className="fs--1 opacity-75 my-0">Platform Fees</Form.Label>
        <Form.Label
          className={`fs--1 my-0 mx-1 ${false ? "loadingPulse" : "opacity-75"}`}
        >
          {filteredselectedAmount?.[0]?.invcurr}
          <span className="mx-2 my-0">{formatNumber(`${platformfee}`)}</span>
        </Form.Label>
      </div>
      <Form.Label column lg={5} className="fs--1">
        {t("resource_2:payableAmount")}
      </Form.Label>
      <Col lg={6}>
        <InputGroup>
          {multiCurrencyAllow && !isNaN(kesPayableAmount) ? (
            <div className="d-flex py-1">
              {countryDDList.map((item: any) => (
                <div className="pe-5">
                  <p className="text-600 fs--1 fw-medium mb-0">
                    {item.currencyCode}
                  </p>{" "}
                  <h4 className="text-800 mb-0">
                    {amountMap?.[item.currencyCode] ?? ""}{" "}
                  </h4>
                </div>
              ))}
            </div>
          ) : (
            <>
              <Col lg={5}>
                <Form.Select
                  value={selectedCcy}
                  onChange={(e) => setSelectedCcy(e.target.value)}
                  disabled={true}
                >
                  <option value={filteredselectedAmount?.[0]?.invcurr}>
                    {filteredselectedAmount?.[0]?.invcurr}
                  </option>
                </Form.Select>
              </Col>
              <Col lg={7}>
                <Form.Control
                  aria-label={t("resource_2:payableAmount")}
                  aria-describedby="payable-amount"
                  value={formatNumber(
                    amount +
                      ([7, 8, 10, "7", "8", "10"].includes(paymentMode)
                        ? platformfee ?? 0
                        : 0),
                    {
                      round: roundingPrecision([
                        ...filteredselectedAmount.map((i: any) => +i.amount),
                        +platformfee,
                      ]),
                    }
                  )}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    dispatch(updateAmountMode(e.target.value));
                  }}
                  disabled={true}
                />
              </Col>
            </>
          )}
        </InputGroup>
      </Col>
      <Col lg={1}>
        {/*<Button variant="secondary" disabled={disableFields}>
          <FontAwesomeIcon icon={faSave} className="me-1" />
          <span>{t("resource_1:pay")}</span>
        </Button>
  */}
      </Col>
    </Form.Group>
  );
};

export default PayableAmount;
