import { useTranslation } from 'react-i18next';
import DataTable from '../../common/components/DataTable';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomerCodeDataList } from './MappingActions';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';

const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';

const CustomerCodeList = () => {
  const { t } = useTranslation();
  const data = useSelector((state:RootState)=>state.mappingDashboard.customerCodeDataList)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const linerOptions = useSelector((state:RootState)=>state.registration.linerOptions)
  const {custId} = useParams()
  
  useEffect(()=>{
    if(custId)
    dispatch(getCustomerCodeDataList({custId}))
  },[custId])

  const columnConfig = [
    {
      accessor: 'id',
      Header: '',
      width: 0,
      maxWidth: 0,
    },
    {
      accessor: 'liner',
      Header: t('resource_1:liner'),
      width: 180,
      maxWidth: 180,
      Cell: (rowData:any)=>{
        const {liner} = rowData.row.original
        return <span className='fs--1'>{linerOptions.find((i:any)=>i.value == liner)?.text??liner}</span>
      }
    },
    {
      accessor: 'customerCode',
      Header: t('resource_1:customercode'),
      width: 180,
      maxWidth: 180
    },
    {
      accessor: 'matchCode',
      Header: 'Match Code',
      width: 180,
      maxWidth: 180,
    },
  ];

  return (
    <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>

          <Button
            variant='falcon-primary'
            style={{
                width: '160px',
                marginRight: '10px',
                marginBottom: '10px',
            }}
            size='sm'
            onClick={() => {
                navigate(`${APP_PATH_PREFIX}/mappingdashboard/customerdetails/${custId}/addcode`)
            }}>
            <FontAwesomeIcon icon={faPlus} className='me-2 mt-1 ' />
            <span>Customer Code</span>
        </Button>

      <DataTable
        gridName='CUSTOMERCODE'
        columns={columnConfig}
        data={data}
        shortPrepend='qp_'
        perPage={100}
        sortable={true}
        searchable={false}
        showColumnFilter={false}
        selection={false}
        pagination={false}
        seekPagination={false}
        showExport={false}
        localFilter={true}
        uniqueFilterKey='userList'
        autoHeight={true}
        maxHeight='35vh'
        tableMinHeight={300}
        staticHiddenCols={['id']}
      />


    </div>
  );
};

export default CustomerCodeList;
