import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { faRefresh, faSave } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { useBackNavigation } from '../../../hooks/BackNavigation';
import useDataCountry from '../../../hooks/useDataCountry';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { populateEditUserData, setUserEditDetails } from './MappingSlice';
import { useParams } from 'react-router-dom';
import { getGroupTypes, getUserDataList, resetPassword, saveUserDetails } from './MappingActions';


const EditUserSlider = () => {
  const { t } = useTranslation();

  const onBack = useBackNavigation();
  const dataCountry = useDataCountry();

  const profileDetails = useSelector((state: RootState) => state.userProfile.profile);
  const companyCountry = useSelector((state: RootState) => state.mappingDashboard.companyDetails.country)
  const groupList = useSelector((state: RootState) => state.mappingDashboard.groupList)
  const dispatch = useDispatch<AppDispatch>()
  const formState = useSelector((state: RootState) => state.mappingDashboard.userEditingState)
  const { userId, custId } = useParams()
  const userListData = useSelector((state: RootState) => state.mappingDashboard.userListData)

  const onResetPassword = () => {
    if (userId && formState.email) {
      dispatch(resetPassword({
        loginId: formState.email,
        mstUsrId: userId
      })).unwrap().then(()=>{
        onBack();
      })
    } else {
      console.error('Error: userId or formState.email is null or undefined in EditUserSlider.tsx')
    }
  };
  const language = useSelector((state:RootState)=> state.navbarTop.language);

  useEffect(() => {
    i18next.changeLanguage(
      `${dataCountry}_${language}`
    );
  }, [dataCountry,language]);

  useEffect(() => {
    dispatch(populateEditUserData(userId ?? null))
    dispatch(getGroupTypes(companyCountry))
  }, [userId, userListData, companyCountry])

  return (
    <Card className='h-100 overflow-auto'>

      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={onBack}
        />
        <h2 className='fs-0 text-primary mb-1'>{userId ? t("resource_2:editUser") : t("resource_2:addUser")}</h2>
      </Card.Header>

      <Card.Body>
        <div className='row'>
          <div className='col-lg-6 mb-3'>
            <Form.Label className='fs--1'>
              {t('resource_2:emailID')}
            </Form.Label>
            <Form.Control
              aria-label={t('resource_2:emailID')}
              className='fs--1'
              disabled={!!userId}
              value={formState.email}
              onChange={(e) => {
                dispatch(setUserEditDetails({
                  email: e.target.value.trim().toLowerCase()
                }))
              }}
            >
            </Form.Control>
          </div>

          <div className='col-lg-6 mb-3'>
            <Form.Label className='fs--1'>
              {t(t('resource_2:userName'))}
            </Form.Label>
            <Form.Control
              aria-label={t(t('resource_2:userName'))}
              className='fs--1'
              disabled={false}
              value={formState.name}
              onChange={(e) => {
                dispatch(setUserEditDetails({
                  name: e.target.value
                }))
              }}
            >
            </Form.Control>
          </div>

          <div className='col-lg-12 mb-3'>
            <Form.Label className='fs--1'>
              {'User Group'}
            </Form.Label>
            <Select
              onChange={(u: any) => {
                dispatch(setUserEditDetails({
                  group: u.map((i: any) => i.value),
                  userGrpName: u.map((i: any) => i.label)

                }))
              }}
              options={groupList}
              className={`fs--1`}
              classNames={{
                control: (state: any) => {
                  return `fs--1 m-0 p-0 asyncDropField
                  ${state.isDisabled ? '' : 'dropControl form-control'}
                  ${state.isFocused ? 'focusField' : ''}`;
                },
              }}
              styles={{
                menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                control: (_: any) => ({}),
                dropdownIndicator: (base: any, state: any) => (state.isDisabled ? { display: 'none' } : { ...base }),
                menu: (base: any) => ({ ...base, zIndex: 999 })
              }}
              isDisabled={false}
              noOptionsMessage={() => 'No group'}
              isMulti={true}
              autoFocus={true}
              isSearchable={true}
              value={formState.group?.map((i) => ({ value: i, label: groupList.find((x) => x.value == i)?.label ?? '' }))}
            />
          </div>

          {userId && false && <div className='col-lg-6 mb-3'>
            <Form.Label className='fs--1'>
              {t(t('resource_2:changePassword'))}
            </Form.Label>
            <Form.Control
              aria-label={t(t('resource_2:changePassword'))}
              className='fs--1'
              disabled={false}
              value={formState.password}
              onChange={(e) => {
                dispatch(setUserEditDetails({
                  password: e.target.value
                }))
              }}
            >
            </Form.Control>
          </div>}

          <div className='col-lg-6 mb-3'>
            <Form.Label className='fs--1'>
              {t('resource_1:status')}
            </Form.Label>
            <Form.Select
              aria-label={t('resource_1:status')}
              className='fs--1'
              disabled={false}
              value={formState.status ? 1 : 0}
              onChange={(e) => {
                dispatch(setUserEditDetails({
                  status: e.target.value == '1'
                }))
              }}
            >
              <option key={1} value={1}>Active</option>
              <option key={0} value={0}>Inactive</option>

            </Form.Select>
          </div>

        </div>

        <div className='d-flex justify-content-center align-items-center'>
          <Button
            className='me-2'
            variant='falcon-primary'
            size='sm'
            disabled={
              formState.group?.length == 0
              || formState.email.trim() == ''
              || (formState.password !== '' && (
                formState.password.length < 8 ||
                !formState.password.match(/^(?=.*[a-z])/) ||
                !formState.password.match(/^(?=.*[A-Z])/) ||
                !formState.password.match(/^(?=.*[0-9])/)
              ))
            }
            onClick={() => {

              dispatch(saveUserDetails({ custId })).unwrap().then(() => {
                onBack()
                dispatch(getUserDataList({ custId }))
              })
            }}
          >
            <FontAwesomeIcon icon={faSave} className='me-2' />
            {t('resource_1:submit')}
          </Button>
          {
            userId &&
            <Button
              className='me-2'
              variant='falcon-primary'
              size='sm'
              disabled={false}
              onClick={() => { onResetPassword() }}
            >
              <FontAwesomeIcon color='#f5822a' icon={faRefresh} className='me-2' />
              {t('Reset Password')}
            </Button>
          }
        </div>

      </Card.Body>

    </Card>
  );
};

export default EditUserSlider;
