/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";

import JSZip from "jszip";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import queryString from "query-string";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Dropdown, Form, InputGroup } from "react-bootstrap";

import { AnyAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleQuestion,
  faCreditCard,
  faEnvelope,
  faHandPointRight,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAdd,
  faDownload,
  faFileCsv,
  faFileInvoice,
  faGear,
  faMailBulk,
  faPlus,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import axiosInstance from "../../../../axiosSetup";
import { AESEncrypt } from "../../../../encrypt-util";
import Flex from "../../../../components/common/Flex";
import { AppDispatch, RootState } from "../../../store";
import { showNotificationModal } from "../../slices/navbarTop";
import { BLDetailsList } from "../../../features/dashboard/types";
import { generateActiveColuns } from "../../../utils/columnConfig";
import { updateInitialLoadLocalStorage } from "../../slices/filterView";
import UploadIndicator from "../../../../components/common/UploadIndicator";
import useReportGridName from "../../../features/reportDashboard/useReportGridName";
import { selectReportInstruction } from "../../../features/reportDashboard/reportSlice";
import SnoozedToButton from "../../../features/instructionDashboard/components/SnoozedTo";
import AssignedToButton from "../../../features/instructionDashboard/components/AssignedTo";
import {
  roundingPrecision,
  useAmountFormatter,
} from "../../../../hooks/useAmountFormatter";
import AddInstruction from "../../../features/instructionDashboard/components/AddInstruction";
import {
  useBlDashName,
  useInstDashName,
  useInvDashName,
} from "../../../../hooks/useDashName";
import {
  updateDataTableFilterState,
  updateParams,
  updateSelectedConfigId,
  updateSortStateDataTable,
} from "../DataTable/slices/DataTable";
import {
  exportDashboardData,
  getBlsListExportCSV,
  getDashboardData,
  getInvoiceListExportCSV,
} from "../../../features/dashboard/actions/dashboard";
import {
  toggleAddAgent,
  toggleNominationReq,
  updateCurrentPageInstructionDashboard,
  updatePrveDependenciesBlDashboard,
} from "../../../features/dashboard/slices/dashboardSlice";
import {
  updateSelectedAmount,
  updateSelectedBlListMultiPay,
  updateSelectedBlListMultiPayTable,
  updateSelectedRowsInvoice,
} from "../../../features/multiBlPayment/slices/multiBlPaymentSlice";

import "react-datepicker/dist/react-datepicker.min.css";

interface IProps {
  toggleColumnSettings?: (v: boolean) => AnyAction;
  showColumnSettings?: boolean;
  showPayButton?: boolean;
  showUploadButton?: boolean;
  showAddFundButton?: boolean;
  showExport?: boolean;
  dashboardName?: string;
  showNomination?: boolean;
  data?: any;
  columns?: any;
  showInstruction?: boolean;
  columnSettingsPersistKey?: string;
  dataLoading?: boolean;
  configViews?: any[];
  uniqueFilterKey?: string;
  showRightDiv?: boolean;
  loadList?: (pageUpdate: boolean) => void;
  gridDef?: any[];
  showAddUserButton?: boolean;
  showAddInstruct?: boolean;
  showAmount?: boolean;
  showReportDropdown?: boolean;
  child?: any;
}

const FilterView = ({
  loadList,
  toggleColumnSettings,
  showColumnSettings,
  showPayButton,
  dashboardName,
  showAddFundButton,
  showExport,
  showNomination,
  showInstruction,
  dataLoading,
  configViews,
  uniqueFilterKey,
  showRightDiv = true,
  data = { result: [] },
  gridDef,
  showAddUserButton,
  showAddInstruct = false,
  showAmount = false,
  showReportDropdown = false,
  child = null,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const instDashboardName = useInstDashName();
  const invDashboardName = useInvDashName();
  const blDashboardName = useBlDashName();

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: null,
    search: null,
    grids: null,
  });

  const { t } = useTranslation();
  const grids = useSelector((state: RootState) => state.dataTable.grids);
  const mappingGrids = useSelector(
    (state: RootState) => state.dataTable.mappingGrids
  );
  const paymentGrids = useSelector(
    (state: RootState) => state.dataTable.paymentGrids
  );

  const receiptGrids = useSelector(
    (state: RootState) => state.dataTable.receiptGrids
  );

  const emailGrids = useSelector(
    (state: RootState) => state.dataTable.emailGrids
  );

  // const currentGridsName = useSelector(
  //   (state: RootState) => state.dashboard.currentGridName
  // );
  const selectedInst = useSelector(
    (state: RootState) => state.reportDashboard.selectedInstruct
  );
  const invoiceGrids = useSelector(
    (state: RootState) => state.dataTable.invoiceGrids
  );
  const standingInstructionGrids = useSelector(
    (state: RootState) => state.dataTable.standingInstructionGrids
  );
  const registrationMasterGrid = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const paymentFundGrids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );
  const gateInGateOut = useSelector(
    (state: RootState) => state.dataTable.getInGetOutGrids
  );
  const reportList = useSelector(
    (state: RootState) => state.reportDashboard.reportList
  );
  const instructionsGrids = useSelector(
    (state: RootState) => state.dataTable.instructionsGrids
  );
  const reportGrids = useSelector(
    (state: RootState) => state.dataTable.reportGrids
  );
  const { reportGridName, reportGridFilterKey } = useReportGridName();
  const fileInputRef = useRef(null);
  const userGrids = useSelector(
    (state: RootState) => state.dataTable.userGrids
  );

  const selectedBls = useSelector(
    (state: RootState) => state.multiBlPayment.selectedRowsMultiPay
  );
  const savedViewValue: any = localStorage.getItem(
    `selectedConfigId_${dashboardName}`
  );

  const [csvLabels, setCsvLabels] = useState<
    { accessor: string; label: string; timeFormat: string; dataType?: string }[]
  >([]);
  const [exportCsvLabels, setExportCsvLabels] = useState<
    { accessor: string; label: string; dataType?: string }[]
  >([]);

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const userGridDefinitions = useSelector((state: RootState) => {
    switch (state.navbarTop.selectedDashbaordName) {
      case blDashboardName:
        return state.dataTable.grids;
      case "INV_DASHBOARD":
        return state.dataTable.invoiceGrids;
      case "INST_DASHBOARD":
        return state.dataTable.instructionsGrids;
      case "REGISTRATION_DASHBOARD":
        return state.dataTable.registrationMasterGrid;
      case "PAYMENT_FUNDING":
        return state.dataTable.paymentFundGrids;
      case "USER_DASHBOARD":
        return state.dataTable.userGrids;
      case "STANDING_INSTRUCTION":
        return state.dataTable.standingInstructionGrids;
      case "PAYMENT_DASHBOARD":
        return state.dataTable.paymentGrids;
      case "PAYMENT_RECEIPT":
        return state.dataTable.receiptGrids;
      case "EMAIL_TEMPLATE_DASHBOARD":
        return state.dataTable.emailGrids;
      case reportGridName:
        return state.dataTable.reportGrids;
      case "CUSTOMER_DASHBOARD":
        return state.dataTable.mappingGrids;
      case "GET_IN_OUT_DASHBOARD":
        return state.dataTable.getInGetOutGrids;

      default:
        return null;
    }
  });

  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );

  const currentGridName = useSelector(
    (state: RootState) => state.dashboard.currentGridName
  );
  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageInstructionDashboard(page));
  };

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(",")
    ?.join?.(" ");

  const [isUnderlined, setIsUnderlined] = useState(false);

  const linkStyle = {
    cursor: "pointer",
    fontSize: "13px",
    fontStyle: "italic",
    textDecoration: isUnderlined ? "underline" : "none",
  };

  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const [dropdownDownVisible, setdropdownDownVisible] = useState(false);
  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem("@user-selected-datacountry") || "{}"
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry = storedCountry
    ? storedCountry
    : linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;

  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageInstruction
  );
  const currentPageRegistration = useSelector(
    (state: RootState) => state.dashboard.currentPageRegistration
  );

  /* const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  ); */
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedData = useSelector(
    (state: RootState) => state.dashboard.selectedData
  );

  const selectedinstIdList: any = selectedData.map((item: { inst_id: any }) => {
    return item.inst_id;
  });

  const userGridDefinitionConfig = userGridDefinitions?.find(
    (item: any) => item.gridName === dashboardName
  )?.columnConfigGrid;

  const selectedAmount = useSelector(
    (state: RootState) => state.multiBlPayment.selectedAmountAll
  );
  const rbac = useSelector((state: RootState) => state.userProfile);
  const filteredselectedAmount = selectedAmount.filter(
    (item: { invcurr: any; id: any; localamount: any }) =>
      item.invcurr === selectedAmount[0]?.invcurr &&
      (item?.id?.substring(0, 2) == "CD") ==
        (selectedAmount[0]?.id.substring(0, 2) == "CD") &&
      isNaN(item.localamount) == isNaN(selectedAmount[0].localamount)
  );

  const paidUnpaidFilter =
    filteredselectedAmount.some(
      (obj: { amount: string }) => obj.amount === "0"
    ) &&
    filteredselectedAmount.some(
      (obj: { amount: string }) => obj.amount !== "0"
    );
  const includesPropertyValue = (array: any, propertyName: any, value: any) => {
    return array.some(
      (item: { [x: string]: any }) => item[propertyName] === value
    );
  };

  const lengthCheck = filteredselectedAmount > 0;
  const checkForPaid = includesPropertyValue(
    filteredselectedAmount,
    "amount",
    "0"
  );
  const enableAddAgentButton = useSelector(
    (state: RootState) => state.dashboard.enableAddAgentButton
  );

  const configViewDependency = JSON.stringify(configViews) || "";
  const prevselectedConfigId = useSelector(
    (state: RootState) => state.dashboard.prevDependencies.prevselectedConfigId
  );
  const setPrevPageInstructionDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevPageInstructionDashbaord",
        value: state,
      })
    );
  };
  const setPrevPageRegistrationDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevPageRegistrationDashbaord",
        value: state,
      })
    );
  };

  const prevPageInstructionDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageInstructionDashbaord
  );
  const prevPageRegistrationDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageRegistrationDashbaord
  );
  const setPrevselectedConfigId = (loc: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: "prevselectedConfigId",
        value: loc,
      })
    );
  };

  useEffect(
    () => {
      const savedViewValueNew: any = localStorage.getItem(
        `selectedConfigId_${dashboardName}`
      );

      const newValue = savedViewValueNew;
      const newdata = location.pathname.includes("instruction")
        ? instructionsGrids[0]?.configGroup?.find(
            (c: any) => c.userGridId == newValue
          )?.columnConfigGrid
        : location.pathname.includes("paymentDashboard")
        ? paymentGrids[0]?.configGroup?.find(
            (c: any) => c.userGridId == newValue
          )?.columnConfigGrid
        : location.pathname.includes("payment")
        ? invoiceGrids[0]?.configGroup?.find(
            (c: any) => c.userGridId == newValue
          )?.columnConfigGrid
        : location.pathname.includes("standing")
        ? standingInstructionGrids[0]?.configGroup?.find(
            (c: any) => c.userGridId == newValue
          )?.columnConfigGrid
        : location.pathname.includes("registrationmaster")
        ? registrationMasterGrid[0]?.configGroup?.find(
            (c: any) => c.userGridId == newValue
          )?.columnConfigGrid
        : location.pathname.includes("users")
        ? userGrids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
            ?.columnConfigGrid
        : grids[0]?.configGroup?.find((c: any) => c.userGridId == newValue)
            ?.columnConfigGrid;

      if (newdata) {
        if (JSON.parse(newdata)[0].sorts) {
          const input = `${JSON.parse(newdata)[0].sorts}`;
          const [firstWord, lastWord]: any = input.match(/\w+/g) || [];
          dispatch(
            updateSortStateDataTable({
              key: firstWord,
              value: lastWord,
            })
          );
          dispatch(
            updateParams({
              key: firstWord,
              value: lastWord,
            })
          );
          const queryParams = queryString.parse(location.search);
          const sortString = {
            sort: `${firstWord},${lastWord}`,
            seek: undefined,
            direction: undefined,
          };
          setTimeout(() => {
            navigate(
              `${location.pathname}?${queryString.stringify({
                ...queryParams,
                ...sortString,
              })}`
            );
          }, 100);
        } else {
          // dispatch(
          //   updateSortStateDataTable({
          //     key: null,
          //   })
          // )
          // navigate(
          //   `${location.pathname}`
          // );
        }
      }
    },
    [
      /*
  grids,
  invoiceGrids,
  paymentGrids,
  instructionsGrids,
  userGrids,
  standingInstructionGrids,
  registrationMasterGrid,
  */
    ]
  );

  useEffect(() => {
    const defaultViewId =
      /*    configViews?.find?.((v: any) =>
        v?.columnConfigName?.toLowerCase?.().includes('default')
      )?.userGridId || null; */
      configViews?.find?.(
        (v: any) => v?.type === "system" && v?.columnConfigName[0]
      )?.userGridId || null;

    const configViewIds = configViews?.map?.((i: any) => i.userGridId) || [];

    if (
      typeof uniqueFilterKey === "string" ||
      typeof uniqueFilterKey === "number"
    ) {
      dispatch(updateInitialLoadLocalStorage(false));
      dispatch(
        updateSelectedConfigId({
          ...selectedConfigId,
          [uniqueFilterKey]:
            savedViewValue &&
            configViewIds?.includes?.(parseInt(savedViewValue))
              ? savedViewValue
              : defaultViewId,
        })
      );
    }
  }, [configViewDependency, savedViewValue, location.pathname]);

  useEffect(() => {
    const selectedConfigVal = configViews?.find?.(
      (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey || ""]
    );
    let filterValue: any = {};

    if (
      selectedConfigId &&
      JSON.stringify(selectedConfigId) !== prevselectedConfigId
    ) {
      setPrevselectedConfigId(JSON.stringify(selectedConfigId));
      if (configViews && selectedConfigId) {
        if (selectedConfigVal?.columnConfigGrid) {
          for (let c of JSON.parse(selectedConfigVal?.columnConfigGrid)) {
            if (c?.filterValues?.[0]?.label && c?.filterValues?.[0]?.accessor) {
              filterValue[c.filterValues[0].accessor] = JSON.parse(
                c.filterValues[0].label
              );
            } else {
              filterValue[c.accessor] = "";
            }
          }
        }

        if (selectedConfigId?.[uniqueFilterKey || ""] && selectedConfigVal) {
          // Todo: Invoice Dashboard reload issue can be resolved here
          if (uniqueFilterKey) {
            setTimeout(() => {
              dispatch(
                updateDataTableFilterState({
                  ...filterState,
                  [uniqueFilterKey]: filterValue,
                })
              );
            }, 500);
          }
        } else if (
          uniqueFilterKey &&
          selectedConfigId?.[uniqueFilterKey] === null
        ) {
          if (uniqueFilterKey) {
            //
            dispatch(
              updateDataTableFilterState({
                ...filterState,
                [uniqueFilterKey]: {},
              })
            );
          }
        }
      }
    }
  }, [selectedConfigId]);

  const _toggleAddAgent = () => {
    dispatch(toggleNominationReq(false));
    dispatch(toggleAddAgent(true));
  };

  const totalAmount: any = filteredselectedAmount.reduce(
    (accumulator: any, currentObj: any) => {
      const amountAsNumber: number = parseFloat(currentObj.amount);
      if (!isNaN(amountAsNumber)) {
        return accumulator + amountAsNumber;
      }
      return accumulator;
    },
    0
  );

  const formatNumber = useAmountFormatter(filteredselectedAmount?.[0]?.invcurr);

  const formattedTotalAmount: string = formatNumber(totalAmount, {
    round: roundingPrecision(filteredselectedAmount.map((i: any) => +i.amount)),
  });

  const navigate = useNavigate();

  const onShowColumnSettings = () => {
    if (toggleColumnSettings) dispatch(toggleColumnSettings(true));
  };

  // Function to export data as a file
  const exportData = (data: any, fileName: string, type: string) => {
    try {
      // Create a link and download the file
      const blob = new Blob([data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("File downloaded successfully", {
        theme: "colored",
      });
    } catch (error) {
      console.error(error);
      toast.error("something went wrong", {
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    const selectedConfigVal = configViews?.find?.(
      (c) => c.userGridId == selectedConfigId?.[uniqueFilterKey || ""]
    )?.columnConfigGrid;
    if (selectedConfigVal) {
      const generateHeaderLabel = (s: any) => {
        let count = 1;
        for (let i in s) {
          if (s.charAt(i) === s.charAt(i).toUpperCase()) {
            count++;
          }
        }
        return count ? t(`resource_${count}:${s}`) : s;
      };

      if (dashboardName == "INV_DASHBOARD")
        if (invoiceGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(invoiceGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }

      if (dashboardName == blDashboardName)
        if (grids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(grids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "PAYMENT_DASHBOARD")
        if (paymentGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(paymentGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "PAYMENT_RECEIPT")
        if (receiptGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(receiptGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "EMAIL_TEMPLATE_DASHBOARD")
        if (emailGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(emailGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "CUSTOMER_DASHBOARD")
        if (mappingGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(mappingGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == instDashboardName)
        if (instructionsGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(instructionsGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "PICKUP_LOC_INSTRACT_REPORT")
        if (reportGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(reportGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "REGISTRATION_DASHBOARD")
        if (registrationMasterGrid[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(registrationMasterGrid?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "STANDING_INSTRUCTION")
        if (standingInstructionGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(standingInstructionGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }
      if (dashboardName == "PAYMENT_FUNDING")
        if (paymentFundGrids[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(paymentFundGrids?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }

      if (dashboardName == "GET_IN_OUT_DASHBOARD")
        if (gateInGateOut[0]) {
          const finalArray: any = [];
          JSON.parse(selectedConfigVal).forEach((obj1: any) => {
            const obj2 = JSON.parse(
              JSON.parse(gateInGateOut?.[0]?.columnConfigGrid)
            ).find((item: any) => item.accessor === obj1.accessor);
            if (obj2) {
              finalArray.push({ ...obj1, dataType: obj2.dataType });
            }
          });
          if (finalArray.length > 0) {
            const labels = finalArray
              .filter((v: any) => v.visible)
              .map((d: any) => ({
                accessor: d.accessor,
                label: d?.accessor ? generateHeaderLabel(d.accessor) : "",
                dataType: d?.dataType,
              }));
            setCsvLabels(labels);
          }
        }

      // const labels = JSON.parse(selectedConfigVal)
      //   .filter((v: any) => v.visible)
      //   .map((d: any) => ({
      //     accessor: d.accessor,
      //     label: d?.accessor ? generateHeaderLabel(d.accessor) : '',
      //     dataType: d?.dataType,
      //   }));
      // setCsvLabels(labels);
    } else {
      if (userGridDefinitionConfig)
        setCsvLabels(
          generateActiveColuns(JSON.parse(JSON.parse(userGridDefinitionConfig)))
        );
    }
  }, [configViews, selectedConfigId]);

  const openLink = () => {
    {
      window.open(
        "https://www.transnetportterminals.net/Ports/Pages/Terminal%20Updates.aspx "
      );
    }
  };

  const exportDataAsCSV = () => {
    let csvData;
    let fileName = `${dashboardName}.csv`;
    if (data.result) {
      if (csvLabels?.length > 0) {
        csvData = Papa.unparse(
          data.result.map((v: any) => {
            let finalOut: any = {};

            for (let i of csvLabels) {
              const val = v?.[i.accessor];
              switch (i.dataType) {
                case "date":
                  finalOut[i.label] = !val
                    ? ""
                    : new Date(val).toLocaleDateString(
                        new Intl.DateTimeFormat().resolvedOptions().locale,
                        { timeZone: "UTC" }
                      );
                  break;

                case "datetime":
                  finalOut[i.label] = !val
                    ? ""
                    : new Date(val).toLocaleString();
                  break;

                default:
                  finalOut[i.label] = v?.[i.accessor];
              }
            }
            return finalOut;
          }),
          { columns: csvLabels.map((m) => m.label) }
        );
        exportData(csvData, fileName, "text/csv;");
      }
    } else {
      if (csvLabels?.length > 0) {
        csvData = Papa.unparse(
          data.result.map((v: any) => {
            let finalOut: any = {};

            for (let i of csvLabels) {
              finalOut[i.label] =
                i?.timeFormat && i?.timeFormat !== "null"
                  ? moment(v?.[i.accessor] + " UTC").format(i.timeFormat)
                  : v?.[i.accessor];
            }

            return finalOut;
          }),
          { columns: csvLabels.map((m) => m.label) }
        );
        exportData(csvData, fileName, "text/csv;");
      }
    }
  };
  // const exportDataAsCSV2 = () => {
  //   const jsonData = {
  //     bulkPayment: {
  //       bulkPayment: [
  //         {
  //           'Invoice No': ' ',
  //           'Inv Amount': '',
  //           'Payment Reference No': '',
  //         },
  //       ],
  //     },
  //   };

  //   const workbook = XLSX.utils.book_new();

  //   const worksheet = XLSX.utils.json_to_sheet(
  //    jsonData.bulkPayment.bulkPayment
  //  );

  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: 'xlsx',
  //     type: 'array',
  //   });

  //   const excelBlob = new Blob([excelBuffer], {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   });

  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(excelBlob);
  //   link.download = 'BULK_Payment.xlsx';

  //   document.body.appendChild(link);
  //   link.click();

  //   document.body.removeChild(link);
  // };
  const tooltipContent = filteredselectedAmount.map(
    (item: any) => `● ${item.id} `
  );

  const formattedContent = tooltipContent.join("\n");

  const showChooseConfigView = () => {
    const types = configViews?.map((v) => v.type);
    const systemConfigsLength =
      configViews &&
      configViews?.filter?.((v) => v?.type === "system")?.length > 1
        ? true
        : false;
    return (
      configViews &&
      configViews?.length > 0 &&
      (types?.includes("shared") ||
        types?.includes("private") ||
        (types?.includes("system") && systemConfigsLength))
    );
  };
  //const filterStateLocal = filterState?.['instDashboard'];

  const filterStateLocal =
    gridDef?.[0]?.gridName === blDashboardName
      ? filterState?.["mainDashboard"]
      : gridDef?.[0]?.gridName === "INV_DASHBOARD"
      ? filterState?.["paymentOne"]
      : gridDef?.[0]?.gridName === "INST_DASHBOARD"
      ? filterState?.["instDashboard"]
      : gridDef?.[0]?.gridName === "USER_DASHBOARD"
      ? filterState?.["userDetails"]
      : gridDef?.[0]?.gridName === "PAYMENT_DASHBOARD"
      ? filterState?.["paymentTwo"]
      : gridDef?.[0]?.gridName === "PAYMENT_RECEIPT"
      ? filterState?.["receipt"]
      : gridDef?.[0]?.gridName === "EMAIL_TEMPLATE_DASHBOARD"
      ? filterState?.["emailDashboard"]
      : gridDef?.[0]?.gridName === "CUSTOMER_DASHBOARD"
      ? filterState?.["mapingDashboard"]
      : gridDef?.[0]?.gridName === reportGridName
      ? filterState?.[reportGridFilterKey]
      : gridDef?.[0]?.gridName === "REGISTRATION_DASHBOARD"
      ? filterState?.["adminDashboard"]
      : gridDef?.[0]?.gridName === "STANDING_INSTRUCTION"
      ? filterState?.["standingInst"]
      : gridDef?.[0]?.gridName === "PAYMENT_FUNDING"
      ? filterState?.["paymentFund"]
      : gridDef?.[0]?.gridName === "GET_IN_OUT_DASHBOARD"
      ? filterState?.["gateinout"]
      : "";

  useEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      const currentState = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      setPreviousState(currentState);
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    if (currentPage && currentPage !== prevPageInstructionDashbaord) {
      setPrevPageInstructionDashbaord(currentPage);
    }
  }, [currentPage]);
  useEffect(() => {
    if (
      currentPageRegistration &&
      currentPageRegistration !== prevPageRegistrationDashbaord
    ) {
      setPrevPageRegistrationDashbaord(currentPage);
    }
  }, [currentPageRegistration]);

  useEffect(() => {
    const lables = csvLabels.map((d: any) => ({
      accessor:
        d?.dataType === "date" ||
        d?.dataType === "datetime" ||
        d?.dataType === "number"
          ? `${d?.accessor}_string`
          : d?.accessor,
      label: d?.label,
      dataType: d?.dataType,
    }));
    setExportCsvLabels(lables);
  }, [csvLabels]);

  const charLimit = (name: string, limit: number) =>
    name && name?.length >= limit ? name?.substring(0, limit) + "..." : name;

  const APP_PATH_PREFIX = process.env.APP_PATH_PREFIX || '';

  const loadDashboardData = (filterUpdated: boolean) => {
    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || "",
              filterStateLocal?.[k]?.to || "",
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === "string"
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (profileDetails) {
            dispatch(
              exportDashboardData({
                gridName: currentGridName,
                payload: {
                  fields: exportCsvLabels.map((c) => c?.accessor),
                  labels: csvLabels.map((c) => c?.label),
                  filters: !selectedTopSearchFilter?.where
                    ? finalFilterApiState
                    : {},

                  size: 20,
                  sorts: sort
                    ? [sort]
                    : currentGridName === blDashboardName
                    ? ["lastupdateddate desc"]
                    : currentGridName === invDashboardName
                    ? ["invid desc"]
                    : currentGridName === instDashboardName
                    ? ["timestamp desc"]
                    : currentGridName === "USER_DASHBOARD"
                    ? ["user_id desc"]
                    : currentGridName === "REGISTRATION_DASHBOARD"
                    ? ["submissiondate desc"]
                    : currentGridName === "PAYMENT_DASHBOARD"
                    ? ["pmtmode desc"]
                    : currentGridName === "PAYMENT_RECEIPT"
                    ? ["receipt_id desc"]
                    : // : currentGridName === 'EMAIL_TEMPLATE_DASHBOARD'
                    currentGridName === "EMAIL_LOGS"
                    ? ["email_logs_id desc"]
                    : currentGridName === "CUSTOMER_DASHBOARD"
                    ? ["id desc"]
                    : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                    ? ["bl_no desc"]
                    : currentGridName === "STANDING_INSTRUCTION"
                    ? ["standing_instruction_id desc"]
                    : currentGridName === "PAYMENT_FUNDING"
                    ? ["payment_funding_id desc"]
                    : currentGridName === "GATE_DASHBOARD_CUSTOMER"
                    ? ["bl_id desc"]
                    : "",
                  page: filterUpdated ? 1 : currentPage,
                },
                asCsv: true,
                dataCountry: dataCountry,
                partnerId:
                  profileDetails?.partnerType === "customer"
                    ? profileDetails?.partnerId
                    : null,
                where: gridDef?.[0]?.whereClause
                  ? AESEncrypt(gridDef?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ""
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            )
              .unwrap()
              .then((response: any) => {
                if (response === "success") {
                  toast.success(
                    "CSV file has been shared successfully to your email ID."
                  );
                }
              });
          }
        }
      } else {
        if (
          profileDetails &&
          ((profileDetails.partnerType === "customer" &&
            profileDetails.partnerId) ||
            profileDetails?.partnerType === "liner")
        ) {
          dispatch(
            exportDashboardData({
              gridName: currentGridName,
              payload: {
                fields: exportCsvLabels.map((c) => c?.accessor),
                labels: csvLabels.map((c) => c?.label),
                filters:
                  selectedTopSearchFilter?.where == ""
                    ? instructionTopSearchState
                    : {},

                size: 20,
                sorts: sort
                  ? [sort]
                  : currentGridName === blDashboardName
                  ? ["lastupdateddate desc"]
                  : currentGridName === invDashboardName
                  ? ["invid desc"]
                  : currentGridName === instDashboardName
                  ? ["timestamp desc"]
                  : currentGridName === "USER_DASHBOARD"
                  ? ["user_id desc"]
                  : currentGridName === "PAYMENT_DASHBOARD"
                  ? ["pmtmode desc"]
                  : currentGridName === "PAYMENT_RECEIPT"
                  ? ["receipt_id desc"]
                  : // : currentGridName === 'EMAIL_TEMPLATE_DASHBOARD'
                  currentGridName === "EMAIL_LOGS"
                  ? ["email_logs_id desc"]
                  : currentGridName === "CUSTOMER_DASHBOARD"
                  ? ["id desc"]
                  : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                  ? ["bl_no desc"]
                  : currentGridName === "REGISTRATION_DASHBOARD"
                  ? ["submissiondate desc"]
                  : currentGridName === "STANDING_INSTRUCTION"
                  ? ["standing_instruction_id desc"]
                  : currentGridName === "PAYMENT_FUNDING"
                  ? ["payment_funding_id desc"]
                  : currentGridName === "GATE_DASHBOARD_CUSTOMER"
                  ? ["bl_id desc"]
                  : "",
                page: filterUpdated ? 1 : currentPage,
              },
              asCsv: true,
              dataCountry: dataCountry,
              partnerId:
                profileDetails?.partnerType === "customer"
                  ? profileDetails?.partnerId
                  : null,
              where: gridDef?.[0]?.whereClause
                ? AESEncrypt(gridDef?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ""
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          )
            .unwrap()
            .then((response: any) => {
              if (response === "success") {
                toast.success(
                  "CSV file has been shared successfully to your email ID."
                );
              }
            });
        }
      }
    } else {
      if (
        !location.pathname.includes("blsliderinstruction/") &&
        location.pathname.includes("/home")
      ) {
        if (
          profileDetails &&
          ((profileDetails.partnerType === "customer" &&
            profileDetails.partnerId) ||
            profileDetails?.partnerType === "liner")
        ) {
          dispatch(
            exportDashboardData({
              gridName: currentGridName,
              payload: {
                fields: exportCsvLabels.map((c) => c?.accessor),
                labels: csvLabels.map((c) => c?.label),
                filters:
                  selectedTopSearchFilter?.where === ""
                    ? instructionTopSearchState
                    : {},
                size: 20,
                sorts: sort
                  ? [sort]
                  : currentGridName === blDashboardName
                  ? ["lastupdateddate desc"]
                  : currentGridName === invDashboardName
                  ? ["invid desc"]
                  : currentGridName === instDashboardName
                  ? ["timestamp desc"]
                  : currentGridName === "USER_DASHBOARD"
                  ? ["user_id desc"]
                  : currentGridName === "PAYMENT_DASHBOARD"
                  ? ["pmtmode desc"]
                  : currentGridName === "PAYMENT_RECEIPT"
                  ? ["receipt_id desc"]
                  : // : currentGridName === 'EMAIL_TEMPLATE_DASHBOARD'
                  currentGridName === "EMAIL_LOGS"
                  ? ["email_logs_id desc"]
                  : currentGridName === "CUSTOMER_DASHBOARD"
                  ? ["id desc"]
                  : currentGridName === "PICKUP_LOC_INSTRACT_REPORT"
                  ? ["bl_no desc"]
                  : currentGridName === "REGISTRATION_DASHBOARD"
                  ? ["submissiondate desc"]
                  : currentGridName === "STANDING_INSTRUCTION"
                  ? ["standing_instruction_id desc"]
                  : currentGridName === "PAYMENT_FUNDING"
                  ? ["payment_funding_id desc"]
                  : currentGridName === "GATE_DASHBOARD_CUSTOMER"
                  ? ["bl_id desc"]
                  : "",
                page: filterUpdated ? 1 : currentPage,
              },
              asCsv: true,
              dataCountry: dataCountry,
              partnerId:
                profileDetails?.partnerType === "customer"
                  ? profileDetails?.partnerId
                  : null,
              where: gridDef?.[0]?.whereClause
                ? AESEncrypt(gridDef?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ""
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          )
            .unwrap()
            .then((response: any) => {
              if (response === "success") {
                toast.success(
                  "CSV file has been shared successfully to your email ID."
                );
              }
            });
        }
      }
    }
  };
  return (
    <Card.Header className="p-0 ps-2 pe-2 d-flex justify-content-between align-items-center odex-mh-60px">
      <div className="d-flex">
        {/*    {location.pathname.split('/')[1] === 'instruction' && dataCountry === 'SG' &&  
           <SnoozedToButton  
              isDisabled={selectedData?.length <= 0 || selectedData?.length > 1 || selectedinstIdList.some((item:any)=>item !=804 )}
              selectedRows={selectedData} 
           />
          }   */}

        {location.pathname.split("/")[1] === "instruction" &&
          rbac.rbacDetails.allowInstructionAssignment === "Y" && (
            <AssignedToButton
              isDisabled={!(selectedData?.length > 0)}
              selectedRows={selectedData}
            />
          )}

        {child ? child : <></>}

        {location.pathname.split("/")[1] === "home" && (
          <AddInstruction
            isDisabled={!(selectedData?.length > 0)}
            selectedRows={selectedData}
          />
        )}
        {location.pathname.split("/")[1] === "instruction" && (
          <AddInstruction />
        )}
        {showAddInstruct && <AddInstruction />}
        {profileDetails?.partnerType !== "liner" && showNomination ? (
          <Button
            variant="falcon-primary z-inxe-agent"
            size="sm"
            onClick={_toggleAddAgent}
            disabled={!enableAddAgentButton}
            style={{
              position: "relative",
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Agent
          </Button>
        ) : (
          ""
        )}

        {showInstruction ? (
          <Button
            variant="falcon-primary z-inxe-agent"
            size="sm"
            onClick={_toggleAddAgent}
            disabled={!enableAddAgentButton}
            style={{
              position: "relative",
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            {t("resource_1:new")}
          </Button>
        ) : (
          ""
        )}

        {showAddUserButton && (
          <Button
            className=""
            variant="falcon-primary"
            size="sm"
            onClick={() =>
              navigate(`${APP_PATH_PREFIX}/users/details/${AESEncrypt("0").toString()}`)
            }
          >
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        )}

        {showAddFundButton ? (
          <Button
            variant="falcon-primary"
            onClick={() => navigate(`${APP_PATH_PREFIX}/multi-payment`)}
            disabled={!(selectedBls?.length > 0)}
            size="sm"
            style={{
              position: "relative",
            }}
            className="position-fixed"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>ADD Fund</span>
          </Button>
        ) : (
          ""
        )}

        {/*  {location.pathname === '/payment' &&
        profileDetails.partnerCountry === 'US' ? (
          <div>
            {profileDetails.partnerType != 'liner' ? (
              <div className='usdiscMes'>
                <p
                  className='mt-0 mb-0'
                  title={t('resource_message:invoicDisclaimer1')}>
                  <FontAwesomeIcon icon={faHandPointRight} />{' '}
                  {t('resource_message:invoicDisclaimer1')}
                </p>
                <p title={t('resource_message:invoicDisclaimer2')}>
                  <FontAwesomeIcon icon={faHandPointRight} />{' '}
                  {t('resource_message:invoicDisclaimer2')}
                </p>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          ''
        )} */}
        <span
          style={{
            fontSize: "14px",
            color: "#f5822a",
            fontWeight: "bold",
            marginLeft: "111px",
            position: "absolute",
            top: "15px",
            width: "47%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            zIndex: "10",
          }}
        >
          {location.pathname === "/home" && storedCountry == "ZA" ? (
            <span>
              Please refer to the{" "}
              <a
                onClick={openLink}
                target="_blank"
                style={{ color: "#2c7be5", cursor: "pointer" }}
              >
                Transnet Port Terminals{" "}
              </a>
              website for updated vessel ETA.{""}
            </span>
          ) : (
            ""
          )}
        </span>

        {((showPayButton &&
          filteredselectedAmount.length > 0 &&
          !checkForPaid &&
          !lengthCheck) ||
          paidUnpaidFilter) && (
          <Button
            variant="falcon-primary"
            className="ms-0 ps-0"
            size="sm"
            data-placement="left"
            data-toggle="tooltip"
            style={{
              position: "relative",
              boxShadow: "none",
              background: "transparent",
              border: "none",
            }}
            title={formattedContent}
          >
            {showAmount ? (
              <div className="d-flex flex-nowrap gap-1">
                {filteredselectedAmount[0]?.invcurr}{" "}
                <span>
                  {formattedTotalAmount}({filteredselectedAmount.length})
                </span>
              </div>
            ) : (
              ""
            )}
          </Button>
        )}

        {/* {(rbac?.rbacDetails?.multiInstructCreate && 
          rbac.rbacDetails.multiInstructCreate === 'Y' &&
          selectedData.length > 0 ) && (
          <Button
            variant='falcon-primary'
            className='ms-0 ps-0'
            size='sm'
            data-placement='left'
            data-toggle='tooltip'
            style={{
              position: 'relative',
              boxShadow: 'none',
              background: 'transparent',
              border: 'none',
            }}
            title={formattedContent}
          >
            {location.pathname.includes('/home') ? (
              <div>
                
                <span>
                  ({selectedData.filter((value:any, index: any, self: any) =>self.indexOf(self.find((v:any)=>v.blid==value.blid)) === index).length})
                </span>
              </div>
            ) : (
              ''
            )}
          </Button>
        )} */}

        {((showPayButton &&
          filteredselectedAmount.length > 0 &&
          !checkForPaid &&
          !lengthCheck) ||
          paidUnpaidFilter) && (
          <Button
            variant="falcon-primary"
            className="d-flex align-items-center ms-0 ps-0 "
            onClick={() => {
              dispatch(updateSelectedBlListMultiPayTable({}));
              dispatch(updateSelectedRowsInvoice([]));
              dispatch(updateSelectedAmount([]));
              dispatch(updateSelectedBlListMultiPay([]));
              // dispatch(loadPaymentDashboard(true))
              if (loadList) loadList(false);
            }}
            size="sm"
            style={{
              width: "max-content",
              position: "relative",
            }}
          >
            <FontAwesomeIcon
              style={{ marginLeft: "10px" }}
              color="#f5822a"
              icon={faTrash}
            />
          </Button>
        )}
      </div>

      {location.pathname === "/payment" ? (
        <div
          style={{
            fontSize: "14px",
            color: "#f5822a",
            fontWeight: "bold",
            // marginLeft: '5px',
            // position: 'absolute',
            top: "15px",
            width:"max-content",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {profileDetails.partnerType != "liner" ? (
            <span title={t("resource_message:notofication")}>
              <Marquee speed={30} pauseOnHover={true}>
                {t("resource_message:notofication")}{" "}
              </Marquee>
            </span>
          ) : (
            <></>
          )}
        </div>
      ) : (
        ""
      )}
      {showRightDiv && (
        <div className="d-flex justify-content-end align-items-center">
          {showReportDropdown && (
            <InputGroup className="z-index-agent mx-2">
              <Form.Select
                aria-label="Config"
                className="rounded-pill text-primary fs--1"
                size="lg"
                value={selectedInst}
                disabled={reportList.length <= 1}
                onChange={(e) => {
                  dispatch(selectReportInstruction(e?.target?.value));
                  if (e?.target?.value == "") return;
                  window.localStorage.setItem(
                    "@linerReportInstruct",
                    e?.target?.value
                  );
                }}
              >
                <option value="" hidden={true}>
                  none
                </option>

                {reportList.map(({ value, text }) => (
                  <option value={value}>{text}</option>
                ))}
              </Form.Select>
            </InputGroup>
          )}
          {showChooseConfigView() ? (
            <InputGroup
              className="z-inxe-agent flex-nowrap d-flex"
              style={{ minWidth: "11.5rem" }}
            >
              <InputGroup.Text className="rounded-pill-l bg-100 fs--1">
              {t("resource_1:view")}
              </InputGroup.Text>
              <Form.Select
                aria-label="Config"
                className="rounded-pill-r text-primary fs--1"
                size="sm"
                value={
                  uniqueFilterKey
                    ? selectedConfigId?.[uniqueFilterKey]?.toString() || ""
                    : ""
                }
                onChange={(e) => {
                  if (uniqueFilterKey) {
                    const newValue = parseInt(e?.target?.value) || null;
                    localStorage.setItem(
                      `selectedConfigId_${dashboardName}`,
                      JSON.stringify(newValue)
                    );
                    dispatch(
                      updateSelectedConfigId({
                        ...selectedConfigId,
                        [uniqueFilterKey]: newValue,
                      })
                    );

                    const newdata = location.pathname.includes("instruction")
                      ? instructionsGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                      : location.pathname.includes("standing")
                      ? standingInstructionGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                      : location.pathname.includes("paymentDashboard")
                      ? paymentGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )
                      : location.pathname.includes("receipt")
                      ? receiptGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )
                      : location.pathname.includes("emailDashboard")
                      ? emailGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )
                      : location.pathname.includes("payment")
                      ? invoiceGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                      : location.pathname.includes("registrationmaster")
                      ? registrationMasterGrid[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                      : location.pathname.includes("users")
                      ? userGrids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid
                      : grids[0].configGroup?.find(
                          (c: any) => c.userGridId == newValue
                        )?.columnConfigGrid;

                    if (newdata) {
                      setTimeout(() => {
                        if (JSON.parse(newdata)[0].sorts) {
                          const input = `${JSON.parse(newdata)[0].sorts}`;
                          const [firstWord, lastWord]: any =
                            input.match(/\w+/g) || [];
                          dispatch(
                            updateSortStateDataTable({
                              key: firstWord,
                              value: lastWord,
                            })
                          );
                          dispatch(
                            updateParams({
                              key: firstWord,
                              value: lastWord,
                            })
                          );
                          const queryParams = queryString.parse(
                            location.search
                          );
                          const sortString = {
                            sort: `${firstWord},${lastWord}`,
                            seek: undefined,
                            direction: undefined,
                          };

                          navigate(
                            `${location.pathname}?${queryString.stringify({
                              ...queryParams,
                              ...sortString,
                            })}`
                          );
                        } else {
                          dispatch(
                            updateSortStateDataTable({
                              key: null,
                            })
                          );

                          navigate(`${location.pathname}`);
                        }
                      }, 200);
                    }
                  }
                }}
                disabled={dataLoading}
              >
                <option
                  disabled
                  value="disable-1"
                  className="text-dark"
                ></option>
                <optgroup label="System">
                  {/*  <option value='' className='text-dark'>
                    Default
                  </option> */}
                  {configViews &&
                    configViews
                      .filter((v: any) => v?.type === "system")
                      .map((i: any) => (
                        <option
                          value={i?.userGridId || ""}
                          className="text-dark"
                          selected={i?.userGridId === savedViewValue}
                        >
                          {i?.columnConfigName}{" "}
                          {/* ({i?.type?.toUpperCase()}) */}
                        </option>
                      ))}
                </optgroup>

                {/* <option disabled value='disable-2' className='text-dark'></option> */}

                <optgroup label="Private">
                  {configViews &&
                    configViews
                      .filter((v: any) => v?.type === "private")
                      .map((i: any) => (
                        <option
                          value={i?.userGridId || ""}
                          className="text-dark"
                          selected={i?.userGridId === savedViewValue}
                        >
                          {i?.columnConfigName}{" "}
                          {/* ({i?.type?.toUpperCase()}) */}
                        </option>
                      ))}
                </optgroup>

                <optgroup label="Shared">
                  {configViews &&
                    configViews
                      .filter((v: any) => v?.type === "shared")
                      .map((i: any) => (
                        <option
                          value={i?.userGridId || ""}
                          className="text-dark"
                          selected={i?.userGridId === savedViewValue}
                        >
                          {i?.columnConfigName}
                        </option>
                      ))}
                </optgroup>

                <option
                  disabled
                  value="disable-4"
                  className="text-dark"
                ></option>
              </Form.Select>
            </InputGroup>
          ) : null}

          {showColumnSettings ? (
            <Button
              style={{ height: 27, marginTop: 2 }}
              variant="falcon-primary z-inxe-agent"
              onClick={onShowColumnSettings}
              size="sm"
              className="ms-3"
            >
              <FontAwesomeIcon icon={faGear} />
            </Button>
          ) : (
            ""
          )}
          {showExport ? (
            <>
              <Dropdown drop="down">
                <Dropdown.Toggle
                  className="no-caret-dropdown-toggle cursor-pointer z-inxe-agent ms-2 csvDownload"
                  as="div"
                >
                  <Button
                    variant="falcon-primary"
                    size="sm"
                    style={{ marginRight: "0.5rem", position: "relative" }}
                  >
                    <div className="d-flex">
                      <FontAwesomeIcon
                        icon={faFileCsv}
                        className="me-2 mt-1 text-warning"
                      />
                      <span> {t("resource_1:csv")} </span>
                    </div>
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div>
                    <Dropdown.Item onClick={exportDataAsCSV} disabled={false}>
                      <span> {t("resource_2:currentPage")}</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => {
                        // loadDashboardData(true);

                        loadDashboardData(true);
                      }}
                      disabled={false}
                    >
                      <span>{t("resource_2:allPages")} </span>
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              {/* <Button
                variant='falcon-primary z-inxe-agent'
                className='ms-2'
                size='sm'
                onClick={exportDataAsCSV}
                style={
                  /* location.pathname === '/payment' ||
                location.pathname === '/home'  
                  configViews !== undefined && configViews.length > 1
                    ? { width: 110 }
                    : { width: 80 }
                }
                disabled={dataLoading}>
                <FontAwesomeIcon
                  className='text-warning me-2'
                  icon={faFileCsv}
                />
                <span>CSV</span>
              </Button> */}
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </Card.Header>
  );
};

export default FilterView;
